<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Please Note</h2>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <span>
            You have updated the status of an operator, please go to the <strong>Toplists</strong> tab to
            remove this operator from toplists if needed.
          </span>
          <div class="d-flex justify-content-end p-4">
            <button class="btn btn-secondary" :class="{ loading: loading }" @click="close()">Close</button>
            <button class="btn btn-primary ml-3" :class="{ loading: loading }" @click="submit()">
              Open Toplists tab
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      operatorId: null,
      object: null,
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(id, object) {
      this.operatorId = id
      this.object = object
      this.$refs.modal.classList.add('show')
    },
    close() {
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.$emit('closed')
    },
    submit() {
      this.close()
      this.$router.push({
        path: `/operators/${this.object.operator_id}/toplists`,
        query: {
          markets_label: this.object.market.label,
          sites_name: this.object.affiliate.name,
          lists_type: this.object.type,
        },
      })
    },
  },
}
</script>
