export default {
  getForm() {
    return [
      {
        name: 'affiliate_id',
        required: true,
        display_name: 'Affiliate ID',
        type: 'hidden'
      },
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        auto_focus: true,
        help_text: 'Automatically changes spaces to dashes, capitals to lowercase and removes special characters.',
        value_modifier: function(value) {
          if (typeof value !== 'undefined') {
            value = value.toLowerCase()
            value = value.replace(/[^A-Za-z0-9\-\s]/g,'')
            value = value.replace(/[\s+]/g,'-')
            return value;
          }
          return ''
        }
      },
      {
        name: 'link',
        required: true,
        display_name: 'Link',
        type: 'text'
      },
      {
        name: 'custom_link',
        required: false,
        display_name: 'Mobile Link',
        type: 'text',
        help_text: 'Use if mobile version of link is different'
      }
    ]
  },
  getConfig() {
    return {
      url: 'operators/affiliate-links',
      name: 'Tracking Link'
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
