<template>

  <div>

    <div>

      <router-link
        :to="{ path: `${siteLog.path}` }"
        v-for="(siteLog, key) in siteLogs()"
        :key="key"
        class="card card-clickable"
      >

        <div class="card-header">

          <div class="right-element top-0 right-0 position-absolute m-3">

            <div class="badge bg-white-75">{{ siteLog.label }}</div>

          </div>

        </div>

        <div class="card-body text-center">

          <span class="btn btn-lg icon-button rounded-pill pointer-events-none" :class="siteLog.color">

            <i :class="siteLog.icon"></i>

          </span>

          <span class="h3 d-block mt-3">{{ siteLog.name }}</span>

          <p class="additional">{{ siteLog.description }}</p>

        </div>

      </router-link>

    </div>

  </div>

</template>

<script>
/* eslint-disable no-unused-vars */
export default {
  methods: {
    siteLogs() {
      return [
        {
          name: 'Site Logs',
          description: 'Activity logs from Olympus',
          path: '/site-logs',
          icon: 'uil uil-clipboard-notes',
          color: 'bg-orange-75',
          label: 'Logs',
          role: 'site logs',
        },
      ].filter(() => this.hasRole('site logs') || this.hasRole('admin'))
    },
  },
}
</script>

