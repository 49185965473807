<template>
    <div>
      <div class="card">
        <div class="card-header align-center"  >
          <h2>Performance Scoring</h2>
          <i class="uil uil-info-circle align-self-center ml-2"
          @mouseover="positionTooltip($event, 'lighthouse')"
          @mouseleave="hideTooltip('lighthouse')"></i> 
          <span ref="lighthouse" class="tooltiptext">{{ tooltipText.lighthouse }}</span>

          <div class="right-element top-0 right-0 position-absolute m-3">
            <div class="badge bg-white-75">Pandora</div>
            <div class="badge bg-white-75 ml-2">BETA</div>
          </div>
        </div>

        <div class="card-body">
          <div class="row row-column">
            <div class="col-6 rounded-8 view" :style="{ '--active-view-border-color': viewFillColor(mobilePerformanceScore) ,borderBottom: 4}" :class="{ 'active-view': currentView === 'mobile' }" @click="currentView = 'mobile'">
              <div class="rounded-8 border vertical-center py-3 min-height-280">
                <i v-if="mobileDataNotAvailable" class="uil uil-info-circle align-self-center ml-2"
                @mouseover="positionTooltip($event, 'nodatamobile')"
                @mouseleave="hideTooltip('nodatamobile')"></i> 
                <span v-if="mobileDataNotAvailable" ref="nodatamobile" class="tooltiptext">{{ tooltipText.nodata }}</span>
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <ProgressCircle :value="mobilePerformanceScore" :maxWidth=150 :maxHeight=150 />
                  <h1 class="mt-4">Mobile</h1>
                </div>
              </div>
            </div>
            <div class="col-6 rounded-8 view" :style="{ '--active-view-border-color': viewFillColor(desktopPerformanceScore) ,borderBottom: 4}" :class="{ 'active-view': currentView === 'desktop' }" @click="currentView = 'desktop'">
              <div class="rounded-8 border vertical-center py-3 min-height-280">
                <i v-if="desktopDataNotAvailable" class="uil uil-info-circle align-self-center ml-2"
                @mouseover="positionTooltip($event, 'nodatadesktop')"
                @mouseleave="hideTooltip('nodatadesktop')"></i> 
                <span v-if="desktopDataNotAvailable" ref="nodatadesktop" class="tooltiptext">{{ tooltipText.nodata }}</span>
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <ProgressCircle :value="desktopPerformanceScore" :maxWidth=150 :maxHeight=150 />
                  <h1 class="mt-4">Desktop</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-header align-center">
          <h2>Core Web Vitals</h2>
          <i class="uil uil-info-circle align-self-center ml-2"
          @mouseover="positionTooltip($event, 'coreWebVitals')"
          @mouseleave="hideTooltip('coreWebVitals')"></i> 
          <span ref="coreWebVitals" class="tooltiptext">{{ tooltipText.coreWebVitals }}</span>

          <div class="right-element top-0 right-0 position-absolute m-3">
            <div class="badge bg-white-75">Pandora</div>
            <div class="badge bg-white-75 ml-2">BETA</div>
          </div>
        </div>
        <div class="card-body">
          <div class="row row-container">
            <!-- Add @click on the parent div for the entire card -->
            <div class="col mx-2 px-4 py-3 rounded-8 card-hover" :style="{ '--active-card-border-color': getBorderColor(activeTab) ,borderBottom: 4,  borderBottomColor: activeTab === 'LCP' ? getBorderColor('LCP') : '' }" :class="{ 'active-card': activeTab === 'LCP' }" @click="activeTab = 'LCP'">
              <div class="circle">
                <span class="dot" :style="{ '--active-card-border-color': getBorderColor('LCP')}"></span>
              </div>
              <p>Avg LCP</p>
              <h3 class="mt-2" :style="{ color: getColorForMetric('LARGEST_CONTENTFUL_PAINT_MS_average', displayedData.LARGEST_CONTENTFUL_PAINT_MS_average) }">{{ truncateToTwoDecimalPlaces(formatMetric('LARGEST_CONTENTFUL_PAINT_MS_average', displayedData.LARGEST_CONTENTFUL_PAINT_MS_average))  }}</h3>
              <div class="d-flex justify-content-between mt-2">
                <p :style="{ color: getColorForMetric('LARGEST_CONTENTFUL_PAINT_MS_average', displayedData.LARGEST_CONTENTFUL_PAINT_MS_average) }">{{getTextForMetric('LARGEST_CONTENTFUL_PAINT_MS_average', displayedData.LARGEST_CONTENTFUL_PAINT_MS_average)}}</p>
                <p class="text-right">(&lt; 2.5s - 4s) {{ calculateOneMetric('LARGEST_CONTENTFUL_PAINT_MS_average', displayedData.LARGEST_CONTENTFUL_PAINT_MS_average) }}%</p>
              </div>
            </div>
            <div class="col mx-2 px-4 py-3 rounded-8 card-hover" :style="{ '--active-card-border-color': getBorderColor(activeTab) ,borderBottom: 4,  borderBottomColor: activeTab === 'CLS' ? getBorderColor('CLS') : '' }" :class="{ 'active-card': activeTab === 'CLS' }" @click="activeTab = 'CLS'">
              <div class="circle">
                <span class="dot" :style="{ '--active-card-border-color': getBorderColor('CLS')}"></span>
              </div>
              <p>Avg CLS</p>
              <h3 class="mt-2" :style="{ color: getColorForMetric('CUMULATIVE_LAYOUT_SHIFT_SCORE_average', displayedData.CUMULATIVE_LAYOUT_SHIFT_SCORE_average) }">{{ truncateToTwoDecimalPlaces(formatMetric('CUMULATIVE_LAYOUT_SHIFT_SCORE_average', displayedData.CUMULATIVE_LAYOUT_SHIFT_SCORE_average))  }}</h3>
              <div class="d-flex justify-content-between mt-2">
                <p :style="{ color: getColorForMetric('CUMULATIVE_LAYOUT_SHIFT_SCORE_average', displayedData.CUMULATIVE_LAYOUT_SHIFT_SCORE_average) }">{{getTextForMetric('CUMULATIVE_LAYOUT_SHIFT_SCORE_average', displayedData.CUMULATIVE_LAYOUT_SHIFT_SCORE_average)}}</p>
                <p class="text-right">(&lt; 0.1 - 0.25) {{ calculateOneMetric('CUMULATIVE_LAYOUT_SHIFT_SCORE_average', displayedData.CUMULATIVE_LAYOUT_SHIFT_SCORE_average) }}%</p>
              </div>
            </div>
            <div class="col mx-2 px-4 py-3 rounded-8 card-hover" :style="{ '--active-card-border-color': getBorderColor(activeTab) ,borderBottom: 4,  borderBottomColor: activeTab === 'INP' ? getBorderColor('INP') : '' }" :class="{ 'active-card': activeTab === 'INP' }" @click="activeTab = 'INP'">
              <div class="circle">
                <span class="dot" :style="{ '--active-card-border-color': getBorderColor('INP')}"></span>
              </div>
              <p>Avg INP</p>
              <h3 class="mt-2" :style="{ color: getColorForMetric('INTERACTION_TO_NEXT_PAINT_average', displayedData.INTERACTION_TO_NEXT_PAINT_average) }">{{ truncateToTwoDecimalPlaces(formatMetric('INTERACTION_TO_NEXT_PAINT_average', displayedData.INTERACTION_TO_NEXT_PAINT_average)) }}</h3>
              <div class="d-flex justify-content-between mt-2">
                <p :style="{ color: getColorForMetric('INTERACTION_TO_NEXT_PAINT_average', displayedData.INTERACTION_TO_NEXT_PAINT_average) }">{{getTextForMetric('INTERACTION_TO_NEXT_PAINT_average', displayedData.INTERACTION_TO_NEXT_PAINT_average)}}</p>
                <p class="text-right">(&lt; 0.2ms - 0.5ms) {{ calculateOneMetric('INTERACTION_TO_NEXT_PAINT_average', displayedData.INTERACTION_TO_NEXT_PAINT_average) }}%</p>
              </div>
            </div>
            <div class="col mx-2 px-4 py-3 rounded-8 card-hover" :style="{ '--active-card-border-color': getBorderColor(activeTab) ,borderBottom: 4,  borderBottomColor: activeTab === 'FCP' ? getBorderColor('FCP') : '' }" :class="{ 'active-card': activeTab === 'FCP' }" @click="activeTab = 'FCP'">
              <div class="circle">
                <span class="dot" :style="{ '--active-card-border-color': getBorderColor('FCP')}"></span>
              </div>
              <p>Avg FCP</p>
              <h3 class="mt-2" :style="{ color: getColorForMetric('FIRST_CONTENTFUL_PAINT_MS_average', displayedData.FIRST_CONTENTFUL_PAINT_MS_average) }">{{ truncateToTwoDecimalPlaces(formatMetric('FIRST_CONTENTFUL_PAINT_MS_average', displayedData.FIRST_CONTENTFUL_PAINT_MS_average)) }}</h3>
              <div class="d-flex justify-content-between mt-2">
                <p :style="{ color: getColorForMetric('FIRST_CONTENTFUL_PAINT_MS_average', displayedData.FIRST_CONTENTFUL_PAINT_MS_average) }">{{getTextForMetric('FIRST_CONTENTFUL_PAINT_MS_average', displayedData.FIRST_CONTENTFUL_PAINT_MS_average)}}</p>
                <p class="text-right">(&lt; 1.8s - 3s) {{ calculateOneMetric('FIRST_CONTENTFUL_PAINT_MS_average', displayedData.FIRST_CONTENTFUL_PAINT_MS_average) }}%</p>
              </div>
            </div>
            <div class="col mx-2 px-4 py-3 rounded-8 card-hover" :style="{ '--active-card-border-color': getBorderColor(activeTab) ,borderBottom: 4,  borderBottomColor: activeTab === 'TTFB' ? getBorderColor('TTFB') : '' }" :class="{ 'active-card': activeTab === 'TTFB' }" @click="activeTab = 'TTFB'">
              <div class="circle">
                <span class="dot" :style="{ '--active-card-border-color': getBorderColor('TTFB')}"></span>
              </div>
              <p>Avg TTFB</p>
              <h3 class="mt-2" :style="{ color: getColorForMetric('EXPERIMENTAL_TIME_TO_FIRST_BYTE_average', displayedData.EXPERIMENTAL_TIME_TO_FIRST_BYTE_average) }">{{ truncateToTwoDecimalPlaces(formatMetric('EXPERIMENTAL_TIME_TO_FIRST_BYTE_average', displayedData.EXPERIMENTAL_TIME_TO_FIRST_BYTE_average)) }}</h3>
              <div class="d-flex justify-content-between mt-2">
                <p :style="{ color: getColorForMetric('EXPERIMENTAL_TIME_TO_FIRST_BYTE_average', displayedData.EXPERIMENTAL_TIME_TO_FIRST_BYTE_average) }">{{getTextForMetric('EXPERIMENTAL_TIME_TO_FIRST_BYTE_average', displayedData.EXPERIMENTAL_TIME_TO_FIRST_BYTE_average)}}</p>
                <p class="text-right">(&lt; 0.8ms - 1.8ms) {{ calculateOneMetric('EXPERIMENTAL_TIME_TO_FIRST_BYTE_average', displayedData.EXPERIMENTAL_TIME_TO_FIRST_BYTE_average) }}%</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body min-height-155">
          <div v-if="activeTab">
            <div class="row border border-1 rounded-8">
              <div class="col-2 px-4 py-3 d-flex flex-column align-items-center ">
                <p :style="{marginBottom: '10px'}">{{ getMetricInfo(activeTab).title }}</p>
                <h1 :style="{ color: getColorForMetric(activeTab, getMetricInfo(activeTab).value  ) }">{{ truncateToTwoDecimalPlaces(formatMetric(activeTab,getMetricInfo(activeTab).value))  }}</h1>
                <p></p>
              </div>
              <div class="col-10 p-4 d-flex align-items-center min-height-155">
                <p>{{ getMetricInfo(activeTab).text }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTab" class="card">
        <div class="row" v-if="currentView == 'desktop'">
          <div class="col-12">
            <div class="card-header"><h3>Desktop - {{ activeTab }}</h3>
              <div class="right-element top-0 right-0 position-absolute m-3">
                <div class="badge bg-white-75">Pandora</div>
                <div class="badge bg-white-75 ml-2">BETA</div>
              </div>
            </div>
              <div class="card-body">
                <div class="charts">
                <!-- <apexchart type="area" height="350px" :options="chartOptions" :series="series"></apexchart> -->
                <apexchart ref="chartDesktop" type="area" height="350px" :options="chartOptions" :series="series"></apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="currentView == 'mobile'">
            <div class="col-12">
            <div class="card-header"><h3>Mobile - {{ activeTab }}</h3>
              <div class="right-element top-0 right-0 position-absolute m-3">
                <div class="badge bg-white-75">Pandora</div>
                <div class="badge bg-white-75 ml-2">BETA</div>
              </div>
            </div>
            <div class="card-body">
              <div class="charts">
                <apexchart ref="chartMobile" type="area" height="350px" :options="chartOptions2" :series="series2"></apexchart>
              </div>
            </div>
          </div>
          </div>
        </div>
        <PageSpeedInsights :data="apiData"/>
      </div>
  </template>
  
  <script>
  import GooglePageSpeedInsightService from '@services/GooglePageSpeedInsightService';
  import ProgressCircle from '@atoms/misc/progress-circle.vue';
  import PageSpeedInsights from '@atoms/misc/page-speed-insights.vue';

  
  export default {
    name: 'CoreWebVitals',
    components: {
      ProgressCircle,
      PageSpeedInsights
    },
    watch: {
    activeTab() {
      this.updateCharts();
    },
  },
    data: function() {
      return {
        activeTab: 'CLS', // Added to control which tab is active
        currentView: 'mobile',
        desktopAverageData: {},
        mobileAverageData: {},
        desktopPerformanceScore: NaN,
        mobilePerformanceScore: NaN,
        mobileDataNotAvailable: true,
        desktopDataNotAvailable: true,
        apiData: [],
        tooltipText: {
        nodata: "The Chrome User Experience Report does not have sufficient real-world speed data for this page.",
        lighthouse: "Performance score follows Google's PageSpeed Insights and is calculated by the Origin average data key web vitals of a 28-day collection period: CLS and LCP carry the most weight at 25%, followed by INP at 30%, and both FCP and TTFB at 10% each. The final performance score is the final calculated score, providing a comprehensive measure of the site's loading performance and visual stability.",
        coreWebVitals: "By analyzing data from the PageSpeed Insights API, we collect important metrics like page speed (FCP, LCP), stability (CLS), and responsiveness (TTFB, INP). These averages, pulled from actual user experiences, provide a clear snapshot of the site's performance. Understanding these figures helps us improve the website's speed and user experience.",
                    },
        metrics: {
            CUMULATIVE_LAYOUT_SHIFT_SCORE_average: { good: 0.01, poor: 0.1, weight: 25 }, // Adjusted for lower actual values
            EXPERIMENTAL_TIME_TO_FIRST_BYTE_average: { good: 0.1, poor: 0.4, weight: 10 }, // Time to First Byte
            FIRST_CONTENTFUL_PAINT_MS_average: { good: 0.8, poor: 1.5, weight: 10 },       // First Contentful Paint
            INTERACTION_TO_NEXT_PAINT_average: { good: 0.05, poor: 0.2, weight: 30 },     // Interaction to Next Paint
            LARGEST_CONTENTFUL_PAINT_MS_average: { good: 1.0, poor: 2.5, weight: 25 }    // Largest Contentful Paint
        },
        categories: [],
        desktopLCP: [],
        mobileLCP: [],
        desktopCLS: [],
        mobileCLS: [],
        desktopINP: [],
        mobileINP: [],
        desktopTTFB: [],
        mobileTTFB: [],
        desktopFCP: [],
        mobileFCP: [],
        chartOptions: {
          chart: {
            id: 'vuechart-desktop', // Unique ID for desktop chart
            toolbar: {
              show: false
            }
          },
          xaxis: {
            categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8']
          },
          yaxis: {
            labels: {
              reversed: true,
              formatter: function (value) {
                return value;
              }
            }
          },
          tooltip: {
            y: {
              formatter: (value) => {
                return this.formatTooltipValue(value, this.activeTab);
              }
            }
          },
          stroke: {
            curve: 'smooth',
          },
          colors: ['#008FFB', '#00E396', '#008FFB'],
        },
        chartOptions2: {
          chart: {
            id: 'vuechart-mobile', // Unique ID for mobile chart
            toolbar: {
              show: false
            }
          },
          xaxis: {
            reversed: true,
            categories: []
          },
          tooltip: {
            y: {
              formatter: (value) => {
                return this.formatTooltipValue(value, this.activeTab);
              }
            }
          },
          stroke: {
            curve: 'smooth',
          },
          colors: ['#00E396', '#00E396', '#008FFB'],
        },
      };
    },
    created() {
      this.getGooglePageSpeedInsightsData();
    },
    computed: {
      series() {
        return this.getSeries(true);
      },
      series2() {
        return this.getSeries(false);
      },
      displayedData() {
        if (this.currentView === 'desktop') {
          return this.desktopAverageData;
        } else {
          return this.mobileAverageData;
        }
      }
    },
    methods: {
      truncateToTwoDecimalPlaces(value) {
      const numStr = value.toString();
      const index = numStr.indexOf('.');
      if (index !== -1) {
        return parseFloat(numStr.substring(0, index + 3));
      }
      return value; // Return the original number if there's no decimal point
    },
    calculateMetricCoverage(good, poor, value) {
    if (value <= good) {
        return 100;
    } else if (value > poor) {
        return 0;
    } else {
        const percentage = (1 - ((value - good) / (poor - good))) * 100;
        return percentage.toFixed(2);
    }
    },
    calculateWeightedScore(percentageScore, weight) {
      return Math.round((percentageScore / 100) * weight);
    },
    calculateOneMetric(name, value){
      const nameMapping = {
          LCP: 'LARGEST_CONTENTFUL_PAINT_MS_average',
          FID: 'FIRST_INPUT_DELAY_MS_average',
          CLS: 'CUMULATIVE_LAYOUT_SHIFT_SCORE_average',
          INP: 'INTERACTION_TO_NEXT_PAINT_average',
          FCP: 'FIRST_CONTENTFUL_PAINT_MS_average',
          TTFB: 'EXPERIMENTAL_TIME_TO_FIRST_BYTE_average',
        };

        const fullName = nameMapping[name] || name;

        const metrics = {
                  CUMULATIVE_LAYOUT_SHIFT_SCORE_average: { good: 0.1, poor: 0.25, weight: 25 },
                  EXPERIMENTAL_TIME_TO_FIRST_BYTE_average: { good: 0.8, poor: 1.8, weight: 10 },
                  FIRST_CONTENTFUL_PAINT_MS_average: { good: 1.8, poor: 3, weight: 10 },
                  INTERACTION_TO_NEXT_PAINT_average: { good: 0.2, poor: 0.5, weight: 30 },
                  LARGEST_CONTENTFUL_PAINT_MS_average: { good: 2.5, poor: 4, weight: 25 }
                };
      
        const metricThresholds = metrics[fullName];

        return Math.round(this.calculateMetricCoverage(metricThresholds.good, metricThresholds.poor, value));
    },
    formatMetricsData(sortedData) {
                     
            // Initialize arrays for metrics
            let tempDesktopLCP = [], tempMobileLCP = [];
            let tempDesktopCLS = [], tempMobileCLS = [];
            let tempDesktopINP = [], tempMobileINP = [];
            let tempDesktopTTFB = [], tempMobileTTFB = [];
            let tempDesktopFCP = [], tempMobileFCP = [];
            // Add other metrics as needed...
            let categories = sortedData.map(entry => new Date(entry.created_at).toLocaleDateString('en-GB'));
            sortedData.forEach(entry => {
              const entryData = JSON.parse(entry.data);
              const desktopMetrics = entryData.desktop.originData.experienceMetricsData;
              const mobileMetrics = entryData.mobile.originData.experienceMetricsData;

              if (!desktopMetrics || Object.keys(desktopMetrics).length === 0 || !mobileMetrics || Object.keys(mobileMetrics).length === 0) {
              // Skip this iteration if data is missing or not structured correctly
              return;
              }
              if (desktopMetrics.LARGEST_CONTENTFUL_PAINT_MS && desktopMetrics.LARGEST_CONTENTFUL_PAINT_MS.percentile !== undefined) {
                  tempDesktopLCP.push((desktopMetrics.LARGEST_CONTENTFUL_PAINT_MS.percentile / 1000).toFixed(1));
              }
              if (desktopMetrics.CUMULATIVE_LAYOUT_SHIFT_SCORE && desktopMetrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile !== undefined) {
                  tempDesktopCLS.push((desktopMetrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile / 100).toFixed(2));
              }
              if (desktopMetrics.INTERACTION_TO_NEXT_PAINT && desktopMetrics.INTERACTION_TO_NEXT_PAINT.percentile !== undefined) {
                  tempDesktopCLS.push((desktopMetrics.INTERACTION_TO_NEXT_PAINT.percentile));
              }
              if (desktopMetrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE && desktopMetrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE.percentile !== undefined) {
                  tempDesktopTTFB.push((desktopMetrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE.percentile).toFixed(1));
              }
              if (desktopMetrics.FIRST_CONTENTFUL_PAINT_MS && desktopMetrics.FIRST_CONTENTFUL_PAINT_MS.percentile !== undefined) {
                  tempDesktopFCP.push((desktopMetrics.FIRST_CONTENTFUL_PAINT_MS.percentile / 1000).toFixed(1));
              }

                if (mobileMetrics.LARGEST_CONTENTFUL_PAINT_MS && mobileMetrics.LARGEST_CONTENTFUL_PAINT_MS.percentile !== undefined) {
                    tempMobileLCP.push((mobileMetrics.LARGEST_CONTENTFUL_PAINT_MS.percentile / 1000).toFixed(1));
                }
                if (mobileMetrics.CUMULATIVE_LAYOUT_SHIFT_SCORE && mobileMetrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile !== undefined) {
                    tempMobileCLS.push((mobileMetrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile / 100).toFixed(2));
                }
                if (mobileMetrics.INTERACTION_TO_NEXT_PAINT && mobileMetrics.INTERACTION_TO_NEXT_PAINT.percentile !== undefined) {
                    tempMobileINP.push(mobileMetrics.INTERACTION_TO_NEXT_PAINT.percentile);
                }
                if (mobileMetrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE && mobileMetrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE.percentile !== undefined) {
                    tempMobileTTFB.push((mobileMetrics.EXPERIMENTAL_TIME_TO_FIRST_BYTE.percentile).toFixed(1));
                }
                if (mobileMetrics.FIRST_CONTENTFUL_PAINT_MS && mobileMetrics.FIRST_CONTENTFUL_PAINT_MS.percentile !== undefined) {
                    tempMobileFCP.push((mobileMetrics.FIRST_CONTENTFUL_PAINT_MS.percentile / 1000).toFixed(1));
                }
            });
            
            this.categories = categories;
            this.desktopLCP = tempDesktopLCP;
            this.mobileLCP = tempMobileLCP;
            this.desktopCLS = tempDesktopCLS;
            this.mobileCLS = tempMobileCLS;
            this.desktopINP = tempDesktopINP;
            this.mobileINP = tempMobileINP;
            this.desktopTTFB = tempDesktopTTFB;
            this.mobileTTFB = tempMobileTTFB;
            this.desktopFCP = tempDesktopFCP;
            this.mobileFCP = tempMobileFCP;
            this.updateChartOptions();
      },
      isText(variable) {
          return typeof variable === 'string';
      },
      getColorForMetric(metricName, value) {
        // Convert values to a consistent unit if necessary
        const secondsValue = value;

        const nameMapping = {
          LCP: 'LARGEST_CONTENTFUL_PAINT_MS_average',
          FID: 'FIRST_INPUT_DELAY_MS_average',
          CLS: 'CUMULATIVE_LAYOUT_SHIFT_SCORE_average',
          INP: 'INTERACTION_TO_NEXT_PAINT_average',
          FCP: 'FIRST_CONTENTFUL_PAINT_MS_average',
          TTFB: 'EXPERIMENTAL_TIME_TO_FIRST_BYTE_average',
        };

        const fullName = nameMapping[metricName] || metricName;

        const thresholds = {
          CUMULATIVE_LAYOUT_SHIFT_SCORE_average: { good: 0.1, needsImprovement: 0.25 },
          EXPERIMENTAL_TIME_TO_FIRST_BYTE_average: { good: 0.8, needsImprovement: 1.8},
          FIRST_CONTENTFUL_PAINT_MS_average: { good: 1.8, needsImprovement: 3 },
          // FIRST_INPUT_DELAY_MS_average: { good: 0.1, needsImprovement: 0.3 }, // Converted threshold to seconds
          INTERACTION_TO_NEXT_PAINT_average: { good: 0.2, needsImprovement: 0.5 },
          LARGEST_CONTENTFUL_PAINT_MS_average: { good: 2.5, needsImprovement: 4},
        };

        const metricThresholds = thresholds[fullName];
        if (!metricThresholds) return 'black'; // Fallback color

        if (secondsValue <= metricThresholds.good) {
          return 'green'; // Good
        } else if (secondsValue <= metricThresholds.needsImprovement) {
          return 'orange'; // Needs Improvement
        } else {
          return 'red'; // Poor
        }
      },
      getTextForMetric(metricName, value) {
        // Convert values to a consistent unit if necessary
        const secondsValue = value;

        const nameMapping = {
          LCP: 'LARGEST_CONTENTFUL_PAINT_MS_average',
          FID: 'FIRST_INPUT_DELAY_MS_average',
          CLS: 'CUMULATIVE_LAYOUT_SHIFT_SCORE_average',
          INP: 'INTERACTION_TO_NEXT_PAINT_average',
          FCP: 'FIRST_CONTENTFUL_PAINT_MS_average',
          TTFB: 'EXPERIMENTAL_TIME_TO_FIRST_BYTE_average',
        };

        const fullName = nameMapping[metricName] || metricName;

        const thresholds = {
          CUMULATIVE_LAYOUT_SHIFT_SCORE_average: { good: 0.1, needsImprovement: 0.25 },
          EXPERIMENTAL_TIME_TO_FIRST_BYTE_average: { good: 0.8, needsImprovement: 1.8},
          FIRST_CONTENTFUL_PAINT_MS_average: { good: 1.8, needsImprovement: 3 },
          // FIRST_INPUT_DELAY_MS_average: { good: 0.1, needsImprovement: 0.3 }, // Converted threshold to seconds
          INTERACTION_TO_NEXT_PAINT_average: { good: 0.2, needsImprovement: 0.5 },
          LARGEST_CONTENTFUL_PAINT_MS_average: { good: 2.5, needsImprovement: 4},
        };

        const metricThresholds = thresholds[fullName];

        if (secondsValue <= metricThresholds.good) {
          return 'Good'; // Good
        } else if (secondsValue <= metricThresholds.needsImprovement) {
          return 'Needs Improvement'; // Needs Improvement
        } else {
          return 'Poor'; // Poor
        }
      },
      viewFillColor(value){
        const number = parseInt(value);
        if(number >= 90){
          return "#4caf50"; // Green
        } else if(number >= 50){
          return "#ff9800"; // Orange
        } else {
          return "#f44336"; // Red
        }
    },
      formatMetric(metricName, value){
        const nameMapping = {
          LCP: 'LARGEST_CONTENTFUL_PAINT_MS_average',
          FID: 'FIRST_INPUT_DELAY_MS_average',
          CLS: 'CUMULATIVE_LAYOUT_SHIFT_SCORE_average',
          INP: 'INTERACTION_TO_NEXT_PAINT_average',
          FCP: 'FIRST_CONTENTFUL_PAINT_MS_average',
          TTFB: 'EXPERIMENTAL_TIME_TO_FIRST_BYTE_average',
        };

        const fullName = nameMapping[metricName] || metricName;

        const thresholds = {
          CUMULATIVE_LAYOUT_SHIFT_SCORE_average: { multiply: 10},
          EXPERIMENTAL_TIME_TO_FIRST_BYTE_average: { multiply: 1000},
          FIRST_CONTENTFUL_PAINT_MS_average: { multiply: 1},
          FIRST_INPUT_DELAY_MS_average: { multiply: 1 }, // Converted threshold to seconds
          INTERACTION_TO_NEXT_PAINT_average: { multiply: 1000 },
          LARGEST_CONTENTFUL_PAINT_MS_average: { multiply: 1},
        };

        const multiplier = thresholds[fullName];
        const metric = value * multiplier.multiply;

        if (isNaN(metric)){
          return 'N/A';
        }
        return metric;
      },
      formatTooltipValue(value, metric) {
        let formattedValue = value;
        let unit = '';

        switch (metric) {
          case 'LCP':
          case 'FCP':
            // Convert to seconds and append 's' for seconds
            formattedValue = value // Assuming value is in milliseconds
            unit = ' s';
            break;
          case 'CLS':
            // CLS doesn't need conversion, but ensure it's formatted nicely
            formattedValue = value
            break;
          case 'INP':
          case 'TTFB':
            // INP and TTFB are already in milliseconds, just append 'ms'
            formattedValue = value;
            unit = ' ms';
            break;
          // Add cases for other metrics if needed
        }

        return `${formattedValue}${unit}`;
      },
      getGooglePageSpeedInsightsData() {
        GooglePageSpeedInsightService.getOne(this.$route.params['id'])
          .then(response => {
            const results = response.data.result;
            const latestData = results.reduce((prev, current) => {
              return (prev.created_at > current.created_at) ? prev : current;
            }, results[0]);

            const sortedData = results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 10);
            const sortedDataReversed = sortedData.reverse();
            this.formatMetricsData(sortedDataReversed);
            const latestPSI = results.reduce((prev, current) => {
                  return new Date(prev.created_at) > new Date(current.created_at) ? prev : current;
                });

                const metrics = {
                  CUMULATIVE_LAYOUT_SHIFT_SCORE_average: { good: 0.1, poor: 0.25, weight: 25 },
                  EXPERIMENTAL_TIME_TO_FIRST_BYTE_average: { good: 0.8, poor: 1.8, weight: 10 },
                  FIRST_CONTENTFUL_PAINT_MS_average: { good: 1.8, poor: 3, weight: 10 },
                  INTERACTION_TO_NEXT_PAINT_average: { good: 0.2, poor: 0.5, weight: 30 },
                  LARGEST_CONTENTFUL_PAINT_MS_average: { good: 2.5, poor: 4, weight: 25 }
                };

                const psiData = JSON.parse(latestPSI.data);
                let mobileAverage = psiData.mobile.originData.averageData;
                let desktopAverage = psiData.desktop.originData.averageData;

                let mobileScore = 0;
                let desktopScore = 0;
                Object.keys(desktopAverage).forEach(key => {
                  if (metrics.hasOwnProperty.call(metrics,key)) { 
                  let currentDesktopScore = Math.round(this.calculateMetricCoverage(metrics[key].good, metrics[key].poor, desktopAverage[key]));
                  desktopScore += this.calculateWeightedScore(currentDesktopScore, metrics[key].weight);
                  }
                });

                Object.keys(mobileAverage).forEach(key => {
                  if (metrics.hasOwnProperty.call(metrics,key)) { 
                  let currentMobileScore = Math.round(this.calculateMetricCoverage(metrics[key].good, metrics[key].poor, mobileAverage[key]));
                  mobileScore += this.calculateWeightedScore(currentMobileScore, metrics[key].weight);
                  }
                });

                // let mobileData = false;
                // let desktopData = false;

                // console.log(psiData);

                if(this.isText(psiData.mobile.originData.performanceScore) || this.isText(psiData.desktop.originData.performanceScore)){
                  this.mobilePerformanceScore = NaN;
                  this.desktopPerformanceScore = NaN;
                }else{
                  this.mobileDataNotAvailable = false;
                  this.mobilePerformanceScore = mobileScore;
                  this.desktopDataNotAvailable = false;
                  this.desktopPerformanceScore = desktopScore;
                }

                // if(this.isText(psiData.desktop.originData.performanceScore)){
                // }else{
                // }

                // this.mobileDataNotAvailable = mobileData;
                // this.desktopDataNotAvailable = desktopData;

            if (latestData) {
              const parsedData = JSON.parse(latestData.data);
              ['desktop', 'mobile'].forEach((type) => {
                if (parsedData[type] && parsedData[type].originData.averageData) {
                  if (type === 'desktop' && !this.desktopDataNotAvailable) {
                    this.desktopAverageData = parsedData[type].originData.averageData;
                  } else if (type === 'mobile' && !this.mobileDataNotAvailable) {
                    this.mobileAverageData = parsedData[type].originData.averageData;
                  }
                }
              });
            } else {
              console.log("No latest data available");
            }
            this.apiData = results;
          })
          .catch(error => {
            console.error("Error fetching data:", error);
          });
      },
    positionTooltip(event, tooltipRef) {
      const tooltip = this.$refs[tooltipRef];
      if (!tooltip) return;
      tooltip.style.left = '15%';
      // Make the tooltip visible
      tooltip.style.visibility = 'visible';
      tooltip.style.opacity = '1';
  },
  hideTooltip(tooltipRef) {
  const tooltip = this.$refs[tooltipRef];
  if (tooltip) {
    tooltip.style.visibility = 'hidden';
    tooltip.style.opacity = '0';
  }
}, 
    getSeries(isDesktop) {
      const prefix = isDesktop ? 'desktop' : 'mobile';
      return [{
        name: this.activeTab,
        data: this[`${prefix}${this.activeTab}`] || []
      }];
    },
    updateCharts() {
      this.$nextTick(() => {
        if (this.$refs.chartDesktop && this.$refs.chartMobile) {
          this.$refs.chartDesktop.updateSeries(this.getSeries(true));
          this.$refs.chartMobile.updateSeries(this.getSeries(false));
        }
      });
    },
    updateChartOptions() {
    // Update chartOptions with new categories
    this.chartOptions = {
      ...this.chartOptions,
      xaxis: { ...this.chartOptions.xaxis, categories: this.categories }
    };
    this.chartOptions2 = {
      ...this.chartOptions2,
      xaxis: { ...this.chartOptions2.xaxis, categories: this.categories }
    };
  },
      getBorderColor(tab) {
      const colors = {
      LCP: '#EAB308',
      FID: '#1E40AF',
      CLS: '#EC4899',
      INP: '#9333EA',
      FCP: '#5EEAD4',
      TTFB: '#38BDF8',
    };
    return colors[tab] || '#CED0DD'; // Default color
  },
  getMetricInfo(name){
    const metricInfo = {
      LCP: {
        title: 'Avg LCP',
        value: (this.displayedData.LARGEST_CONTENTFUL_PAINT_MS_average),
        text: 'Largest Contentful Paint (LCP): Measures loading performance. (LCP) is an important, stable Core Web Vital metric for measuring perceived load speed because it marks the point in the page load timeline when the page\'s main content has likely loaded—a fast LCP helps reassure the user that the page is useful.To provide a good user experience, strive to have LCP occur within the first 2.5 seconds of the page starting to load.',
      },
      FID:
      {
        title: 'Avg FID',
        value: this.displayedData.FIRST_INPUT_DELAY_MS_average ,
        text: 'First Input Delay (FID): Measures interactivity. First Input Delay (FID) is the stable Core Web Vital metric for measuring load responsiveness because it quantifies the experience users feel when trying to interact with unresponsive pages—a low FID helps ensure that the page is usable. FID will be replaced by Interaction to Next Paint (INP) as a Core Web Vital in March 2024.To provide a good user experience, strive to have an FID of less than 100 milliseconds.',
      },
      CLS:{
        title: 'Avg CLS',
        value: this.displayedData.CUMULATIVE_LAYOUT_SHIFT_SCORE_average,
        text: 'Cumulative Layout Shift (CLS): Measures visual stability. Cumulative Layout Shift (CLS) is a stable Core Web Vital metric. It is an important, user-centric metric for measuring visual stability because it helps quantify how often users experience unexpected layout shifts—a low CLS helps ensure that the page is delightful.To provide a good user experience, strive to have a CLS score of less than 0.1.',
      },
      INP:{
        title: 'Avg INP',
        value: this.displayedData.INTERACTION_TO_NEXT_PAINT_average,
        text:'Interaction to Next Paint (INP) is a pending Core Web Vital metric that will replace First Input Delay (FID) in March 2024. INP assesses responsiveness using data from the Event Timing API. When an interaction causes a page to become unresponsive, that is a poor user experience. INP observes the latency of all interactions a user has made with the page, and reports a single value which all (or nearly all) interactions were below. A low INP means the page was consistently able to respond quickly to all—or the vast majority—of user interactions. To provide a good user experience, strive to have an INP of less than 200 milliseconds.',
      },
      FCP:{
        title: 'Avg FCP',
        value: this.displayedData.FIRST_CONTENTFUL_PAINT_MS_average,
        text: 'First Contentful Paint (FCP) is an important, user-centric metric for measuring perceived load speed because it marks the first point in the page load timeline where the user can see anything on the screen—a fast FCP helps reassure the user that something is happening. To provide a good user experience, strive to have an FCP of less than 1800 milliseconds.',  
    }, 
      TTFB:{
        title: 'Avg TTFB',
        value: this.displayedData.EXPERIMENTAL_TIME_TO_FIRST_BYTE_average,
        text:'Time to First Byte (TTFB) is a foundational metric for measuring connection setup time and web server responsiveness in both the lab and the field. It helps identify when a web server is too slow to respond to requests. In the case of navigation requests—that is, requests for an HTML document—it precedes every other meaningful loading performance metric. To provide a good user experience, strive to have an TTFB of less than 800 milliseconds.',  
    }, 
    }
    return metricInfo[name];
  }
    }
  }
  </script>
  

  <style scoped>
 
.uil-info-circle {
  position: relative; 
  cursor: pointer; 
}

.tooltiptext {
  visibility: hidden; 
  width: auto;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  position: absolute;
  z-index: 1;
  bottom: 70%; /* Position it above the icon */
  left: 25%; 
  margin-left: -60px; /* Pull it back to the left by half its width to center it */
  opacity: 0; /* Start fully transparent */
  transition: opacity 0.3s; /* Smooth transition for the tooltip appearance */
}

/* Show the tooltip text when hovering over the icon */
.uil-info-circle:hover + .tooltiptext {
  visibility: visible; /* Make the tooltip text visible */
  opacity: 1; /* Fade it in */
}
  .uil-info-circle {
    color: #000;
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
  }
  h3 {
    margin-bottom: 0;
  }
  .border-color {
    border-color: #CED0DD;
  }
  .border{
    border: 1px solid #CED0DD !important;
  }
  .row-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-left: -8px;
    --active-card-border-color: #CED0DD; /* Default color */
    --dot-color: #CED0DD;
  }
  .row-container .col-2 h3 {
    margin-bottom: 8px;
  }
  .row-container .col-2 p {
    margin-bottom: 8px;
  }
  .row-container .col-2 {
    border-bottom: 4px solid black;
    flex: 0 0 calc(16.6667% - 10px);
    margin: 5px;
  }
  .rounded-8 {
    border-radius: 8px;
  }
  .charts {
    margin-bottom: 36px;
  }

  .align-items-center{
    align-items: center;
  }
.min-height-155{
  min-height: 155px
}

.min-height-280{
  min-height: 280px;
}
  .dot {
  height: 8px;
  width: 8px;
  background-color: var(--active-card-border-color);
  border-radius: 50%;
  display: inline-block;
}
.circle{
  text-align: end;
}

  .card-hover {
    border: 1px solid #CED0DD !important;
    border-bottom: 4px solid #CED0DD !important;
}

.view:hover , .active{
  transform: scale(1.01);
  /* border-bottom: 4px solid #E2E8F0 !important; */
}

.active, .rounded-8{
  /* border-bottom: 4px solid #E2E8F0 !important; */
  box-shadow: #0000001a 0px 0px 1px 0px;
}

.card-hover:hover, .active-card {
  transform: scale(1.03);
}

.active-card:hover{
  transform: scale(1.03);
}

.active-card{
  border: 1px solid var(--active-card-border-color) !important;
  border-bottom: 4px solid var(--active-card-border-color) !important;
}

.active-view:hover{
  transform: scale(1.03);
}

.active-view{
  transform: scale(1.03);;
  /* border-bottom: 4px solid var(--active-view-border-color) !important; */
}

.active-view::after{
  content:'';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 10px solid var(--active-view-border-color) !important;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    top: 100%;
}

.active-card::after{
  content:'';
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 10px solid var(--active-card-border-color);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    top: 100%;
}

.view:hover, .card-hover:hover{
  cursor: pointer;
}

  </style>
  