<template>
  <div v-if="isPdf" data-tooltip="Preview not available for PDF" data-tooltip-position="top left">
    <img src="https://www.zamzar.com/images/filetypes/pdf.png" />
  </div>
  <div v-else>
    <div class="clickable base-list-image-column" @click="previewImage">
      <img :src="value" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },
  computed: {
    isPdf() {
      return this.value && this.value.substring(this.value.length - 4) === '.pdf'
    },
  },
  methods: {
    previewImage() {
      if (this.value) {
        this.$store.commit('app/setPreviewImage', this.value)
      }
    },
  },
}
</script>

<style scoped>
img {
  height: 30px;
}
</style>
