<template>
  <div class="col">
    <div class="card">
      <div class="card-header font-weight-bold">
        {{ findFieldByName('accordion_list_item_title').value }}
      </div>
      <div class="card-body">
        <p v-html="findFieldByName('accordion_list_item_content').value"></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    findFieldByName(name) {
      return this.moduleField.children.find(item => item.field.name === name)
    }
  },
  props: {
    fieldSpecificAttributes: {},
    moduleField: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped></style>
