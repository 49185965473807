<template>
  <table class="table table-fixed">
    <tbody>
      <tr
        v-for="(item, index) in data"
        v-bind:key="index"
        class="borderless"
        @click="rowClick('item' + index)"
      >
        <td>
          <div class="radio">
            <input
              type="radio"
              :value="item.value"
              :id="'item' + index"
              :checked="modelValue === item.value"
              @change="changedInput(item.value)"
            />
            <label :for="'item' + index">{{ item.label }}</label>
          </div>
        </td>
        <td class="text-left">
          <span v-if="item.enableBadge" class="badge" :class="item.badgeColor">{{ item.helpText }}</span>
          <span v-else>{{ item.helpText }}</span>
        </td>
        <td class="text-right">
          <span :data-tooltip="item.tooltip" data-tooltip-position="top center" data-tooltip-color="info"
            ><i class="uil uil-info-circle text-muted"></i
          ></span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    data: {
      type: [Array, Object],
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: () => ''
    }
  },
  methods: {
    changedInput(value) {
      this.$emit('update:modelValue', value)
    },
    rowClick(id) {
      document.getElementById(id).click()
    }
  }
}
</script>
