<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Confirm page path update and redirect creation</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-2">
          <p>
            To ensure that Google crawls the updated page, we have updated the page <strong>path</strong> as
            follows:
          </p>
          <base-input label="Events page default path prefix" v-model="currentPath" disabled />
          <div class="d-flex w-100 pl-2">
            <i class="uil uil-corner-down-right text-cobalt"></i>
            <base-input label="Events page reindexed path prefix" v-model="reindexPath" class="col" />
          </div>
          <hr />
          <div class="callout callout-info">
            After saving the page, a <strong>redirect</strong> will be added from the old URL to the new one
          </div>
          <p></p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="close()">Cancel</button>
          <button class="btn btn-primary" @click="submit()">Understood, continue!</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  components: { BaseInput },
  data() {
    return {
      loading: false,
      currentPath: null,
      reindexPath: null,
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(page) {
      const pageClone = { ...page }
      this.reindexPath = pageClone.path.replace(
        pageClone.path.split('/')[0],
        page.site.events_page_reindex_path_prefix
      )
      this.currentPath = page.path
      this.$refs.modal.classList.add('show')
    },
    submit() {
      this.$emit('reindexPage', this.reindexPath)
      this.close()
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
  },
}
</script>
