<template>
  <span :data-tooltip="tooltip && value">
    {{ text }}
  </span>
</template>

<script>
export default {
  computed: {
    text() {
      if (this.value?.length > this.limit) {
        return this.value.substring(0, this.limit) + '...'
      }
      return this.value
    }
  },
  props: {
    value: {},
    limit: {
      type: Number,
      default: () => 50
    },
    tooltip: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
