export default {
  getForm() {
    return [
      {
        name: 'site_id',
        search_url: 'hercules/sites/sites',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'site.name'
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets',
        required: true,
        display_name: 'Market',
        value_key: 'market.label',
        selectLabel: 'label',
        type: 'list-one'
      },
      {
        name: 'name',
        required: true,
        display_name: 'name',
        type: 'text',
        slug_field: 'short_name'
      },
      {
        name: 'short_name',
        required: true,
        display_name: 'Short name',
        type: 'slug',
        disabled: true
      },
      {
        name: 'orientation',
        field: 'orientation',
        required: true,
        type: 'list-one',
        options: ['horizontal', 'vertical'],
        prettyLabels: true,
        display_name: 'Orientation',
        trackBy: null,
        selectLabel: null
      },
      {
        name: 'active',
        required: false,
        display_name: 'Active',
        type: 'checkbox',
        default: 0
      }
    ]
  },
  getConfig() {
    return {
      url: 'sites/banners',
      name: 'Banner',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
