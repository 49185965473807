<template>
  <div>
    <ol class="breadcrumb mb-0">
      <li class="breadcrumb-item">
        <a>Home</a>
      </li>
      <li class="breadcrumb-item" v-for="(breadcrumb, index) in breadcrumbs" v-bind:key="index">
        <a
          :data-tooltip="breadcrumb.page_path"
          data-position-position="bottom center"
        >{{breadcrumb.label ? breadcrumb.label : breadcrumb.page_title}}</a>
      </li>
      <li class="breadcrumb-item active">Page name</li>
    </ol>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    breadcrumbs: {
      required: true,
    },
  },
  methods: {
    label(breadcrumb) {
      return breadcrumb.label ? breadcrumb.label : breadcrumb.page_title
    },
  },
}
</script>
