import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sports/site-tournaments/${id}`, {
      params: data
    })
  },
  get(data) {
    return Api().get(`hercules/sports/site-tournaments`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/sports/site-tournaments`, data)
  },
  update(data) {
    return Api().put(`hercules/sports/site-tournaments/${data.id}`, data)
  },
  updateSortOrder(data) {
    return Api().put(`hercules/sports/site-tournaments/sort-order`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sports/site-tournaments/${row.id}`)
  }
}
