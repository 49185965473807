export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'name',
        type: 'text'
      },
      {
        name: 'site_id',
        search_url: 'hercules/sites/sites',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'name',
        only_create: true,
        notify_fields: [
          { field: 'module_id', param: 'site_id' },
          { field: 'prefilled_module_markets', param: 'site_id' }
        ]
      },
      {
        name: 'prefilled_module_markets',
        display_name: 'Active markets',
        type: 'checkbox-list',
        search_url: 'hercules/sites/site-markets?with=market',
        selectLabel: 'site_market.market.label',
        trackBy: 'site_market.market.label',
        showPrimary: false,
        only_edit: true,
        enableToggleAll: true,
        notified_by: { field: 'site_id', label: 'Please select a site first.' }
      },
      {
        name: 'module_id',
        search_url: 'hercules/sites/modules',
        required: true,
        display_name: 'Module',
        type: 'list-one',
        selectLabel: 'label',
        notified_by: { field: 'site_id', label: 'Please select a site first.' },
        only_create: true
      },
      {
        name: 'module_id',
        search_url: 'hercules/sites/modules',
        required: true,
        display_name: 'Module',
        type: 'list-one',
        selectLabel: 'label',
        value_key: 'module.label',
        disabled: true,
        only_edit: true
      },
      {
        name: 'active',
        required: false,
        display_name: 'Active',
        type: 'checkbox',
        default: 1
      }
    ]
  },
  getConfig() {
    return {
      url: 'sites/prefilled-modules',
      name: 'Prefilled module',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
