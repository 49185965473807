<template>
  <div>
    <!-- Real image; hidden until loaded -->
    <img v-show="imageSrc && isLoaded" :src="imageSrc" @load="onImageLoad" :class="imageClass" />
    <!-- Placeholder image; shown until real image is loaded -->
    <span :class="imageClass">
      <img v-if="!isLoaded && imageSrc" src="/placeholder-spin.svg" :class="[imageClass, 'loading']" />
      <img v-else-if="!imageSrc" :src="imagePlaceholderSrc" :class="[imageClass]" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      required: false
    },
    imagePlaceholderSrc: {
      required: false,
      default: '/placeholder.svg'
      //   default: '/placeholder-spin.svg'
    },
    imageClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoaded: false
    }
  },
  methods: {
    onImageLoad() {
      this.isLoaded = true
    }
  }
}
</script>

<style scoped>
.loading {
  opacity: 0.1;
}
</style>
