<template>
  <div class="form-group">
    <label v-if="showLabel">{{ $prettyLabels(label) }}</label>
    <label class="text-muted ml-1 clickable" v-if="labelAction" @click="labelAction">{{
      labelActionTitle
    }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>
    <div :class="ratingsContainerClass">
      <base-input
        v-for="(rating, key) in field.inputs"
        :label="sanitizeInputLabel(rating)"
        v-bind:key="key"
        class="pr-2 flex-fill"
        v-model="value[rating]"
        :type="fieldType"
        :min="field.min"
        :max="field.max"
        :step="field.step"
        @update:modelValue="calculateAverage"
        @blur="formatNumberInput(rating)"
      />
    </div>
    <base-input
      v-if="showAverage"
      type="number"
      ref="averageValue"
      :label="averageInputLabel"
      step="any"
      :min="field.min"
      :max="field.max"
      v-model="value[field.name]"
      :readOnly="readonlyAverage"
    />
    <small v-if="helpText && showLabel" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  components: { BaseInput },
  computed: {
    averageInputLabel() {
      return `Average ${this.field.average_input}`
    },
    ratingsContainerClass() {
      return `d-flex flex-x${this.field.inputs.length}`
    },
  },
  data() {
    return {
      readonlyAverage: true,
    }
  },
  mounted() {
    if (this.getInputValues().length === 0) {
      this.readonlyAverage = false
    }
  },
  created() {
    this.calculateAverage()
  },
  methods: {
    formatNumberInput(rating) {
      if (!this.allow_text) {
        this.value[rating] = parseFloat(this.value[rating]).toFixed(1)
      }
    },
    getInputValues() {
      return this.field.inputs
        .map((input) => {
          return this.value[input]
        })
        .filter((item) => {
          return item !== '' && item !== null && item !== undefined
        })
    },
    calculateAverage() {
      if (this.showAverage) {
        let inputValues = this.getInputValues()

        if (inputValues.length > 0) {
          this.value[this.field.name] = this.average(inputValues, 1)
          this.readonlyAverage = true
        } else {
          this.readonlyAverage = false
        }
      }
    },
    sanitizeInputLabel(label) {
      return this.sanitizeLabel ? label.split('_')[this.labelIndex] : this.$prettyLabels(label)
    },
  },
  props: {
    sanitizeLabel: {
      type: Boolean,
      default: () => true,
    },
    labelIndex: {
      type: Number,
      default: () => 1,
    },
    showAverage: {
      type: Boolean,
      default: () => true,
    },
    showLabel: {
      type: Boolean,
      default: () => true,
    },
    label: {
      type: String,
    },
    labelActionTitle: {
      type: String,
    },
    labelAction: {
      type: Function,
    },
    helpText: {
      type: String,
    },
    value: {
      type: [Object],
    },
    loading: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    field: {
      type: Object,
    },
    fieldType: {
      type: String,
      default: () => 'number',
    },
    allow_text: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>
