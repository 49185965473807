import Api from './Api'

export default {
  getOne(id) {
    return Api().get(`hercules/globals/deposit-methods/${id}?with=support_types,selling_points`)
  },
  post(data) {
    return Api().post(`hercules/globals/deposit-methods`, data)
  },
  update(data) {
    return Api().put(`hercules/globals/deposit-methods/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/globals/deposit-methods/${row.id}`)
  }
}
