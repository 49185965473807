import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sites/sections/${id}`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/sites/sections`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/sections/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/sections/${row.id}`)
  }
}
