<template>
  <div>
    <page-edit-module-field
      v-for="(moduleField, index) in moduleValueFields()"
      v-bind:key="index"
      :index="index"
      :siteId="siteId"
      :affiliateId="affiliateId"
      :marketId="marketId"
      :moduleField="moduleField"
      :visibleFields="visibleFields"
    />
  </div>
</template>
<script>
import PageEditModuleField from '@templates/forms/module-dynamic-fields.vue'

export default {
  components: { PageEditModuleField },
  props: {
    siteId: {},
    affiliateId: {},
    marketId: {},
    pageModule: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  methods: {
    moduleValueFields() {
      this.pageModule.module_value_fields.forEach((item, index) => {
        if (item.field.name === 'iframe_lazy_loaded') {
          delete this.pageModule.module_value_fields?.splice(index, 1)
        }
      })

      this.pageModule.module_value_fields.sort(function(a, b) {
        return a.field_id - b.field_id
      })

      return this.pageModule.module_value_fields
    }
  },
  computed: {
    visibleFields() {
      return [
        'iframe_source',
        'iframe_clip_list',
        'iframe_clip_group',
        'iframe_clip_list_item_name',
        'iframe_clip_list_item_url',
        'iframe_clip_list_item_start_offset',
        'iframe_clip_list_item_end_offset'
      ]
    }
  }
}
</script>
