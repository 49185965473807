<template>
  <div>
    <div class="modal in" v-if="visible">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Hercules has been updated 🎉</h5>
            <button class="close" @click="close"></button>
          </div>
          <div class="modal-body pb-2">
            <p>
              You are not using the latest version of Hercules. Please reload the page to download the latest
              version.
            </p>
            <p class="text-muted">
              If you have unsaved changes, please save your work and then reload manually.
            </p>
          </div>
          <div class="modal-footer py-3">
            <button class="btn btn-primary" @click="reload"><i class="uil uil-refresh"></i>Reload now</button>
          </div>
        </div>
      </div>
    </div>
    <div class="notifications-container" @click="reload" v-if="minimized">
      <div class="alert alert-warning">
        You are using an old version of Hercules. Click <strong>here</strong> to download the latest version.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      minimized: false
    }
  },
  methods: {
    close() {
      this.minimized = true
      this.visible = false
    },
    reload() {
      location.reload()
    }
  },
  created() {
    const channel = window.pusher.subscribe('maintenance')
    channel.bind('versionUpdated', () => {
      this.visible = true
    })
  }
}
</script>

<style scoped>
.notifications-container {
  right: 20px;
  left: unset;
}
</style>
