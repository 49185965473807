import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sites/modules/${id}`, {
      params: data
    })
  },
  get(data) {
    return Api().get(`hercules/sites/modules`, {
      params: data
    })
  },
  add(data) {
    return Api().get(`hercules/sites/modules/clone`, { params: data })
  },
  persist(data) {
    return Api().post(`hercules/sites/modules/clone-persist`,   data)
  },
  delete(id) {
    return Api().delete(`hercules/sites/modules/${id}`)
  },
  allowedSectionModules(data) {
    return Api().get(`hercules/sites/modules/allowed-section`, {
      params: data
    })
  }
}
