<template>
  <span v-if="status === 'active'" class="badge bg-green">Active</span>
  <span v-else-if="status === 'inactive'" class="badge bg-black-25">Inactive</span>
  <span v-else-if="status === 'coming_soon'" class="badge bg-orange">Coming Soon</span>
  <span v-else-if="status === 'draft'" class="badge bg-black-25">Draft</span>
</template>

<script>
export default {
  props: {
    status: {},
    color: {}
  }
}
</script>
