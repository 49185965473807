import Api from '../Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sites/banners/${id}`, {
      params: data
    })
  },
  get(data) {
    return Api().get(`hercules/sites/banners`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/sites/banners`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/banners/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/banners/${row.id}`)
  }
}
