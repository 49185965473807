import Api from './Api'

export default {
  getAll(data) {
    return Api().get(`hercules/operators/affiliates`, {
      params: data
    })
  },
  getOne(affiliateId, data) {
    return Api().get(`hercules/operators/affiliates/${affiliateId}`, { params: data })
  },
  post(data) {
    return Api().post(`hercules/globals/sites`, data)
  },
  remove(data) {
    return Api().delete(`hercules/globals/sites/${data.id}`)
  },
  update(data) {
    return Api().put(`hercules/globals/sites/${data.id}`, data)
  }
}
