<template>
  <div class="color-wrapper" ref="colorPicker" v-click-outside-popover="hideColorPicker">
    <div class="color-picker">
      <button
        class="color-picker__color border-0"
        v-for="color in colors"
        :key="color"
        :class="{ active: color === activeColor }"
        :style="{ backgroundColor: color }"
        @click.stop.prevent="setColor(color)"
      />
      <button class="color-picker__trash" @click.stop.prevent="unsetColor">
        <i class="uil uil-trash" />
      </button>
    </div>
    <div class="px-3 pb-3 d-flex">
      <input class="form form-control form-control-sm w-80" type="text" v-model="colorHex" />
      <button class="btn btn-sm btn-secondary ml-2" @click.stop.prevent="setColor(colorHex)">Apply</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseColorpicker',
  directives: {
    'click-outside-popover': {
      mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
          // Here we'll check that the click was outside the element and call method
          if (!(el === event.target || el.contains(event.target))) {
            // And if it did, call method provided in attribute value
            binding.value(event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },
  props: {
    inputHex: {
      type: String,
      default: '#000000',
    },
  },
  data() {
    return {
      selectedColor: null,
      colors: [
        '#1ABC9C',
        '#61BD6D',
        '#54ACD2',
        '#2C82C9',
        '#9365B8',
        '#475577',
        '#CCCCCC',
        '#41A85F',
        '#00A885',
        '#3D8EB9',
        '#2969B0',
        '#553982',
        '#28324E',
        '#000000',
        '#F7DA64',
        '#FBA026',
        '#EB6B56',
        '#E25041',
        '#A38F84',
        '#EFEFEF',
        '#FFFFFF',
        '#FAC51C',
        '#F37934',
        '#D14841',
        '#B8312F',
        '#7C706B',
        '#D1D5D8',
      ],
      activeColor: '#000000',
      colorHex: this.inputHex,
    }
  },
  methods: {
    hideColorPicker() {
      this.$emit('hideColorPicker')
    },
    setColor(color) {
      this.colorHex = color
      this.activeColor = color
      this.$emit('setColor', color)
    },
    unsetColor() {
      this.colorHex = '#000000'
      this.activeColor = '#000000'
      this.$emit('unsetColor')
    },
  },
}
</script>

<style lang="scss" scoped>
.color-wrapper {
  z-index: 9999;
  top: 3rem;
  color: #222;
  background: white;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  max-width: 264px !important;
  .color-picker {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;

    .color-picker__color {
      width: 2rem;
      height: 2rem;
      border-radius: 0px;
      cursor: pointer;
      &:hover {
        border: 1px solid #000 !important;
      }
      &.active {
        border: 1px dashed black !important;
      }
    }
    .color-picker__trash {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      width: 2rem !important;
      height: 2rem !important;
      margin: 0 !important;
      &:hover {
        background: #f1f1f1;
      }
    }
  }
}
</style>
