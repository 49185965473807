<template>
  <div v-if="shouldShow" class="px-3 pt-3 border mb-3">
    <h5 class="text-danger mb-3">{{ warning }}</h5>

    <div v-if="Object.hasOwn(difference, 'sections')">
      The target page type does not have the following
      <strong>section(s)</strong>

      <ul>
        <li v-for="(section, index) in difference.sections" :key="index">{{ $prettyLabels(section) }}</li>
      </ul>
    </div>

    <div v-if="Object.hasOwn(difference, 'extra_fields')">
      The target page type does not have the following
      <strong>extra field(s)</strong>

      <ul>
        <li v-for="(field, index) in difference.extra_fields" :key="index">{{ $prettyLabels(field) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    difference: {},
    warning: {
      default: 'Changing page type is possible but please note the the following warnings',
    },
  },
  computed: {
    shouldShow() {
      if (!this.difference) {
        return false
      }
      return Object.hasOwn(this.difference, 'sections') || Object.hasOwn(this.difference, 'extra_fields')
    },
  },
}
</script>
