<template>
  <div class="row mt-5">
    <div class="card mx-auto mt-5" style="min-width: 350px">
      <div class="card-body text-center">
        <i class="uil uil-info-circle hero-icon"></i>
        <h2>Maintenance active</h2>
        <p class="pt-2">{{ $auth.user().settings.maintenance_mode_text }}</p>
      </div>
      <div class="card-footer text-center text-muted">
        Find us on slack <a href="slack://channel?team=T0707SH4P&id=CCFDPGRUL">#gm-alpha-hercules</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeMount() {
    // return to operators if maintenance mode is not enabled
    if (this.$auth.user().settings.maintenance_mode_enabled == 0) {
      // let's fully redirect them to the homepage, so that if any cache needs to removed files are re-downloaded
      window.location = '/'
    }
  }
}
</script>
