export default {
  getForm() {
    return [
      {
        name: 'title',
        required: true,
        display_name: 'Title',
        type: 'text'
      },
      {
        name: 'target_page_path',
        required: true,
        display_name: 'Path',
        type: 'text'
      },
      {
        name: 'site_id',
        search_url: 'hercules/sites/sites',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'site.name',
        notify_fields: [
          { field: 'target_site_market_id', param: 'site_id' },
          { field: 'target_page_type_id', param: 'site_id' }
        ]
      },
      {
        name: 'target_site_market_id',
        search_url: 'hercules/sites/site-markets?with=market',
        required: true,
        display_name: 'Market',
        type: 'list-one',
        value_key: 'site_market.market.label',
        selectLabel: 'market.label',
        notified_by: { field: 'site_id', label: 'Please select a site first.' }
      },
      {
        name: 'target_page_type_id',
        search_url: 'hercules/sites/templates?orderBy=name&ascending=1',
        required: true,
        display_name: 'Page type',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'template.name',
        notified_by: { field: 'site_id', label: 'Please select a site first.' }
      },
      {
        name: 'status',
        required: true,
        type: 'list-one',
        options: ['active', 'inactive'],
        trackBy: null,
        selectLabel: null
      }
    ]
  },
  getConfig() {
    return {
      url: 'sites/pages',
      name: 'Page',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
