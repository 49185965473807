<template>
  <div>
    <CoreWebVitals/>
  </div>
</template>

<script>
import CoreWebVitals from '@atoms/misc/core-web-vitals.vue'

export default {
  components:{
    CoreWebVitals
  }
}
</script>
