import Api from './Api'

export default {
  getOne(path, id) {
    return Api().get(`${path}/${id}`)
  },
  get(path, data) {
    return Api().get(path, {
      params: data
    })
  }
}
