import Image from '@tiptap/extension-image'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import CustomImageTemplate from './CustomImageTemplate.vue'
import { imageStyles } from '@/lib/tiptap/constants.js'

const getOriginalAttribute = (element, name) => {
  if (element.hasAttribute(`data-${name}`)) {
    return element.getAttribute(`data-${name}`)
  } else if (element.hasAttribute(name)) {
    return element.getAttribute(name)
  }
  return element.style[name]?.replace('px', '')
}

const getResizedAttribute = (element, name) => {
  if (element.hasAttribute(name)) {
    return element.getAttribute(name)
  } else if (element.hasAttribute(`data-${name}`)) {
    return element.getAttribute(`data-${name}`)
  }
  return element.style[name]?.replace('px', '')
}

export default Image.extend({
  // name: 'image',
  inline: true,
  marks: 'link',
  group: 'inline',
  draggable: false,
  addAttributes() {
    return {
      ...this.parent?.(),
      dir: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('dir')
        },
        renderHTML: (attributes) => {
          if (attributes.dir === null) {
            return {}
          }
          return { dir: attributes.dir }
        },
      },
      label: {
        parseHTML: (element) => element.getAttribute('data-anchor-label'),
        renderHTML: (attributes) => {
          return {
            'data-anchor-label': attributes.label,
          }
        },
      },
      originalWidth: {
        parseHTML: (element) => {
          return getOriginalAttribute(element, 'data-width')
        },
        renderHTML: (attributes) => {
          return {
            'data-width': attributes.originalWidth,
          }
        },
      },
      originalHeight: {
        parseHTML: (element) => {
          return getOriginalAttribute(element, 'data-height')
        },
        renderHTML: (attributes) => {
          return {
            'data-height': attributes.originalHeight,
          }
        },
      },
      resizedWidth: {
        parseHTML: (element) => {
          return getResizedAttribute(element, 'width')
        },
        renderHTML: (attributes) => {
          return {
            width: attributes.resizedWidth,
          }
        },
      },
      resizedHeight: {
        parseHTML: (element) => {
          return getResizedAttribute(element, 'height')
        },
        renderHTML: (attributes) => {
          return {
            height: attributes.resizedHeight,
          }
        },
      },

      style: {
        parseHTML: (element) => {
          return Object.keys(imageStyles).find((style) => element.classList.contains(style))
        },
        renderHTML: (attributes) => {
          return {
            class: attributes.style,
          }
        },
      },
      id: {},
      alt: {
        parseHTML: (element) => element.getAttribute('alt'),
        renderHTML: (attributes) => {
          return {
            alt: attributes.alt,
          }
        },
      },
      title: {
        parseHTML: (element) => element.getAttribute('title'),
        renderHTML: (attributes) => {
          return {
            title: attributes.title,
          }
        },
      },
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(CustomImageTemplate)
  },
  addCommands() {
    return {
      ...this.parent?.(),

      imageToFigure:
        (attributes) =>
        ({ tr, commands, editor }) => {
          const { selection } = tr
          const { node } = selection
          let attrs = node?.attrs || {}
          if (attributes) {
            // grab link attributes if image is wrapped in link
            attrs = Object.assign(attributes, editor.getAttributes('link'))
          } else {
            // grab link attributes if image is wrapped in link
            attrs = Object.assign(attrs, editor.getAttributes('link'))
          }

          return commands.insertContent({
            type: 'figure',
            attrs: attrs,
            content: [{ type: 'text', text: 'Your caption' }],
          })
        },
    }
  },
})
