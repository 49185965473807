import Api from './Api'

export default {
  getAll(affiliateId) {
    return Api().get(`hercules/operators/affiliate-markets-data`, {
      params: {
        affiliate_id: affiliateId
      }
    })
  },
  getOne(id, data) {
    return Api().get(`hercules/operators/affiliate-markets-data/${id}`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/operators/affiliate-markets-data`, data)
  },
  remove(data) {
    return Api().delete(`hercules/operators/affiliate-markets-data/${data.id}`)
  },
  update(data) {
    return Api().put(`hercules/operators/affiliate-markets-data/${data.id}`, data)
  },
  clone(data) {
    return Api().post(`hercules/operators/bonuses/${data.id}/clone`, data)
  },
}
