<template>
  <div class="card">
    <div class="card-header">
      <div class="right-element top-0 right-0 position-absolute m-3">
        <div class="badge bg-white-75">BETA</div>
      </div>
    </div>
    <div class="card-body text-center  animate fn-fade-in">
      <span class="btn btn-lg icon-button rounded-pill pointer-events-none bg-primary">
        <i class="uil uil-search text-white"></i>
      </span>
      <div class="mt-3">
        <span class="h3">Global Search</span>
        <span class="ml-2">BETA</span>
      </div>
      <p class="additional mt-3 font-size-lg">
        Press <code class="font-size-xl bg-secondary px-2 mx-1  py-1 rounded">/</code> to try it out
      </p>
    </div>
  </div>
</template>
<script>
export default {}
</script>
