<template>

  <div class="modal in" v-click-outside="close">

    <div class="modal-dialog modal-lg">

      <div class="modal-content">

        <div class="modal-header d-flex justify-content-between">

          <h5 class="modal-title">Heading anchor</h5>

          <button class="close p-0 m-0" v-on:click="close"></button>

        </div>

        <div class="modal-body pb-0">

          <base-input :required="true" label="label" v-model="selectedText" />

        </div>

        <div class="modal-footer">

          <button class="btn btn-info mr-2 ml-2" @click="submit">

            <i class="uil uil-link"></i>
             Set anchor
          </button>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
/* eslint-disable no-unused-vars */
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  components: {
    BaseInput
  },
  data() {
    return {
      attributes: { 'data-anchor-label': null, id: null }
    }
  },
  props: {
    editor: {}
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  computed: {
    selectedText: {
      get() {
        return this.$store.state.tiptap.selectedText
      },
      set(value) {
        this.$store.commit('tiptap/setSelectedText', value)
      }
    },
    anchorText: {
      get() {
        return this.attributes['data-anchor-label']
      },
      set(value) {
        // change the spaces to dashes and make it lowercase and don't allow other characters except numbers, letters, and dashes
        const anchorId = this.parseId(value)
        this.attributes = { id: anchorId, 'data-anchor-label': value }
      }
    }
  },

  methods: {
    parseId(text) {
      text = text.trimEnd().toLowerCase()
      const emojiRegex = /[\p{Emoji_Presentation}\p{Emoji}\u200d]/gu
      let sanitizedInput = ''
      for (let char of text) {
        if (char.match(/\p{L}|\p{N}/u) || char.match(emojiRegex)) {
          sanitizedInput += char
        } else if (char.match(/\s/)) {
          sanitizedInput += '-'
        }
      }
      return sanitizedInput.replace(/[#*]/g, '')
    },

    show() {
      const elem = this.editor.isActive('heading') ? 'heading' : 'paragraph'
      this.attributes = this.editor.getAttributes(elem)
      if (this.attributes['data-anchor-label']) {
        this.selectedText = this.attributes['data-anchor-label']
        return
      }
      const anchorId = this.parseId(this.selectedText)
      this.attributes = { id: anchorId, 'data-anchor-label': this.selectedText }
    },
    submit() {
      if (!this.attributes.id) return

      const anchorId = this.parseId(this.selectedText)
      this.attributes = { id: anchorId, 'data-anchor-label': this.selectedText }

      const elem = this.editor.isActive('heading') ? 'heading' : 'paragraph'
      this.editor
        .chain()
        .focus()
        .updateAttributes(elem, this.attributes)
        .run()

      this.close()
    },
    close() {
      this.$store.commit('tiptap/setSelectedText', '')
      this.$emit('closeAnchor')
    }
  }
}
</script>

