<template>
  <div>
    <ul :class="containerClass">
      <li v-bind:class="[activePage === 1 && 'disabled']">
        <a @click="() => goToPage(activePage - 1)">{{ previousText }}</a>
      </li>
      <li
        v-for="(item, index) in paginationList"
        v-bind:class="[activePage === item && 'active', item === '...' && 'disabled']"
        v-bind:key="index"
      >
        <a @click="() => goToPage(item)">{{ item }}</a>
      </li>
      <li v-bind:class="[activePage === pageCount && 'disabled']">
        <a @click="() => goToPage(activePage + 1)">{{ nextText }}</a>
      </li>
    </ul>
    <div v-if="showPageLabel" class="text-center">{{ paginationLabel }}</div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.paginationRefresh()
  },
  computed: {
    paginationLabel() {
      let finish = this.activeLimit * (this.activePage === 0 ? 1 : this.activePage)
      finish = finish > this.totalRows ? this.totalRows : finish

      const startOne =
        this.activePage === this.pageCount
          ? this.totalRows - (this.totalRows % this.activeLimit)
          : finish - this.activeLimit

      const start = this.activePage === 0 || this.activePage === 1 ? 1 : startOne + 1

      return `Showing ${start} to ${finish} of ${this.totalRows} records`
    },
  },
  watch: {
    activePage: function () {
      this.paginationRefresh()
    },
    pageCount: function () {
      this.paginationRefresh()
    },
  },
  data() {
    return {
      paginationList: [],
    }
  },
  props: {
    activePage: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    nextText: {
      type: String,
      default: () => 'Next',
    },
    previousText: {
      type: String,
      default: () => 'Previous',
    },
    containerClass: {
      type: String,
    },
    clickHandler: {
      type: Function,
    },
    totalRows: {
      type: Number,
    },
    activeLimit: {
      type: Number,
    },
    showPageLabel: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    goToPage(page) {
      this.clickHandler(page)
    },
    paginationRefresh() {
      let delta = 2,
        left = this.activePage - delta,
        right = this.activePage + delta + 1,
        range = [],
        rangeWithDots = [],
        l

      for (let i = 1; i <= this.pageCount; i++) {
        if (i == 1 || i == this.pageCount || (i >= left && i < right)) {
          range.push(i)
        }
      }

      // eslint-disable-next-line no-unused-vars
      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1)
          } else if (i - l !== 1) {
            rangeWithDots.push('...')
          }
        }
        rangeWithDots.push(i)
        l = i
      }

      this.paginationList = rangeWithDots
    },
  },
}
</script>
