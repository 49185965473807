<template>
  <div
    class="modal"
    ref="modal"
    tabindex="-1"
    role="dialog"
    id="toplist-edit-bonus-modal"
    v-click-outside="close"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header pb-4 d-flex flex-column position-relative">
          <div class="d-flex">
            <h5 class="modal-title" v-if="row">Edit bonus for {{ row.operator.name }}</h5>
            <button class="close" v-on:click="close"></button>
          </div>
        </div>
        <div class="modal-body" v-if="bonus">
          <base-input v-model="bonus.format" label="Bonus one-liner" :autoFocus="true" />
          <base-multiselect
            key="one_liner_types"
            :multiple="true"
            ref="bonusTypes"
            id="one_liner_types"
            path="hercules/operators/one-liner-types"
            field="one_liner_types"
            name="one_liner_types"
            label="Bonus Types"
            selectLabel="name"
            trackByOption="id"
            :value="bonus.one_liner_types"
            v-model="bonus.one_liner_types"
            @sync="syncOnelinerTypes"
          />
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <a class="btn btn-primary ml-3" :class="{ loading: isLoading }" @click="update">Update</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemRow from './item-row-modal.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'

export default {
  components: { ItemRow, BaseInput, BaseCheckbox, BaseMultiselect },
  data() {
    return {
      bonus: null,
      row: null,
      isLoading: false,
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(bonus, row) {
      this.row = row
      this.bonus = bonus
      this.$refs.modal.classList.add('show')
    },
    async update() {
      this.isLoading = true
      await this.$http
        .put(
          `hercules/operators/affiliate-operators/${this.bonus.affiliate_operator_id}/types/${this.bonus.id}`,
          {
            name: this.bonus.name,
            format: this.bonus.format,
            one_liner_types: this.bonus.one_liner_types,
          }
        )
        .then((response) => {
          if (response.data.success) {
            this.$emit('refresh', response.data.result, this.row)
            this.showSuccessMessage('Bonus updated')
          }
          this.close()
          this.item = null
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    syncOnelinerTypes(_field, value, key) {
      let newValue = null
      if (value && Array.isArray(value)) {
        newValue = value.map((item) => item[key])
      }
      this.bonus.one_liner_types = newValue
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
  },
}
</script>
