export default {
  methods: {
    getModuleFieldPreview: function(moduleField) {
      
      let label = null

      if (moduleField.field.name === 'spotlights_group') {
        moduleField.children.forEach(item => {
          if (item.field.name === 'spotlights_list_item_label' && item.value) {
            label = item.value
          }

          if (item.field.name === 'spotlights_list_item_link_text' && item.value) {
            label = item.value
          }

          if (item.field.name == 'spotlights_list_item_title' && item.value) {
            label = item.value
          }
        })
      }

      return label ? label : 'No preview available'
    }
  }
}
