import Api from './Api'

export default {
  getAll(data) {
    return Api().get(`hercules/pandora/page-speed`, {
      params: data
    })
  },
  getOne(affiliateId, data) {
    return Api().get(`hercules/pandora/page-speed/${affiliateId}`, { params: data })
  },
}
