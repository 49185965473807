export default {
  getForm() {
    return [
      {
        name: 'label',
        required: true,
        display_name: 'Label',
        type: 'text',
        slug_field: 'short_code'
      },
      {
        name: 'short_code',
        required: true,
        display_name: 'Short Code',
        type: 'slug',
        disabled: true
      },
      {
        name: 'enabled_sites',
        required: true,
        display_name: 'Enabled Sites',
        type: 'list-many',
        search_url: 'hercules/globals/sites',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true,
      },
    ]
  },
  getConfig() {
    return {
      url: 'toplists/prefilled-lists',
      name: 'List',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
