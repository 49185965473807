<template>
  <div class="preview-image-modal" v-click-outside="closeModal">
    <span class="close-icon" @click="closeModal"><i class="uil uil-times"></i></span>
    <Transition name="expand">
      <img :src="previewImage" v-if="isImageVisible" />
    </Transition>
  </div>
</template>

<script>
export default {
  directives: {
    'click-outside': {
      mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
          if (event.target === el) {
            binding.value(event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },

  data() {
    return {
      isImageVisible: false,
    }
  },
  props: {
    previewImage: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('app/setPreviewImage', '')
    },
    disableScroll() {
      document.body.classList.add('no-scroll')
    },
    enableScroll() {
      document.body.classList.remove('no-scroll')
    },
  },
  mounted() {
    this.disableScroll()
    setTimeout(() => {
      this.isImageVisible = true
    }, 100)
  },
  unmounted() {
    this.enableScroll()
    this.isImageVisible = false
  },
}
</script>

<style lang="scss">
.expand-enter-active {
  animation: expand-in 0.3s;
}
.expand-leave-active {
  animation: expand-in 0.3s reverse;
}

@keyframes expand-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.preview-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
}
.no-scroll {
  overflow: hidden;
}
</style>
