export default {
  hasPermission: function (permission) {
    if (!permission) return true
    return this.$auth.check() && this.$auth.user().permissions.includes(permission)
  },
  methodAllowed: function (method, path) {
    let errors = []
    if (!method) errors.push('method not defined for methodAllowed check')
    if (!path) errors.push('path not defined for methodAllowed check')
    if (errors.length > 0) {
      // eslint-disable-next-line no-console
      console.error(errors)
      return true
    }
    const fixedPath = new URL(path, window.location.origin).pathname
      .replace('/hercules', '')
      .replace('/v1', '')
      .substring(1)
    return this.hasPermission(`${method}.${fixedPath}`)
  },
  hasRole: function (role) {
    let hasRole = false
    if (this.$auth.check()) {
      const user = this.$auth.user()
      if (user.roles && user.roles.length > 0) {
        user.roles.some((rle) => {
          if (rle === role) {
            hasRole = true
            return true
          }
        })
      }
    }
    return hasRole
  },
  hasRoles: function (roles) {
    let hasRole = false
    roles.some((role) => {
      if (this.hasRole(role)) {
        hasRole = true
        return true
      }
    })

    return hasRole
  },
}
