import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sites/colors/${id}`, {
      params: data
    })
  },
  get(data) {
    return Api().get(`hercules/sites/colors`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/sites/colors`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/colors/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/colors/${row.id}`)
  }
}
