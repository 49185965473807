import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/sites/menus`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/sites/menus`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/menus/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/menus/${row.id}`)
  },
  clone(data) {
    return Api().post(`hercules/sites/menus/clone`, data)
  },
}
