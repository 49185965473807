import Maintenance from '@pages/misc/maintenance.vue'
import Dashboard from '@pages/misc/dashboard.vue'
import Profile from '@pages/misc/profile.vue'
import SiteLogs from '@pages/misc/site-logs.vue'

export default [
  {
    path: '/',
    component: Dashboard,
    name: 'Dashboard',
    meta: {
      parent: 'Home',
      auth: true,
      name: 'Dashboard'
    }
  },
  {
    path: '/profile',
    component: Profile,
    meta: {
      parent: 'Home',
      auth: true,
      name: 'Profile'
    }
  },
  {
    path: '/site-logs',
    component: SiteLogs,
    name: 'Site Logs',
    meta: {
      auth: true,
      parent: 'Home',
      name: 'Site Logs',
      permission: 'view.site-logs'
    }
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: {
      parent: 'Home',
      auth: true,
      bodyClass: 'bg-bluegrey-1'
    }
  }
]
