import Api from './Api'

export default {
  getAll(data = {}) {
    return Api().get('backoffice/settings', { params: data })
  },
  getOne(id) {
    return Api().get('backoffice/settings', { params: { id: id } })
  },
  update(data) {
    return Api().put('backoffice/settings', data)
  }
}
