<template>
  <div class="text-center position-relative" style="height: 60px" v-if="totalRows">
    <pagination
      ref="pagination"
      :page-count="pageCount"
      :click-handler="pageNumber => $emit('paginationClick', pageNumber)"
      container-class="pagination mb-2"
      v-if="pageCount > 1"
      :activeLimit="activeLimit"
      :totalRows="totalRows"
      :showPageLabel="true"
      :activePage="activePage"
    />
    <div class="position-absolute left-0 top-0 pt-3" v-if="enableDataExport">
      <button class="btn btn-secondary btn-sm icon-btn" @click="$emit('exportCsv')">
        <i class="uil uil-file-export"></i>Export to CSV
      </button>
    </div>
  </div>
</template>

<script>
import Pagination from '@atoms/pagination/pagination.vue'

export default {
  components: {
    Pagination
  },
  props: {
    stateName: {
      type: String,
      required: true
    },
    pageCount: {
      required: true
    },
    totalRows: {
      required: true
    },
    activeLimit: {
      required: true
    },
    activePage: {
      required: true
    },
    enableDataExport: {
      type: Boolean,
      default: () => true
    }
  }
}
</script>
