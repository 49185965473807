<template>
  <div
    v-if="value"
    @click="copyValue(getValue)"
    data-tooltip="Copy to clipboard"
    :class="customClass ? 'clipboard-column-custom' : 'clipboard-column'"
  >
    <span class="clickable d-flex align-items-center">
      <i class="uil uil-copy text-muted mr-1" />
      {{ getValue }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    customClass: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getValue() {
      return this.value?.name ? this.value?.name : this.value
    },
  },
  methods: {
    copyValue(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          this.showSuccessMessage('Copied to your clipboard.')
        })
        .catch((err) => {
          this.showErrorMessage('Failed to copy text: ', err)
        })
    },
  },
}
</script>

<style>
.clipboard-column {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clipboard-column-custom {
  max-width: unset;
}
</style>
