export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        slug_field: 'short_name',
      },
      {
        name: 'short_name',
        required: true,
        display_name: 'Short Name',
        type: 'slug',
        read_only: false,
      },
      {
        name: 'logo_asset_id',
        required: false,
        display_name: 'Logo',
        help_text: 'Standardised logo',
        type: 'base_media',
        substitute: 'image',
        permission: 'misc.cryptos/manage-standardised-logos',
        standardised: true,
      },
      {
        name: 'url',
        required: false,
        display_name: 'URL',
        type: 'text',
      },
      {
        name: 'rating',
        required: false,
        display_name: 'Ratings',
        type: 'average_number',
        inputs: [
          'rating_security',
          'rating_cost',
          'rating_privacy',
          'rating_usability',
          'rating_features',
          'rating_support',
        ],
        average_input: 'rating',
        min: 0,
        max: 5,
        step: 0.1,
      },
      {
        name: 'wallet_type_id',
        search_url: 'hercules/cryptos/wallet-types',
        required: false,
        display_name: 'Type of wallet',
        type: 'list-one',
        value_key: 'walletType.type',
        trackBy: 'id',
        selectLabel: 'type',
      },
      {
        name: 'price',
        required: false,
        display_name: 'Price',
        type: 'text',
      },
      {
        name: 'fees',
        required: false,
        display_name: 'Fees',
        type: 'text',
      },
      {
        name: 'number_of_cryptos',
        required: false,
        display_name: 'Number Of Cryptos',
        type: 'text',
      },
      {
        name: 'earning',
        required: false,
        display_name: 'Earning',
        type: 'text',
      },
      {
        name: 'hardware_compatibility',
        required: false,
        display_name: 'Hardware Compatibility',
        type: 'text',
      },
      {
        name: 'support_types',
        search_url: 'hercules/globals/support-types',
        display_name: 'Customer service',
        display_field: 'type',
        selectLabel: 'type',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'type',
        required: false,
        display_name: 'SPV or Full node',
        type: 'list-one',
        options: ['SPV', 'Full node'],
      },
      {
        name: 'currencies',
        required: false,
        display_name: 'Crypto Currencies',
        type: 'list-many',
        search_url: 'hercules/globals/currencies?&with_no_assets=1',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'wallet_alternatives',
        required: false,
        display_name: 'Wallet alternatives',
        type: 'list-many',
        search_url: 'hercules/cryptos/wallets',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true,
      },
      {
        name: 'standout_features',
        required: false,
        display_name: 'Standout features',
        type: 'text',
      },
      {
        name: 'support_page',
        required: false,
        display_name: 'Support Page',
        type: 'text',
      },
      {
        name: 'twitter',
        required: false,
        display_name: 'Twitter',
        type: 'text',
      },
      {
        name: 'facebook',
        required: false,
        display_name: 'Facebook',
        type: 'text',
      },
      {
        name: 'help_desk',
        required: false,
        display_name: 'Help Desk',
        type: 'text',
      },
      {
        name: 'blog',
        required: false,
        display_name: 'Blog',
        type: 'text',
      },
      {
        name: 'reddit',
        required: false,
        display_name: 'Reddit',
        type: 'text',
      },
      {
        name: 'custodial',
        required: false,
        display_name: 'Custodial',
        type: 'checkbox',
      },
      {
        name: 'mobile_app',
        required: false,
        display_name: 'Mobile App',
        type: 'checkbox',
      },
      {
        name: 'best_for',
        required: false,
        display_name: 'Best for',
        type: 'text',
      },
    ]
  },
  getConfig() {
    return {
      url: 'cryptos/wallets',
      name: 'Crypto Wallets',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
