import Api from './Api'
import _ from 'lodash'

export default {
  getAll(data = {}) {
    return Api().get('hercules/toplists/prefilled-lists', { params: data })
  },
  getOne(id, data) {
    return Api().get(`hercules/toplists/lists/${id}`, { params: data })
  },
  post(data) {
    return Api().post(`hercules/toplists/prefilled-lists`, data)
  },
  update(data) {
    return Api().put(`hercules/toplists/prefilled-lists/${data.id}`, data)
  },
  remove(data) {
    return Api().delete(`hercules/toplists/prefilled-lists/${data.id}`)
  },
  bulkRemove(data) {
    return Api().delete('hercules/toplists/prefilled-lists/bulk', {
      params: data
    })
  },
  fetchTopListItems(id, data) {
    return Api().get(
      `hercules/toplists/lists/${id}/items`,
      {
        params: data
      }
    )
  },
  sortListItems(data, listId) {
    return Api().post(`hercules/toplists/lists/${listId}/items/sort`, {
      items: data
    })
  },
  searchForLists(search, items) {
    return this.getAll({
      ids_not: items,
      label: search,
      fields_only: ['name'],
      limit: 10,
      page: 0,
      orderBy: 'name',
      ascending: 1
    })
  },
  searchForOperators(items, txtSearch, affiliateId, marketId, type, tracker, limit = 5) {
    return Api().get(`hercules/operators/operators`, {
      params: {
        ids_not: _.map(items, 'operator_id').join(','),
        market_affiliate_id: affiliateId,
        market_id: marketId,
        type: type,
        tracker: tracker,
        name: txtSearch,
        fields_only: [],
        limit: limit,
        page: 0,
        orderBy: 'name',
        ascending: 1,
        status: 1,
        with: 'affiliate_operators.links,bonuses'
      }
    })
  },
  deleteOperator(data) {
    return Api().delete('hercules/toplists/list-items/bulk', {
      params: data
    })
  }
}
