<template>
  <div class="d-flex toplist-item-tracker text-truncate">
    <div
      v-if="tracker === null || tracker.link == null || tracker.link === ''"
      class="badge bg-red clickable text-truncate"
      :class="[(row.removed || !allowBonusTrackerEdit) && 'pointer-events-none']"
      @click="linkEdit"
    >
      <div v-if="isLoading"><i class="uil uil-spin" /> <span class="badge-text">Loading..</span></div>
      <div v-else @click="linkEdit">
        <i class="uil uil-times" />
        <span class="badge-text  clickable"> Not valid</span>
      </div>
    </div>
    <div v-else-if="showFullTracker" class="badge badge-mint text-truncate clickable" @click="linkEdit">
      {{ tracker.link }}
    </div>
    <div v-else class="badge badge-mint clickable" @click="linkEdit">
      <i class="uil uil-check" /> <span class="badge-text"> Valid</span>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isLoading: false
    }
  },
  props: {
    allowBonusTrackerEdit: {
      required: false,
      default: true
    },
    affiliateOperator: {
      required: false
    },
    tracker: {
      required: true
    },
    showFullTracker: {
      type: Boolean,
      default: false
    },
    toplist: {
      required: false
    },
    row: {}
  },
  methods: {
    async linkEdit() {
      if (!this.allowBonusTrackerEdit || this.row.removed || this.isLoading) return
      const affiliateOperatorId = this.affiliateOperator.id
      this.isLoading = true
      await this.$http
        .get('hercules/operators/affiliate-links', {
          params: { affiliate_operator_id: affiliateOperatorId, name: this.toplist.tracker }
        })
        .then(response => {
          if (response.data.result.length === 1) {
            return response
          } else {
            return this.$http.post(`hercules/operators/affiliate-operators/${affiliateOperatorId}/links`, {
              affiliate_operator_id: affiliateOperatorId,
              name: this.toplist.tracker
            })
          }
        })
        .then(response => {
          if (response.data.success) {
            if (Array.isArray(response.data.result)) {
              this.$emit('updateTracker', response.data.result[0].id)
            } else {
              this.$emit('updateTracker', response.data.result.id)
            }
          } else {
            this.showErrorMessage('Failed to edit tracking link')
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false
          }, 200)
        })
    }
  }
}
</script>
