export default {
  getForm() {
    return [
      {
        name: 'site_id',
        type: 'hidden',
        notify_fields: [{ field: 'template_id', param: 'site_id' }]
      },
      {
        name: 'current_page_type',
        type: 'text',
        disabled: true
      },
      {
        name: 'page_type_id',
        search_url: 'hercules/sites/templates?orderBy=name&ascending=1',
        required: true,
        display_name: 'Target page type',
        type: 'list-one',
        selectLabel: 'name',
        notified_by: { field: 'site_id', label: 'Please select a site first.' }
      },
      {
        name: 'create_backup',
        required: false,
        display_name: 'Create a back-up of the original page',
        help_text: 'Link to the back-up will be available in the next step (back-up will be set to inactive)',
        type: 'checkbox'
      }
    ]
  },
  getConfig() {
    return {
      url: 'sites/pages',
      name: 'Page',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
