<template>
  <div>
    <div class="col">
      <div class="card-title">
        <div class="form-inline">
          <h2>{{ $prettyLabels(getModuleFieldLabel()) }}</h2>
          <div class="ml-auto">
            <button class="btn btn-info" @click="duplicate">
              {{ label }}
              <i class="uil uil-plus ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col module-group" v-for="(child, index) in moduleField.children" v-bind:key="index">
      <div class="d-flex justify-content-between">
        <h3># {{ index + 1 }}</h3>
        <button
          v-if="moduleField.children.length > 1"
          class="btn btn-sm btn-danger flex-end"
          @click="() => removeFromParent()"
        >
          Delete item
          <i class="uil uil-trash ml-2" />
        </button>
      </div>
      <slot :module="child"></slot>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'module-group-add-item',
  props: {
    moduleField: {
      default: () => [],
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: () => 'Add Item',
    },
    module: {},
    siteId: {},
    marketId: {},
  },
  methods: {
    getModuleFieldLabel() {
      const field = this.moduleField.field
      return field.label ? field.label : field.name
    },
    removeFromParent() {
      this.moduleField.children.splice(this.index, 1)
    },
    duplicate() {
      let last = _.cloneDeep(this.moduleField.children[0])
      last.id = 0
      last.value = ''
      last.children.forEach((child) => {
        child.value = null
        child.id = 0
        child.parent_id = 0
      })
      this.moduleField.children.push(last)
    },
  },
}
</script>

<style scoped></style>
