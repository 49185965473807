import Api from '../Api'

export default {
  getOne(data) {
    return Api().get(`koala/v1/accounts/${data.id}/credentials`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`koala/v1/accounts/${data.id}/credentials`, {
      password: data.password,
      api_credentials:this.prepareApiCredentials(data)
    })
  },

  update(data) {
    return Api().put(`koala/v1/accounts/${data.id}/credentials`, {
      password: data.password,
      api_credentials: this.prepareApiCredentials(data)
    })
  },

  prepareApiCredentials(data) {
    let  apiCredentials = {}

    if (data.api_key) {
      apiCredentials.api_key = data.api_key
    }

    if (data.api_username) {
      apiCredentials.api_username = data.api_username
    }

    //on BOS part the empty object was ignored and not sent to koala
    //this is why this code is here so the required property won't be ignored if is empty
    if (!(apiCredentials.api_username || apiCredentials.api_key))
    {
      apiCredentials = {
        '': ''
      }
    }

    return apiCredentials
  }
}
