export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        type: 'text'
      },
      {
        name: 'dynamic_data_parameter_name',
        required: true,
        type: 'text'
      }
    ]
  },
  getConfig() {
    return {
      name: 'Affiliate Platform',
      url: 'operators/v1/affiliate-platforms'
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
