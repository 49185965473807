import Api from '../../Api'

export default {
  get(id) {
    return Api().get(`backoffice/confluence`, {
      params: {
        id: id
      }
    })
  }
}
