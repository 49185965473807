<template>
  <div>
    <page-edit-module-field
      v-for="(moduleField, index) in pageModule.module_value_fields"
      v-bind:key="index"
      :index="index"
      :siteId="siteId"
      :affiliateId="affiliateId"
      :marketId="marketId"
      :moduleField="moduleField"
      :visibleFields="visibleFields"
    />
  </div>
</template>
<script>
import PageEditModuleField from '@templates/forms/module-dynamic-fields.vue'

export default {
  components: { PageEditModuleField },
  props: {
    siteId: {},
    affiliateId: {},
    marketId: {},
    pageModule: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  methods: {
    findFieldByName(pageModule, name) {
      return pageModule.module_value_fields.find(item => item.field.name === name)
    }
  },
  computed: {
    spotLightMode() {
      return this.findFieldByName(this.pageModule, 'spotlights_mode').value
    },
    visibleFields() {
      let visibleFields = ['spotlights_mode', 'spotlights_list', 'spotlights_group']
      switch (this.spotLightMode) {
        case 'image':
          return visibleFields.concat([
            'spotlights_list_item_image',
            'spotlights_list_item_label',
            'spotlights_list_item_link',
            'spotlights_list_item_link_text',
          ])
        case 'image_text':
          return visibleFields.concat([
            'spotlights_list_item_image',
            'spotlights_list_item_title',
            'spotlights_list_item_title_tag',
            'spotlights_list_item_subtitle',
            'spotlights_list_item_content',
            'spotlights_list_item_link',
            'spotlights_list_item_link_text',
            'spotlights_show_read_more',
            'spotlights_read_more_text'
          ])
        case 'icon':
          return visibleFields.concat([
            'spotlights_list_item_icon',
            'spotlights_list_item_link_text',
            'spotlights_list_item_link'
          ])
      }
      return visibleFields
    }
  }
}
</script>
