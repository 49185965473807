export default {
  getForm() {
    return [
      {
        name: 'enabled',
        type: 'checkbox',
        switch: true,
        help_text: 'Enable this provider so event pages can use it'
      },
      {
        name: 'step_1_heading',
        type: 'text'
      },
      {
        name: 'step_1_subheading',
        type: 'text'
      },
      {
        name: 'step_2_heading',
        type: 'text'
      },
      {
        name: 'step_2_subheading',
        type: 'text'
      },
      {
        name: 'step_3_heading',
        type: 'text'
      },
      {
        name: 'step_3_subheading',
        type: 'text'
      },
      {
        name: 'quality_content',
        type: 'textarea'
      },
      {
        name: 'selection_content',
        type: 'textarea'
      },
      {
        name: 'price_content',
        type: 'textarea'
      },
      {
        name: 'note',
        type: 'textarea'
      },
      {
        name: 'disclaimer',
        type: 'textarea'
      }
    ]
  },
  getConfig() {
    return {
      url: 'sports/site-sport-providers',
      name: 'Provider',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
