<template>
  <div :class="{ 'container-sm': basicTable, container: !basicTable }">
    <div :class="`checkbox ${extraClass}`" v-if="!inHeader">
      <input
        type="checkbox"
        :id="id"
        @click="() => $emit('bulkRowClick', id)"
        :checked="checked"
      />
      <label :for="id"></label>
    </div>
    <div class="dropdown d-inline-block" v-else>
      <button class="btn btn-secondary icon-button">
        <i :class="`uil ${toggleAllIcon}`"></i>
      </button>
      <ul class="dropdown-menu">
        <li @click="selectVisibleClick">Visible</li>
        <li @click="clearItems">Clear</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectVisible: false,
      selectAll: false,
    }
  },
  props: {
    basicTable: {
      type: Boolean,
      default: () => false,
    },
    stateName: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
    },
    checked: {
      type: Boolean,
      default: () => false,
    },
    inHeader: {
      type: Boolean,
      default: () => false,
    },
    extraClass: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    toggleAllIcon() {
      return this.bulkSelected.length > 0 ? 'uil-check-square' : 'uil-square'
    },
    bulkSelected: {
      set(value) {
        this.$store.dispatch(`${this.stateName}/setSelecteditems`, value)
      },
      get() {
        return this.$store.getters[`${this.stateName}/selecteditems`]
      },
    },
  },
  methods: {
    clearItems() {
      this.$store.dispatch(`${this.stateName}/resetBulk`)
      this.selectAll = false
      this.selectVisible = false
    },
    selectVisibleClick() {
      this.selectVisible = !this.selectVisible
      this.selectAll = false
      this.$emit('bulkToggleVisible', this.selectVisible)
    },
  },
}
</script>

<style scoped>
.container {
  padding: 0 0.75rem !important;
}

.container-sm {
  padding: 0.5rem !important;
}

.container .checkbox {
  margin-left: 21px !important;
}

.container-sm .checkbox {
  margin-left: 25px !important;
}

.uil {
  font-size: 26px !important;
}

.uil-square {
  color: #b0aaba;
}

.btn-secondary {
  background-position: calc(100% - 7px) center !important;
  padding-right: 60px !important;
  background-color: transparent !important;
  height: 27px !important;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' version='1'%3E%3Cpath fill='%23AAAABA' fill-rule='nonzero' d='M0 2l4 4a1 1 0 0 0 2 0l4-4a1 1 0 0 0-1-2H1a1 1 0 0 0-1 2z'/%3E%3C/svg%3E") !important;
}

.btn-secondary:hover {
  box-shadow: none !important;
}

.btn-secondary i {
  transform: translate(-78%, -50%) !important;
}

.dropdown-menu li {
  text-transform: capitalize;
}
</style>
