import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/games/games/${id}`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/games/games`, data)
  },
  update(data) {
    return Api().put(`hercules/games/games/${data.id}`, data)
  },
  remove(data) {
    return Api().delete(`hercules/games/games/${data.id}`)
  }
}