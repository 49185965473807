<template>
  <div :class="wrapperClass">
    <label v-if="second_label">{{ $prettyLabels(second_label) }}</label>
    <div class="d-flex">
      <span
        v-if="tooltip"
        :data-tooltip="tooltip"
        data-tooltip-color="info"
        data-tooltip-position="left center"
        class="mr-2"
      >
        <i class="uil uil-info-circle" />
      </span>
      <div
        v-bind:class="{ switch: this.switch, checkbox: !this.switch, 'form-control-sm': smallSwitch }"
        class="d-block mt-0"
      >
        <span v-if="required">&nbsp;*&nbsp;</span>
        <input
          v-bind="$attrs"
          :name="label && slugify(label)"
          v-model="checked"
          type="checkbox"
          :id="id"
          :disabled="checkIfDisabled()"
        />

        <label v-if="label" :for="id" class="no-selectable mb-0">{{ $prettyLabels(label) }}</label>
      </div>
    </div>
    <small v-if="helpText" class="d-block text-muted mt-2">{{ helpText }}</small>
  </div>
</template>

<script>
import { uniqueId } from 'lodash'
export default {
  props: {
    switch: {
      type: Boolean,
      default: false,
    },
    smallSwitch: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    second_label: {
      type: String,
    },
    modelValue: {
      default: '',
    },
    readOnly: {
      default: () => false,
    },
    emitOnGet: {
      default: () => false,
    },
    formType: {
      default: () => null,
      type: String,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
    helpText: {
      type: String,
    },
    wrapperClass: {
      default: 'mb-3',
    },
    saveInStore: {
      default: () => false,
      type: Boolean,
    },
  },
  computed: {
    id() {
      return uniqueId('id')
    },
    checked: {
      get: function () {
        if (this.emitOnGet) {
          this.$emit('update:modelValue', this.modelValue)
        }
        if (this.saveInStore && this.$store.state.baseSelect.baseCheckboxValue !== null) {
          this.$emit('update:modelValue', this.$store.state.baseSelect.baseCheckboxValue)
          return this.$store.state.baseSelect.baseCheckboxValue
        }
        return this.parseBoolean(this.modelValue)
      },
      set: function (newValue) {
        if (this.saveInStore) {
          this.$store.commit(
            'baseSelect/setBaseCheckboxValue',
            this.modelValue.length ? this.modelValue : newValue
          )
        }
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  unmounted() {
    if (this.saveInStore) {
      this.$store.commit('baseSelect/setBaseCheckboxValue', null)
    }
  },
  methods: {
    parseBoolean(value) {
      if (value == 1 || value == true) {
        return true
      } else if (value == 0 || !value || value == null) {
        return false
      }
      return false
    },
    checkIfDisabled() {
      if (this.readOnly || (this.readOnly && this.formType && this.formType === 'edit')) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    checked() {
      if (this.$refs.validator) {
        this.$refs.validator.validate()
      }
    },
  },
}
</script>
