<template>
  <div
    class="d-flex align-items-center border rounded my-1 p-2 clickable item-row-modal"
    :class="wrapperClass"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="event => rowClick(event, row)"
  >
    <div class="toplist-item-operator-name">
      <a class="" :href="`/operators/${row.operator.id}`" target="_blank">{{ row.operator.name }}</a>
    </div>
    <div class="toplist-item-bonus-tracker" v-if="!isPrefilledTemplate">
      <item-column-bonus
        v-if="affiliateOperator"
        :bonus="bonus"
        :row="row"
        :toplist="toplist"
        :showFullBonus="showFullBonus"
        :affiliateOperator="affiliateOperator"
        :allowBonusTrackerEdit="false"
      />
      <item-column-tracker
        v-if="affiliateOperator"
        :toplist="toplist"
        :tracker="tracker"
        :row="row"
        :affiliateOperator="affiliateOperator"
        :showFullTracker="showFullTracker"
        :allowBonusTrackerEdit="false"
      />
      <div class="toplist-item-sites-data-missing" v-if="affiliateOperator === null">
        <div class="badge bg-orange font-weight-normal">
          <i class="uil uil-exclamation-triangle" />Missing
          <span class="d-none d-md-inline-block">sites</span> data
        </div>
      </div>
    </div>
    <div class="toplist-item-status"><OperatorStatus :status="status" /></div>
    <div class="toplist-item-position">
      <base-input
        type="number"
        v-model="row.sort_nr"
        :defaultInputClass="false"
        :showLabel="false"
        :autoFocusDelay="10"
        :readOnly="row.added === false"
        ref="position"
      />
    </div>
    <div class="toplist-item-actions">
      <div class="checkbox">
        <input type="checkbox" :id="row.id" :checked="row.added" />
        <label :id="row.id"></label>
      </div>
    </div>
  </div>
</template>

<script>
import ItemColumnTracker from './item-colum-tracker.vue'
import ItemColumnBonus from './item-colum-bonus.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import OperatorStatus from "@atoms/table/cell/operator-status.vue";
export default {
  components: {OperatorStatus, ItemColumnTracker, ItemColumnBonus, BaseInput, BaseCheckbox },
  data: () => {
    return { isLoading: false, hover: false }
  },
  props: {
    row: {
      type: Object,
      required: true
    },
    toplist: {
      type: Object
    },
    index: {},
    wrapperClass: {},
    showFullBonus: {},
    showFullTracker: {}
  },
  methods: {
    rowClick(event, row) {
      if (event.target.classList.contains('form-control')) {
        return event.preventDefault()
      }
      if (row.added) {
        row.sort_nr = null
        this.$emit('remove', row)
      } else {
        this.$emit('add', row)
        this.$refs.position.setFocused()
      }
    }
  },
  computed: {
    affiliateOperator() {
      if (!this.row.operator.affiliate_operators) {
        return null
      }
      return this.row.operator.affiliate_operators[0] || null
    },
    tracker() {
      if (this.affiliateOperator === null) {
        return null
      }
      return this.affiliateOperator.links[0] || null
    },
    bonus() {
      if (this.affiliateOperator === null) {
        return null
      }
      return this.affiliateOperator.types[0] || null
    },
    isPrefilledTemplate() {
      return this.toplist.list_type === 'prefilled_template'
    },
    status() {
      if (this.affiliateOperator === null) {
        return null
      }
      return this.affiliateOperator.status || null
    },
  }
}
</script>

<style lang="scss">
.item-row-modal {
  &:hover {
    background: #fbfbff;
  }
}
.toplist-item-position {
  display: flex;
  input {
    width: 100px;
    background: #ebf1ff;
    height: 34px;
    font-size: 12px;
  }
}
</style>
