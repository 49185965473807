import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/sports/providers`, {
      params: data
    })
  },
  getOne(id) {
    return Api().get(`hercules/sports/providers/${id}`)
  },
  post(data) {
    return Api().post(`hercules/sports/providers`, data)
  },
  update(data) {
    return Api().put(`hercules/sports/providers/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sports/providers/${row.id}`)
  }
}
