<template>
  <div class="d-flex">
    <div class="form-group">
      <label>{{ $prettyLabels(label) }}</label>
      <input v-bind="$attrs" type="text" :readonly="readOnly" v-model="color" class="form-control" />
    </div>
    <div class="form-group ml-4">
      <label>Color picker</label><span v-if="required">&nbsp;*&nbsp;</span>
      <label v-if="value" class="text-muted ml-2" @click="$emit('input', null)">clear</label>
      <input
        :name="label && slugify(label)"
        type="color"
        :readonly="readOnly"
        :required="required"
        v-model="color"
        class="form-control"
        style="padding: 4px 6px"
      />
    </div>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    helpText: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: () => '',
    },
    readOnly: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
  },
  mounted() {
    this.data = this.value
  },
  computed: {
    color: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      },
    },
  },
}
</script>
