<template>
  <div>
    <data-table
      model="DepositMethodMarketsData"
      service-file="DepositMethodMarketsDataService"
      :serviceParams="[deposit_method_id]"
      :path="path()"
      :columns="columns()"
      :create-defaults="createDefaults()"
      :column-templates="columnTemplates()"
      ref="list"
      :showCreate="true"
    />
  </div>
</template>

<script>
export default {
  name: 'markets-data',
  data() {
    return {
      deposit_method_id: this.$route.params['id'],
      title: `Markets Data`,
    }
  },
  methods: {
    columns() {
      return [
        { field: 'market.label', label: 'Market', type: 'text', filterable: true },
        { field: 'min_deposit', type: 'text', filterable: true },
        { field: 'rating', type: 'text', filterable: true },
      ]
    },
    path() {
      return `hercules/globals/deposit-methods-markets-data?deposit_method_id=${this.$route.params.id}&with=market,sellingPoints`
    },
    columnTemplates() {
      return Object.assign({
        market: function (row) {
          return row.market?.label
        },
      })
    },
    createDefaults() {
      return {
        deposit_method_id: this.$route.params['id'],
      }
    },
  },
}
</script>
