<template>
  <div class="col">
    <div class="card" v-if="moduleField">
      <div class="card-header align-self-center">
        {{ title }}
        {{ linkText }}
      </div>
      <div class="card-body text-center">
        <async-image
          v-if="fieldSpecificAttributes.mode === 'icon' && icon"
          :id="icon"
          class="image-fluid spotlight-img-icon"
        />
        <async-image
          v-if="fieldSpecificAttributes.mode === 'image' && icon"
          :id="image"
          class="image-fluid spotlight-img"
        />
      </div>
    </div>
  </div>
</template>
<script>
import AsyncImage from '@atoms/cms/modules/preview/async-image.vue'

export default {
  components: { AsyncImage },
  methods: {
    findFieldByName(name) {
      return this.moduleField.children.find(item => item.field.name === name)?.value
    },
    getImage() {
      const field = this.findFieldByName('spotlights_list_item_image')
      return `http://assets.local.com/v0.1/files/${field.value}/url`
    }
  },
  computed: {
    title() {
      return this.findFieldByName('spotlights_list_item_title')
    },
    linkText() {
      return this.findFieldByName('spotlights_list_item_link_text')
    },
    icon() {
      return this.findFieldByName('spotlights_list_item_icon')
    },
    image() {
      return this.findFieldByName('spotlights_list_item_image')
    }
  },
  props: {
    fieldSpecificAttributes: {},
    moduleField: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.spotlight-img {
  max-height: 100px;
  height: 100px;
}
.spotlight-img-icon {
  max-height: 60px;
  height: 60px;
}
</style>
