export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        slug_field: 'short_name'
      },
      {
        name: 'short_name',
        required: true,
        display_name: 'Short Name',
        type: 'slug',
        read_only: false
      },
      {
        name: 'standardised_logo_asset_id',
        required: false,
        display_name: 'Standardised Logo',
        help_text:
          'Standardised logos must be requested from the design team and uploaded by a Hercules admin',
        type: 'base_media',
        substitute: 'image',
        permission: 'misc.globals/manage-standardised-logos',
        standardised: true
      },
      {
        name: 'rating',
        required: false,
        display_name: 'Rating',
        type: 'number',
        min: 0,
        max: 5,
        step: 0.1
      },
      {
        name: 'payout_time',
        required: false,
        display_name: 'Payout Time',
        type: 'text'
      },
      {
        name: 'min_deposit',
        required: false,
        display_name: 'Min Deposit',
        type: 'text'
      },
      {
        name: 'selling_points',
        search_url: 'hercules/globals/selling-points',
        display_name: 'Selling Points',
        display_field: 'type',
        selectLabel: 'name',
        type: 'list-many',
        validate_changes: true,
        fetch_on_load: false
      },
      {
        name: 'support_types',
        search_url: 'hercules/globals/support-types',
        display_name: 'Support Types',
        display_field: 'type',
        selectLabel: 'type',
        type: 'list-many',
        validate_changes: true,
        fetch_on_load: false
      }
    ]
  },
  getConfig() {
    return {
      url: 'globals/deposit-methods',
      name: 'Payment Method',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
