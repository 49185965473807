import Api from './Api'

export default {
  get(affiliateOperatorId, data) {
    return Api().get(`hercules/operators/affiliate-operators/${affiliateOperatorId}/extra-field-values`, {
      params: data
    })
  },
  getOne(affiliateOperatorId, fieldId, data) {
    return Api().get(
      `hercules/operators/affiliate-operators/${affiliateOperatorId}/extra-field-values/${fieldId}`,
      {
        params: data
      }
    )
  },
  update(affiliateOperatorId, data) {
    return Api().put(
      `hercules/operators/affiliate-operators/${affiliateOperatorId}/extra-field-values/${data.id}`,
      data
    )
  }
}
