import Api from '../Api'

export default {
  getEnvironments(data) {
    return Api().get(`gigfarm/v1/brew/list/envs`, {
      params: data
    })
  },
  getDomains() {
    return Api().get(`gigfarm/v1/brew/list/domains`)
  },
  sync(type, data) {
    return Api().post(`gigfarm/v1/brew/sync/${type}`, data)
  },
  status(jobId) {
    return Api().get(`gigfarm/v1/jobs/status/${jobId}`)
  },
}
