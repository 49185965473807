<template>
  <data-table
    model="SupportTypes"
    service-file="SupportTypesService"
    path="hercules/globals/support-types"
    :columns="columns()"
    :rowActionButtons="rowActions()"
    :column-templates="columnTemplates()"
  />
</template>

<script>
export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'type', type: 'text', label: 'Name', filterable: true },
        { field: 'short_name', type: 'text', filterable: true }
      ]
    },
    columnTemplates() {
      return Object.assign({
        short_name: function(row) {
          return row.short_name
        },
      })
    },
    rowActions() {
      return [
        {
          action: this.translationAction,
          title: 'Translations',
          icon: 'uil uil-adjust',
          class: 'btn-info'
        }
      ]
    },
    translationAction(row) {
      let routeData = this.$router.resolve('translations?key=' + row.short_name)
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
