<template>
  <div class="card">
    <div class="card-body mt-2">
      <div class="text-center">
        <h1 class="my-1">Welcome to Hercules!</h1>
        <div class="card-text mt-3 d-flex justify-content-center flex-column" style="font-weight:600">
          <span class="mb-1">There is no role assigned to your user 🥹</span>
          <span>Please contact a user manager on Slack to assign you a role so you can start using Hercules.</span>
          <div class="text-muted mt-3 p-1 d-flex justify-content-center">
            Clicking on any of the users below will open Slack automatically
            <i class="uil uil-corner-right-down ml-1 mt-1"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-wrap mx-5 mb-4">
      <a class="m-1 mx-2 link-primary" v-for="(user, key) in users"
        :key="key"
        :href="'https://gig.slack.com/team/' + user.email"
        @click.prevent="openChat(user)">
        @{{ user.name }}<span v-if="users.length - 1 !== key">,</span><span v-else>.</span>
      </a>
    </div>
  </div>
</template>
<script>
import UsersService from '@services/UsersService.js'

export default {
  created() {
    this.getUsers()
  },
  data() {
    return {
      users: [],
    }
  },
  methods: {
    async openChat(user) {
      await UsersService.getSlackInfoBasedOnEmail(user.email).then((response) => {
        if(response.data.messages) {
          this.showErrorMessages(response.data.messages)
          return
        }
        window.open(`https://gig.slack.com/team/${response.data.result[0]}`);
      })
    },
    getUsers() {
      UsersService.getUsersByRole({roles: 'user manager'}).then((response) => {this.users = response.data.result.sort((a, b) => a.name.localeCompare(b.name))})
    },
  },
}
</script>
