<template>
  <div :class="wrapperClass" :hidden="type == 'hidden'">
    <label v-if="label" class>{{ $prettyLabels(label) }}</label>

    <span
      class="text-muted ml-1 clickable"
      :class="{ 'absolute-clear-button': clearableAbsolutePositioned }"
      v-if="canBeCleared"
      @click="$emit('update:modelValue', null)"
    >
      clear
    </span>

    <span v-if="required">&nbsp;*&nbsp;</span>

    <template v-if="notifiedByField && !$parent.object[notifiedByField.field]">
      <span class="text-muted ml-2">{{ notifiedByField.label }}</span>
    </template>

    <div class="dropdown" v-if="options.length > 0 || !loadOnMount">
      <div class="multiselect__arrow" :class="{ active: showDropdown }"></div>

      <input
        type="text"
        :disabled="disabled || (notifiedByField && !$parent.object[notifiedByField.field])"
        @click="dropdownClicked"
        class="form-control"
        v-model="searchOptionLabel"
        :placeholder="placeholder"
        ref="dropdownInput"
        @keypress.enter.exact.prevent="selectFirstOption"
        @keyup.esc.exact.prevent="hideDropdown"
      />

      <ul class="dropdown-menu" :class="[showDropdown ? 'd-block' : 'd-none']">
        <li v-if="loading && !customOptions">Loading ...</li>

        <li @click="$emit('update:modelValue', null)" v-if="showAllOption">All</li>

        <li
          v-for="(option, key) in filteredOptions"
          v-bind:modelValue="option instanceof Object ? option[trackBy] : option"
          v-bind:key="key"
          v-bind:class="{
            active:
              (option instanceof Object ? data === option[trackBy] : data === option) && !removeActiveText,
            'active-item':
              (option instanceof Object ? pointer === option[trackBy] : pointer === option) &&
              (option instanceof Object ? data !== option[trackBy] : data !== option) &&
              allowNullOption &&
              !removeActiveText,
            'active-item-remove':
              (option instanceof Object ? pointer === option[trackBy] : pointer === option) &&
              (option instanceof Object ? data === option[trackBy] : data === option) &&
              allowNullOption &&
              !removeActiveText,
          }"
          class="text-truncate"
          @click="selectItem(option)"
        >
          {{ prettifyLabels(Object.hasOwn(option, optionLabelKey) ? option[optionLabelKey] : option) }}
          <span
            v-if="option instanceof Object ? data === option[trackBy] : data === option"
            class="float-right"
          >
            {{
              (option instanceof Object ? pointer !== option[trackBy] : pointer !== option) &&
              allowNullOption &&
              !removeActiveText
                ? 'Selected'
                : ''
            }}
          </span>
        </li>

        <li @click="hideDropdown" v-if="searchQuery && filteredOptions.length === 0 && !loading">
          No matching records
        </li>

        <li @click="hideDropdown" v-if="!searchQuery && filteredOptions.length === 0 && !loading">
          No records found
        </li>
      </ul>
    </div>

    <div v-else class="form-control text-muted">
      <template v-if="loading">
        <span>Loading ...</span>
      </template>

      <template v-else>
        <span>No {{ noOptionsPlaceholder }} available</span>

        <span v-if="noOptionsLink">
          , click
          <a v-if="noOptionsLink" :href="noOptionsLink" target="blank"> here </a>
          to add one
        </span>
      </template>
    </div>

    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import GeneralService from '../../services/GeneralService'
export default {
  props: {
    removeActiveText: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      default: () => 'form-group position-relative',
    },
    capitalizeLabel: {
      default: true,
      type: Boolean,
    },
    clearableAbsolutePositioned: {
      default: () => false,
    },
    clearable: {
      default: () => false,
    },
    clearableIfOneOption: {
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => {},
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select an option',
    },
    label: {
      type: String,
    },
    helpText: {
      type: String,
    },
    optionLabelKey: {
      type: String,
      default: 'label',
    },
    modelValue: {
      default: () => null,
    },
    customOptions: {
      default: () => null,
    },
    readOnly: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    selectFirstAfterFetch: {
      default: () => false,
      type: Boolean,
    },
    selectFirstIfOneResult: {
      // select first item if there's only 1 option
      default: () => false,
      type: Boolean,
    },
    loadOnMount: {
      // load options on mount
      default: () => true,
      type: Boolean,
    },
    path: {
      default: () => null,
      type: String,
    },
    emitOption: {
      type: String,
      default: () => null,
    },
    noOptionsPlaceholder: {
      type: String,
      default: () => 'options',
    },
    noOptionsLink: {
      type: String,
    },
    trackBy: {
      type: String,
      default: () => 'id',
    },
    showAllOption: {
      type: Boolean,
      default: () => false,
    },
    notifiedByField: {
      type: Object,
      default: () => null,
    },
    allowNullOption: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: 'list-one',
    },
    extraOption: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      data: this.modelValue instanceof Object ? this.modelValue[this.trackBy] : this.modelValue,
      options: [],
      loading: false,
      optionItems: [],
      searchQuery: '',
      placeholderLabel: '',
      pointer: 0,
      focusedIndex: 0,
      showDropdown: false,
    }
  },
  computed: {
    focusedIndexLabel() {
      if (this.focusedIndex) {
        return this.optionItems[this.focusedIndex][this.optionLabel]
      }
      return null
    },
    searchOptionLabel: {
      get() {
        if (this.showDropdown) {
          return this.searchQuery
        }
        return this.activeOptionLabel
      },
      set(newValue) {
        this.searchQuery = newValue
      },
    },

    filteredOptions() {
      let filteredOptions = this.optionItems.filter((item) => {
        if (item instanceof Object) {
          return item[this.optionLabelKey]
            ? item[this.optionLabelKey].toLowerCase().includes(this.searchQuery.toLowerCase())
            : ''
        }
        return item.toLowerCase().includes(this.searchQuery.toLowerCase())
      })

      return filteredOptions
    },
    canBeCleared() {
      if (this.clearable && this.data) {
        if (this.filteredOptions.length === 1) {
          return this.clearableIfOneOption
        } else {
          return true
        }
      }
      return false
    },
    activeOptionLabel() {
      const match = this.options.find((option) => {
        return option[this.trackBy] === this.modelValue
      })

      if (match) {
        return match[this.optionLabelKey]
      }

      return this.placeholder
    },
  },
  created() {
    if (this.customOptions) {
      this.options = this.customOptions
    } else if (
      (this.path && this.loadOnMount) ||
      this.data ||
      (this.notifiedByField &&
        this.$parent.$parent.object &&
        this.$parent.$parent.object[this.notifiedByField.field])
    ) {
      this.getOptions()
    }

    if (this.modelValue instanceof Object) {
      this.$emit(
        'update:modelValue',
        this.data,
        this.emitOption
          ? this.options.filter((item) => {
              if (item[this.trackBy] === this.modelValue[this.trackBy]) {
                return item
              }
            })[0][this.emitOption]
          : ''
      )
    }

    window.addEventListener('click', (e) => {
      // when clicked outside, hide dropdown
      if (this.$el.querySelector('.dropdown') && !this.$el.querySelector('.dropdown').contains(e.target)) {
        this.hideDropdown()
      }
    })
  },
  watch: {
    modelValue() {
      this.data = this.modelValue instanceof Object ? this.modelValue[this.trackBy] : this.modelValue
    },
    customOptions() {
      this.options = this.customOptions
    },
    options() {
      this.optionItems = this.options
    },
  },
  methods: {
    dropdownClicked() {
      this.path && !this.loadOnMount && this.options.length == 0 && this.getOptions()
      this.searchQuery = ''
      this.$refs.dropdownInput.focus()
      this.showDropdown = true
    },
    selectItem(option) {
      if ((option instanceof Object ? option[this.trackBy] : option) == this.data && this.allowNullOption) {
        this.data = null
      } else {
        this.data = option instanceof Object ? option[this.trackBy] : option
      }

      this.hideDropdown()

      this.$emit(
        'update:modelValue',
        this.data,
        this.emitOption
          ? this.options.filter((item) => {
              if (item[this.trackBy] === option[this.trackBy]) {
                return item
              }
            })[0][this.emitOption]
          : ''
      )
    },
    getOptions(selectFirst = false) {
      let data = {}
      if (this.notifiedByField && this.$parent.$parent.object) {
        if (this.notifiedByField.request_field_name) {
          data[this.notifiedByField.request_field_name] = this.$store.getters['baseSelect/getExtraOption']
        } else {
          data[this.notifiedByField.field] = this.$parent.$parent.object[this.notifiedByField.field]
        }
      } else {
        data = this.parameters
      }

      return new Promise((resolve) => {
        GeneralService.fetchItems(this.path, data).then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            if (!this.emitOption) {
              this.options = response.data.result
                .filter((item) => {
                  return this.resolve(this.optionLabelKey, item) ? true : false
                })
                .map((item) => {
                  const option = {
                    [this.trackBy]: item[this.trackBy],
                    [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
                  }
                  if (this.extraOption) {
                    option[this.extraOption] = item[this.extraOption]
                  }
                  return option
                })
            } else {
              this.options = response.data.result.map((item) => ({
                [this.trackBy]: item[this.trackBy],
                [this.optionLabelKey]: this.resolve(this.optionLabelKey, item),
                [this.emitOption]: item[this.emitOption],
              }))
            }

            this.$nextTick(() => {
              if (selectFirst) {
                this.selectFirstOption()
              } else if (
                this.selectFirstAfterFetch ||
                (this.selectFirstIfOneResult && this.options.length === 1)
              ) {
                this.selectFirstOption()
              }
            })

            this.loading = false
            resolve(this.options)
          }
        })
      })
    },
    resolve(path, obj) {
      return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    selectFirstOption() {
      if (this.filteredOptions.length === 0) {
        return
      }
      this.selectItem(this.filteredOptions[0])
    },
    hideDropdown() {
      this.showDropdown = false
      this.searchQuery = ''
      this.$refs.dropdownInput?.blur()
    },
    prettifyLabels(label) {
      return this.capitalizeLabel ? this.$prettyLabels(label) : label
    },
  },
}
</script>

<style scoped>
.dropdown .dropdown-menu {
  width: 100%;
  transition: none !important;
  transition-delay: unset !important;
}

.dropdown .dropdown-menu.show {
  transform: scaleY(1);
}

.dropdown input {
  padding: 0 14px;
}

.dropdown .dropdown-menu li {
  text-transform: none;
}

.active-item {
  background-color: #f6f6fb;
}

.dropdown > input.form-control {
  background-image: unset !important;
}

/* .dropdown > input.form-control::placeholder, */
.dropdown > input.form-control:focus {
  color: #999 !important;
}

.multiselect__arrow {
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
  display: flex;
}

.multiselect__arrow::before {
  display: inline-block;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: '';
  transition: transform 0.2s ease;
  margin: auto;
}

.multiselect__arrow.active::before {
  transform: rotate(180deg);
}

.active-item::after,
.active-item-remove:after {
  position: absolute;
  right: 10px;
}

.active-item::after {
  content: 'Press enter to select';
}

.active-item-remove::after {
  content: 'Press enter to remove';
}
.absolute-clear-button {
  position: absolute;
  top: -25px;
  right: 4px;
}
</style>
