<template>
  <div v-if="!isKoalaCampaign" class="d-flex flex-wrap flex-gutter-x-sm align-items-center">
    <div
      class="search-input position-relative"
      style="margin-left: 0 !important"
      @click="searchClick"
      v-if="enableQuickSearch"
    >
      <base-input
        v-model="search"
        v-debounce:300ms="valueChanged"
        :showLabel="false"
        placeholder="Quick search"
        type="text"
        :readOnly="advancedSearch"
        ref="search"
        :defaultInputClass="false"
        :clearInput="advancedSearch"
      />

      <i
        v-if="advancedSearch"
        class="uil uil-multiply position-absolute p-2 mb-1 clickable right-0 bottom-0"
        style="top: \5px"
      />
    </div>

    <div class="switch" v-if="enableAdvancedSearch">
      <input type="checkbox" id="checkboxAdvancedSearch" :checked="advancedSearch" v-model="advancedSearch" />

      <label for="checkboxAdvancedSearch">Advanced search</label>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  components: { BaseInput },
  data() {
    return {
      search: '',
    }
  },
  created() {
    this.emitter.on('foucsOnSearch', () => {
      this.$refs.search.setFocused()
    })

    this.search = this.$store.getters[`${this.stateName}/searchQuery`]
  },
  unmounted() {
    this.emitter.off('foucsOnSearch')
  },
  computed: {
    isKoalaCampaign() {
      return this.$route && this.$route.path === '/koala-campaigns';
    },
    advancedSearch: {
      set(value) {
        this.search = ''
        this.$cookies.set('advanced_search', value)
        this.$store.dispatch(`${this.stateName}/setAdvancedSearch`, value)
      },
      get() {
        return this.$store.getters[`${this.stateName}/advancedSearch`]
      },
    },
  },
  methods: {
    searchClick() {
      if (this.advancedSearch) {
        this.$cookies.set('advanced_search', false)
        this.$store.dispatch(`${this.stateName}/setAdvancedSearch`, false)
        this.$nextTick(() => {
          this.$refs.search.setFocused()
        })
      }
    },
    valueChanged() {
      this.$store.dispatch(`${this.stateName}/setSearchQuery`, this.search)
      this.$store.dispatch(`${this.stateName}/setActivePage`, 1)
    },
  },
  props: {
    enableAdvancedSearch: {
      type: Boolean,
      default: () => true,
    },
    stateName: {
      type: String,
      required: true,
    },
    enableQuickSearch: {
      type: Boolean,
      required: true,
      default: () => true,
    },
  },
}
</script>
