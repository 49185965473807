// import router from "src/router"

export default {
  methods: {
    getEliasEmptyFieldName: function(column) {
      if (column.path) {
        if (column.path.startsWith('koala/')) {
          return `${column.search_key}_isnull`
        }
      }
      if (column.isGroupedMonitoring) {
        return `${column.search_key}_isempty`
      }
      if (['account_manager_name'].filter(w => column.field.includes(w)).length > 0) {
        return `${column.field}_isempty`
      }
      if (['country', 'name'].filter(w => column.field.includes(w)).length > 0) {
        return `${column.field}__isempty`
      }
      if (['link', 'custom_link'].filter(w => column.field.includes(w)).length > 0) {
        return `is_null_or_empty[${column.field}]=`
      }
      return `${column.field}__isnull`
    }
  }
}
