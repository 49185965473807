<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Operators ({{ operators.length }})</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="operators.length > 0">
            <div class="list-group d-flex flex-x3">
              <div class="list-group-item" v-for="operator in operators" v-bind:key="operator.id">
                {{ operator.name }}
              </div>
            </div>
          </div>
          <div class="row" v-if="operators.length == 0">
            <div class="col-md-12">
              <p>Is not used by any operator</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-group-item {
  cursor: inherit;
}
</style>

<script>
export default {
  data() {
    return {
      operators: [],
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.$refs.modal.classList.add('show')
      })
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
  },
}
</script>
