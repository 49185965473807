import Api from './Api'

const path = 'hercules/operators/affiliate-operators'

export default {
  getAll(affiliateId) {
    return Api().get(`${path}`, {
      params: {
        affiliate_id: affiliateId
      }
    })
  },
  get(data) {
    return Api().get(`${path}`, { params: data })
  },
  getOne(id, data) {
    data.id = id
    return Api().get(path, {
      params: data
    })
  },
  post(data) {
    return Api().post(`${path}`, data)
  },
  remove(data) {
    return Api().delete(`${path}/${data.id}`)
  },
  update(data) {
    return Api().put(`${path}/${data.id}`, data)
  },
  bulkUpdate(data) {
    return Api().put(`${path}/bulk`, data)
  },
  clone(data) {
    return Api().post(`${path}/${data.id}/clone`, data)
  },
  fetchLinks(id) {
    return Api().get(`${path}/${id}/links`, {
      params: {
        affiliate_operator_id: id,
        with: ['affiliateOperator.market', 'affiliateOperator.affiliate']
      }
    })
  },
  postLink(affiliateId, data) {
    return Api().post(`${path}/${affiliateId}/links`, data)
  },
  updateLink: function(affiliateId, data) {
    return Api().put(`${path}/${affiliateId}/links/${data.id}`, data)
  },
  fetchLink(affiliateId, linkId) {
    return Api().get(`${path}/${affiliateId}/links/${linkId}`)
  },
  deleteLink(affiliateId, linkId) {
    return Api().delete(`${path}/${affiliateId}/links/${linkId}`)
  },
  // Bonus Types
  fetchTypes(affiliateId, params = null) {
    return Api().get(`${path}/${affiliateId}/types`, { params: params })
  },
  postType(affiliateId, data) {
    return Api().post(`${path}/${affiliateId}/types`, data)
  },
  updateType(id, data) {
    return Api().put(`${path}/${id}/types/${data.id}`, data)
  },
  deleteType(affiliateId, typeId) {
    return Api().delete(`${path}/${affiliateId}/types/${typeId}`)
  },
  fetchType(affiliateId, typeId, params = null) {
    return Api().get(`${path}/${affiliateId}/types/${typeId}`, { params: params })
  },
  // Bonus Type Values
  deleteTypeValue(id, data) {
    return Api().delete(`${path}/${id}/types/${data['affiliate_type_id']}/values/${data.id}`)
  },
  postTypeValue(affiliateId, data) {
    return Api().post(`${path}/${affiliateId}/types/${data['affiliate_type_id']}/values`, data)
  },
  updateTypeValue(id, data) {
    return Api().put(`${path}/${id}/types/${data['affiliate_type_id']}/values/${data.id}`, data)
  },
  getExtraFields(id) {
    return Api().get(`${path}/${id}/extra-fields`)
  },
  updateExtraFields(id, data) {
    return Api().put(`${path}/${id}/extra-fields`, data)
  },
  // Market Links
  getMarketLink(affiliateId, linkId) {
    return Api().get(`${path}/${affiliateId}/market-links/${linkId}`)
  }
}
