<template>
  <span :class="['badge', badgeColor]">{{ status }}</span>
</template>

<script>
export default {
  props: {
    status: {},
    color: {}
  },
  computed: {
    badgeColor() {
      return (
        {
          Cancelled: 'bg-red',
          'Not started': 'bg-black-25',
          postponed: 'bg-orange'
        }[this.status] || 'bg-green'
      )
    }
  }
}
</script>
