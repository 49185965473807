<template>
  <div class="modal in" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between">
          <h5 class="modal-title">Alt Text</h5>
          <button class="close p-0 m-0" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-0">
          <base-input :required="true" label="Alt Text" v-model="attributes.alt" />
        </div>
        <div class="modal-footer">
          <button class="btn btn-info mr-2 ml-2" @click="submit">
            <i class="uil uil-link"></i> Set Alt Text
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  components: {
    BaseInput
  },
  data() {
    return {
      attributes: { label: null, id: null },
      linkAttributes: {},
      position: null
    }
  },
  props: {
    editor: {}
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(attributes = {}) {
      this.attributes = attributes
      if (this.editor.isActive('image')) {
        const linkAttrs = ['href', 'target', 'rel', 'pageId']

        linkAttrs.forEach(key => {
          if (this.editor.getAttributes('link')[key]) {
            this.linkAttributes[key] = this.editor.getAttributes('link')[key]
          }
        })
      }
    },
    submit() {
      if (this.editor.isActive('figure')) {
        this.editor
          .chain()
          .focus()
          .updateFigureAttribute({ alt: this.attributes.alt })
          .run()
        this.close()

        return
      }
      const commandChain = this.editor
        .chain()
        .focus()
        .setImage(this.attributes)
        .selectParentNode()

      if (Object.keys(this.linkAttributes).length > 0) {
        commandChain.setLink(this.linkAttributes)
      }

      commandChain.run()
      this.close()
    },
    close() {
      this.$emit('closeAltText')
    }
  }
}
</script>
