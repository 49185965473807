<template>
  <div>
    <link-management
      v-if="showLink"
      ref="linkManagement"
      :editor="editor"
      :siteId="siteId"
      :marketId="marketId"
      @closeLink="() => (showLink = false)"
      :actionTitlePrefix="editor.isActive('link') ? 'Edit' : 'Add'"
    />
    <terms-and-conditions-link-management
      v-if="showLink && trackingLink"
      ref="termsAndConditionsLinkManagement"
      :editor="editor"
      :siteId="siteId"
      :marketId="marketId"
      @closeLink="() => (showLink = false)"
      :actionTitlePrefix="editor.isActive('link') ? 'Edit' : 'Add'"
    />
    <anchor-modal
      v-if="showAnchor"
      ref="anchorModal"
      :editor="editor"
      @closeAnchor="() => (showAnchor = false)"
    />
    <media-library
      v-if="showMediaLibrary"
      @update:modelValue="imageSelected"
      ref="imageModal"
      :editor="editor"
      @close="showMediaLibrary = false"
    />
    <alt-text-modal
      v-if="showAltText"
      ref="altTextModal"
      :editor="editor"
      @closeAltText="() => (showAltText = false)"
    />
    <title-modal
      v-if="showTitleModal"
      ref="titleModal"
      :editor="editor"
      @closeAltText="() => (showTitleModal = false)"
    />
  </div>
</template>

<script>
import LinkManagement from '@/lib/tiptap/components/link-management-tiptap.vue'
import TermsAndConditionsLinkManagement from '@/lib/tiptap/components/terms-and-conditions-link-management.vue'
import AnchorModal from '@/lib/tiptap/components/anchor-modal.vue'
import MediaLibrary from '@organisms/media-library/assets.vue'
import AltTextModal from '@/lib/tiptap/components/alt-text-modal.vue'
import TitleModal from '@/lib/tiptap/components/title-modal.vue'

export default {
  components: {
    LinkManagement,
    AnchorModal,
    MediaLibrary,
    AltTextModal,
    TitleModal,
    TermsAndConditionsLinkManagement
  },
  data() {
    return {
      showTitleModal: false,
      showAltText: false,
      showLink: false,
      showAnchor: false,
      showMediaLibrary: false,
      uid: this.generateUID(),
      actionTitlePrefix: 'Add',
    }
  },
  props: {
    trackingLink: false,
    editor: {},
    required: true,
    siteId: {},
    marketId: {},
  },
  computed: {
    assetsSelected() {
      return this.$store.state.media.selectedAsset
    },
    selectedText() {
      return this.$store.state.tiptap.selectedText
    },
  },
  methods: {
    checkSelectedText() {
      if (!this.selectedText.length) {
        const { view, state } = this.editor
        const { from, to } = view.state.selection
        const text = state.doc.textBetween(from, to, '')
        this.$store.commit('tiptap/setSelectedText', text)
      }
    },
    setTermsAndConditionsLink() {
      this.$store.commit('tiptap/setClearLinkModal')
      this.showLink = true
      this.checkSelectedText()
      this.$nextTick(() => {
        let activeType = this.editor.isActive('link')
        let attrs = {}

        // Image inline now can be linked, check if editor is active image and link
        if (this.editor.isActive('link')) {
          attrs = this.editor.getAttributes('link')
        } else {
          attrs = activeType ? this.editor.getAttributes(activeType) : {}
        }
        if (activeType === 'link') {
          const txt = this.editor.commands.findTextInLink()
          this.$store.commit('tiptap/setSelectedText', txt)
        }

        let splitRel = attrs.rel?.split(' ')

        this.$store.commit(
          'tiptap/setLinkNewTab',
          splitRel && splitRel.includes('noopener') && splitRel.includes('noreferrer')
        )

        this.$store.commit('tiptap/setLinkNoFollow', attrs.rel?.length && attrs.rel.includes('nofollow'))

        this.$refs.termsAndConditionsLinkManagement.show(attrs)
      })
    },
    setLink() {
      if (this.trackingLink) {
        this.setTermsAndConditionsLink()
        return
      }
      this.$store.commit('tiptap/setClearLinkModal')
      this.showLink = true
      this.checkSelectedText()
      this.$nextTick(() => {
        let elementTypes = ['figure', 'image', 'link']
        let activeType = elementTypes.find((type) => this.editor.isActive(type))
        let attrs = {}

        // Image inline now can be linked, check if editor is active image and link
        if (activeType === 'image' && this.editor.isActive('link')) {
          attrs = this.editor.getAttributes('link')
        } else {
          attrs = activeType ? this.editor.getAttributes(activeType) : {}
        }
        if (activeType === 'link') {
          const txt = this.editor.commands.findTextInLink()
          this.$store.commit('tiptap/setSelectedText', txt)
        }

        const linkManagementModal = this.trackingLink
          ? this.$refs.termsAndConditionsLinkManagement
          : this.$refs.linkManagement

        linkManagementModal.show(attrs)

        const { href, target, pageId, rel } = attrs
        if (
          this.editor.isActive('link') ||
          this.editor.getAttributes('figure').href ||
          this.editor.getAttributes('image').href
        ) {
          // if there's link, href or image check the pageId and set the linkInternal
          this.$store.commit('tiptap/setLinkInternal', !!pageId)
        } else {
          // otherwise set the linkInternal to true (default)
          this.$store.commit('tiptap/setLinkInternal', true)
        }

        this.$store.commit('tiptap/setLinkPage', pageId)
        this.$store.commit('tiptap/setLinkNewTab', !pageId && !!target)
        this.$store.commit('tiptap/setLinkNoFollow', rel?.length && rel.includes('nofollow'))
        if (href) {
          this.$store.commit('tiptap/setLinkExternalURL', !pageId ? href : '')
        }
      })
    },
    setAnchor() {
      this.showAnchor = true
      this.$nextTick(() => {
        this.$refs.anchorModal.show()
      })
    },
    setAltText() {
      this.showAltText = true
      this.$nextTick(() => {
        if (this.editor.isActive('figure')) {
          this.$refs.altTextModal.show(this.editor.getAttributes('figure'))
        } else {
          this.$refs.altTextModal.show(this.editor.getAttributes('image'))
        }
      })
    },
    setTitle() {
      this.showTitleModal = true
      this.$nextTick(() => {
        if (this.editor.isActive('figure')) {
          this.$refs.titleModal.show(this.editor.getAttributes('figure'))
        } else {
          this.$refs.titleModal.show(this.editor.getAttributes('image'))
        }
      })
    },
    setImage(image) {
      this.showMediaLibrary = true
      this.$nextTick(() => {
        this.$refs.imageModal.show(image?.id)
      })
    },
    imageSelected(image) {
      const { dir } = this.editor.getAttributes(image)
      let attrObj = {
        alt: image.alt,
        title: image.title,
        src: image.url,
        id: image.id,
        resizedHeight: image.height,
        resizedWidth: image.width,
        dir,
      }

      let linkAttr = {}

      const linkAttrs = ['href', 'target', 'rel', 'pageId']

      linkAttrs.forEach((key) => {
        if (this.editor.getAttributes('link')[key]) {
          linkAttr[key] = this.editor.getAttributes('link')[key]
        }
      })

      if (this.editor.isActive('figure')) {
        this.editor
          .chain()
          .focus()
          .updateFigureAttribute({
            src: image.url,
          })
          .run()
        return
      }

      const commandChain = this.editor.chain().focus().setImage(attrObj).selectParentNode()

      if (Object.keys(linkAttr).length > 0) {
        commandChain.setLink(linkAttr)
      }

      commandChain.run()
    },
  },
}
</script>
