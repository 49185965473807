<template>
    <data-table
      model="ResponsibleGaming"
      service-file="ResponsibleGamingService"
      :path="path()"
      :columns="columns()"
      :create-defaults="createDefaults()"
      ref="list"
    />
  </template>
  
  <script>
  import TableColumnTemplate from '@constants/table-column-template'
  
  export default {
    created() {
      this.setTitle()
    },
    methods: {
      path() {
        return `hercules/operators/responsible-gamings`
      },
      columns() {
        return [
          { field: 'name', type: 'text', filterable: true },
          { field: 'short_name', type: 'text', filterable: true },
          { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
        ]
      },
      createDefaults() {
        return {
         site_id: parseInt(this.$route.params['id'])
        }
      }
    }
  }
  </script>