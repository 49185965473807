<template>
  <i class="gig gig-indicator-l" :style="{color: value}" />
</template>

<script>
export default {
  props: {
    value: {},
  },
}
</script>

