<template>
  <div v-if="user">
    <div class="row">
      <div class="col-lg-5 d-flex">
        <div class="card flex-grow-1">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="d-flex flex-wrap">
                  <div class="mr-4">
                    <span class="avatar avatar-lg bg-red text-white">{{ user.name.substring(0, 1) }}</span>
                  </div>
                  <div>
                    <h1>
                      {{ user.name }}
                    </h1>
                    <div class="d-flex flex-column text-muted font-weight-medium code mw-100">
                      <span class="d-flex align-items-center">
                        <i class="uil uil-envelope mr-1"></i>
                        <span class="text-truncate mr-1">
                          {{ user.email }}
                        </span>
                      </span>
                      <span class="text-truncate">
                        <i class="uil uil-calendar-alt"></i> Joined {{ $dateRelative(user.created_at) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col d-flex">
        <div class="card flex-grow-1">
          <div class="card-header">
            <label class="alt"> Email notifications </label>
          </div>
          <div class="card-body mb-n3">
            <div class="font-weight-medium animate-children">
              <div class="d-flex justify-content-between align-items-baseline mb-2 flex-wrap">
                <base-checkbox
                  :switch="true"
                  label="Tracking link changes"
                  :modelValue="user.notify_change_affiliate_link"
                  @update:modelValue="affiliateLinkChange"
                />
              </div>
              <div class="d-flex justify-content-between align-items-baseline mb-2 flex-wrap">
                <base-checkbox
                  :switch="true"
                  label="New operator created"
                  :modelValue="user.notify_new_operator"
                  @update:modelValue="newOperator"
                />
              </div>
              <div class="d-flex justify-content-between align-items-baseline mb-2 flex-wrap">
                <base-checkbox
                  :switch="true"
                  label="Forgotten toplists"
                  :modelValue="user.notify_forgotten_toplists"
                  @update:modelValue="forgottenToplists"
                />
              </div>
              <div class="d-flex justify-content-between align-items-baseline mb-2 flex-wrap">
                <base-checkbox
                  :key="notifyFailedTrackingLinkCheckBox"
                  :switch="true"
                  label="Failed tracking links"
                  :modelValue="user.notify_failed_tracking_links"
                  @update:modelValue="failedTrackingLinks"
                  :read-only="user.roles.includes('data')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Roles</h3>
      </div>
      <div class="card-body" data-view="tags-start">
        <div class="line-height-lg">
          <span
            class="badge mr-1"
            :class="[role === 'admin' && 'bg-orange']"
            v-for="(role, index) in user.roles"
            :key="index"
            >{{ role }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Allowed sites</h3>
          </div>
          <div class="card-body" data-view="tags-start">
            <div class="line-height-lg" v-if="sites.length > 0">
              <span class="badge mr-1" v-for="(site, index) in sites" :key="index">{{ site.website }}</span>
            </div>
            <div v-else>You have access to all sites <i class="uil uil-smile"></i></div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Allowed markets</h3>
          </div>
          <div class="card-body" data-view="tags-start">
            <div class="line-height-lg" v-if="markets.length > 0">
              <span class="badge mr-1" v-for="(market, index) in markets" :key="index">{{
                market.label
              }}</span>
            </div>
            <div v-else>You have access to all markets <i class="uil uil-smile"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralService from '@services/GeneralService.js'
import UsersService from '@services/UsersService.js'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
export default {
  components: { BaseCheckbox },
  data() {
    return {
      user: null,
      sites: [],
      markets: [],
      notifyFailedTrackingLinkCheckBox: 0,
    }
  },
  created() {
    // refresh user data, in case roles or permissions were changed
    this.$auth.fetch().then(() => {
      this.$auth.user()
      this.user = this.$auth.user()
      this.getSites()
      this.getMarkets()
    })
  },
  methods: {
    getSites() {
      if (this.user.allowed_sites === null) {
        return
      }
      GeneralService.fetchItems('hercules/globals/sites', { ids: this.user.allowed_sites })
        .then((response) => {
          this.sites = response.data.result
        })
        .catch(this.showUnknownErrorMessage)
    },
    getMarkets() {
      if (this.user.allowed_markets === null) {
        return
      }
      GeneralService.fetchItems('hercules/globals/markets', { ids: this.user.allowed_markets })
        .then((response) => {
          this.markets = response.data.result
        })
        .catch(this.showUnknownErrorMessage)
    },
    affiliateLinkChange(value) {
      UsersService.update({
        id: this.user.id,
        notify_change_affiliate_link: value,
      })
        .then((response) => {
          if (response.data.status === 1) {
            this.user.notify_change_affiliate_link = response.data.result.notify_change_affiliate_link
            this.showSuccessMessage('Setting has been updated')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    newOperator(value) {
      UsersService.update({
        id: this.user.id,
        notify_new_operator: value,
      })
        .then((response) => {
          if (response.data.status === 1) {
            this.user.notify_new_operator = response.data.result.notify_new_operator
            this.showSuccessMessage('Setting has been updated')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    forgottenToplists(value) {
      UsersService.update({
        id: this.user.id,
        notify_forgotten_toplists: value,
      })
        .then((response) => {
          if (response.data.status === 1) {
            this.user.notify_forgotten_toplists = response.data.result.notify_forgotten_toplists
            this.showSuccessMessage('Setting has been updated')
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    failedTrackingLinks(value) {
      UsersService.update({
        id: this.user.id,
        notify_failed_tracking_links: value,
      })
        .then((response) => {
          if (response.data.status === 1) {
            this.user.notify_failed_tracking_links = response.data.result.notify_failed_tracking_links
            this.showSuccessMessage('Setting has been updated')
          } else {
            this.showErrorMessage(response.data.messages[0])
            this.notifyFailedTrackingLinkCheckBox += 1
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
  },
}
</script>
