import { store } from '@/store/index'
import { uniqueId } from 'lodash'
const duration = 3000

function notify(notification) {
  notification.id = uniqueId('notification')
  store.dispatch('app/addNotification', notification)
  if (notification.duration !== -1) {
    setTimeout(function () {
      store.dispatch('app/removeNotificationById', notification.id)
    }, notification.duration)
  }
}

export default {
  showSuccessMessage: function (message, title = 'Success', sticky = false, customDuration = duration) {
    notify({
      title: title,
      text: message,
      type: 'success',
      duration: sticky ? -1 : customDuration,
    })
  },
  showSuccessMessages: function (messages, title = 'Success', sticky = false) {
    if (typeof messages == 'string') {
      messages = [messages]
    }
    messages.forEach((message) => {
      notify({
        title: title,
        text: message,
        type: 'success',
        duration: sticky ? -1 : duration,
      })
    })
  },
  showErrorMessage: function (message, title = 'Error', sticky = false) {
    notify({
      title: title,
      text: message,
      type: 'warning',
      duration: sticky ? -1 : duration,
    })
  },
  showErrorMessages: function (messages, title = 'Error', sticky = false) {
    if (typeof messages == 'string') {
      messages = [messages]
    }
    messages.forEach((message) => {
      notify({
        title: title,
        text: message,
        type: 'warning',
        duration: sticky ? -1 : duration,
      })
    })
  },
  showUnknownErrorMessage: function (error = {}, sticky = false) {
    if (error.message === 'canceled') {
      return
    }
    /* eslint-disable no-console */

    /* eslint-enable no-alert */
    notify({
      title: 'Error',
      text: 'Something went wrong ;-(',
      type: 'error',
      duration: sticky ? -1 : duration,
    })
    // throw new Error(error)
  },
}
