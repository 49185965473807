<template>
  <span class="badge bg-green" data-tooltip="Active" v-if="$attrs.page.status === 'active'">{{
    minimal ? 'active' : 'active'
  }}</span>
  <span class="badge bg-red-75" data-tooltip="Inactive" v-else-if="$attrs.page.status === 'inactive'">{{
    minimal ? 'inactive' : 'inactive'
  }}</span>
  <span class="badge bg-white-25" data-tooltip="Gone" v-else-if="$attrs.page.status === 'gone'">{{
    minimal ? 'gone' : 'gone'
  }}</span>
  <span
    class="badge bg-info clickable"
    v-else-if="$attrs.page.status === 'scheduled'"
    :data-tooltip="$dateTime($attrs.page.scheduled_at)"
    >{{ minimal ? 'sched.' : 'scheduled' }}</span
  >
  <span class="badge bg-black-25" v-else>{{ $attrs.page.status }}</span>
</template>

<script>
export default {
  props: {
    minimal: {
      default: () => false,
    },
  },
}
</script>
