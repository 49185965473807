<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Details</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="object.action !== 'create'">
            <div class="col">
              <div>
                <label>Old</label>
              </div>
              <code>{{ pretty(object.old_data) }}</code>
            </div>
            <div class="col" v-if="object.action !== 'delete'">
              <div>
                <label>Updated</label>
              </div>
              <code>{{ pretty(object.new_data) }}</code>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col">
              <div>
                <label>Data</label>
              </div>
              <code>{{ pretty(object.new_data) }}</code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-group-item {
  cursor: inherit;
}
</style>

<script>
export default {
  data() {
    return {
      object: {},
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.$refs.modal.classList.add('show')
      })
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
    pretty(value) {
      return value ? JSON.stringify(JSON.parse(value), null, 2) : ''
    },
  },
}
</script>
