<template>
  <ul class="entity-tree">
    <li class="expanded" v-for="(moduleField, key) in moduleFields" v-bind:key="key">
      <span class="border-bottom">
        {{ moduleField.field.name }}
        <strong class="badge bg-black-25 ml-2">{{ moduleField.field.type }}</strong>
      </span>
      <module-list v-if="moduleField.children.length > 0" :module-fields="moduleField.children" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'module-list',
  props: {
    moduleFields: {
      required: true
    }
  }
}
</script>
