export default Object.freeze({
  OWNER_TYPE_PAGE: 'page',
  OWNER_TYPE_MARKET: 'market',
  OWNER_TYPE_PREFILLED_MODULE: 'prefilled_module',
  OWNER_TYPE_PREFILLED_MODULE_MARKET: 'prefilled_module_market',
  OWNER_TYPE_TEMPLATE: 'template',
  VALUE_TYPE_PREFILLED_MODULE_MARKET: 'prefilled_module_market',
  VALUE_TYPE_PREFILLED_MODULE: 'prefilled_module',
  VALUE_TYPE_TEMPLATE_BLOCK: 'template_block'
})
