import Api from '../Api'

const buildQueryString = filters => {
  return Object.keys(filters)
    .map(key => key + '=' + filters[key])
    .join('&')
}

export default {
  getOne(id, data) {
    return Api().get(`koala/v1/operators/${id}`, {
      params: data
    })
  },
  get() {
    return Api().get(`koala/v1/operators`)
  },
  post(data) {
    data.schedule_args = {}
    return Api().post(`koala/v1/operators`, data)
  },
  remove(data) {
    return Api().delete(`koala/v1/operators/${data.id}`, data)
  },
  update(data) {
    const { name, base_url, schedule_cron, schedule_active, schedule_args } = data
    return Api().put(`koala/v1/operators/${data.id}`, {
      name,
      base_url,
      schedule_cron,
      schedule_active,
      schedule_args
    })
  },
  bulkByIds(ids, changes, extraData) {
    this.prepareTheDataForBulkEdit(changes, extraData)
    changes.schedule_args = {}
    if (changes.keywords === null) {
      changes.keywords = []
    }
    return Api().patch(`koala/v1/operators?${buildQueryString({ ids: ids })}`, changes)
  },
  bulkByFilters(filters, changes, extraData) {
    this.prepareTheDataForBulkEdit(changes, extraData)
    if (changes.keywords === null) {
      changes.keywords = []
    }
    return Api().patch(`koala/v1/operators?${buildQueryString(filters)}`, changes)
  },
  prepareTheDataForBulkEdit(changes) {
    if (changes.schedule_active === null) {
      changes.schedule_active = false
    }
  }
}
