<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="items.length > 0">
            <div class="col">
              <ul class="list-group">
                <li v-for="item in items" v-bind:key="item.id" class="list-group-item">
                  <a target="blank" :href="item[label]" v-if="itemIsLink">{{ item[label] }}</a>
                  <span v-else>{{ item[label] }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="row" v-if="items.length == 0">
            <div class="col-md-12">
              <p>{{ emptyMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-group-item {
  cursor: inherit;
}
</style>

<script>
export default {
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  props: {
    label: {
      type: String,
      default: () => 'name',
    },
    title: {
      type: String,
      default: () => 'Title',
    },
    emptyMessage: {
      type: String,
      default: () => 'No items found',
    },
    itemIsLink: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    show() {
      this.$nextTick(() => {
        this.$refs.modal.classList.add('show')
      })
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
  },
}
</script>
