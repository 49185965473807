import Api from './Api'

export default {
  getOne(id) {
    return Api().get(`hercules/globals/currencies/${id}`)
  },
  post(data) {
    return Api().post(`hercules/globals/currencies`, data)
  },
  update(data) {
    return Api().put(`hercules/globals/currencies/${data.id}`, data)
  },
  remove(data) {
    return Api().delete(`hercules/globals/currencies/${data.id}`)
  },
  fetchStructure() {
    return Api().get(`hercules/globals/currencies/structure`)
  }
}
