<template>
  <div class="list-group" v-if="category.length">
    <h4 class="sidebar-title" v-if="title">{{ title }}</h4>
    <div v-for="(child, index) in category" :key="index" class="mb-1">
      <router-link
        v-if="allowedToShow(child) && !child.subsection"
        tag="a"
        :to="{ path: child.to }"
        v-bind:class="{ 'text-blue-75': bestMatch && bestMatch.to === child.to }"
        class="d-flex justify-content-between list-group-item"
        style="height: 40px"
      >
        <span class="title">{{ child.name }}</span>
        <span @click.prevent="favoriteItem(child)" class="custom-star-icon">
          <div v-if="favorites.some((n) => n.to === child.to)">&#9733;</div>
          <div class="star-icon" v-else>&#9734;</div>
        </span>
      </router-link>
    </div>
  </div>
  <div class="list-group" v-else>
    <h4 class="sidebar-title" v-if="title">{{ title }}</h4>
    <router-link
      v-if="!category.subsection"
      tag="a"
      :to="{ path: category.to }"
      v-bind:class="{ 'text-blue-75': isActiveRoute(category.to), active: isActiveRoute(category.to) }"
      class="d-flex justify-content-between list-group-item"
      style="height: 40px"
    >
      <span class="title">{{ category.name }}</span>
      <span @click.prevent="favoriteItem(category)" class="custom-star-icon">
        <div v-if="favorites.some((n) => n.to === category.to)">&#9733;</div>
        <div class="star-icon" v-else>&#9734;</div>
      </span>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: Object || Array,
    },
    favorites: {
      type: Array,
    },
    title: {
      type: String,
    },
  },
  computed: {
    bestMatch() {
      return this.$store.getters['sidebar/bestMatch']
    },
  },
  methods: {
    isActiveRoute(basePath) {
      return this.$route.path.startsWith(basePath)
    },
    allowedToShow(child) {
      if (this.hasPermission(child.permission)) {
        return true
      }
    },
    favoriteItem(value) {
      this.$store.commit('sidebar/setFavoriteItem', value)
    },
  },
}
</script>
