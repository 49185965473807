import Paragraph from '@tiptap/extension-paragraph'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { styleAttribute } from './tiptapHelpers.js'

const CustomParagraph = Paragraph.extend({
  addProseMirrorPlugins() {
    const self = this
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleKeyDown(view, event) {
            const { state } = view
            const currentNode = state.selection.$from.node()

            const hasRequiredAttributes =
              (currentNode.attrs['id'] && currentNode.attrs['data-anchor-label']) ||
              currentNode.attrs['class'] ||
              currentNode.attrs['style']

            if (
              event.key === 'Enter' &&
              !event.shiftKey &&
              (self.editor.isActive('paragraph') || self.editor.isActive('heading')) &&
              hasRequiredAttributes
            ) {
              const type = state.schema.nodes['paragraph']

              const insertPos = state.selection.$to.pos

              self.editor
                .chain()
                .insertContent({ type: type.name })
                .focus()
                .setTextSelection(insertPos + 1)
                .run()

              event.preventDefault()
              return true
            }
            return false
          }
        }
      })
    ]
  },
  addAttributes() {
    return {
      ...this.parent?.(),
      style: styleAttribute(),
      'data-anchor-label': {
        default: null,
        parseHTML: element => {
          const isEmpty = element.outerText && element.outerText.length === 0
          if (isEmpty) return null
          return element.getAttribute('data-anchor-label')
        },
        renderHTML: attributes => {
          const attr = {}
          if (attributes['data-anchor-label']) {
            attr['data-anchor-label'] = attributes['data-anchor-label']
          }
          return attr
        }
      },
      id: {
        default: null,
        parseHTML: element => {
          const isEmpty = element.outerText && element.outerText.length === 0
          if (isEmpty) return null
          return element.getAttribute('id')
        },
        renderHTML: attributes => {
          const attr = {}
          if (attributes.id) {
            attr.id = attributes.id
          }
          return attr
        }
      }
    }
  }
})

export default CustomParagraph
