import Api from '../Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/operators/affiliate-platforms/${id}`, {
      params: data
    })
  },
  get(data) {
    return Api().get(`hercules/operators/affiliate-platforms`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/operators/affiliate-platforms`, data)
  },
  update(data) {
    return Api().put(`hercules/operators/affiliate-platforms/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/operators/affiliate-platforms/${row.id}`)
  }
}
