<template>
  <div>
    <div v-for="(field, key) in values" :key="key" class="d-flex mb-3">
      <base-input v-model="values[key]" :defaultInputClass="false" :showLabel="false" />
      <a v-if="key === 0" class="btn-sm btn-primary align-self-center ml-4" @click="add"><i class="uil uil-plus"></i></a>
      <a v-else class="btn-sm btn-danger align-self-center ml-4" @click="remove(key)"><i class="uil uil-trash"></i></a>
    </div>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
export default {
  components: { BaseInput },
  props: {
    value: {
      type: Array
    },
    field: {
      type: Object
    },
    helpText: {
      type: String
    }
  },
  data() {
    return {
      values: this.value
    }
  },
  watch: {
    values: function() {
      this.$emit('sync', this.field.name, JSON.stringify(this.values))
    }
  },
  methods: {
    add() {
      this.values.push('')
    },
    remove(index) {
      this.values.splice(index, 1)
      this.$emit('sync', this.field.name, JSON.stringify(this.values))
    }
  }
}
</script>
