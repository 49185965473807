import Api from './Api'

export default {
  getAll() {
    return Api().get('/backoffice/roles')
  },
  searchRoles(search) {
    return Api().get('/backoffice/roles', { params: { search: search } })
  },
  getOne(id) {
    return Api().get('/backoffice/roles', { params: { id: id } })
  },
  update(data) {
    return Api().put('/backoffice/roles', data)
  },
  post(data) {
    return Api().post('/backoffice/roles', data)
  },
  getPermissions(id) {
    return Api().get(`backoffice/roles/${id}/permissions`)
  },
  remove(row) {
    return Api().delete(`backoffice/roles/${row.id}`)
  }
}
