import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sports/teams/${id}`, {
      params: data
    })
  },
  get(data) {
    return Api().get(`hercules/sports/teams`, {
      params: data
    })
  },
  update(data) {
    return Api().put(`hercules/sports/teams/${data.id}`, data)
  }
}
