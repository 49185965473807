<template>
  <span :class="`badge ${badgeColor}`">{{ status }}</span>
</template>

<script>
export default {
  props: {
    status: {}
  },
  computed: {
    badgeColor() {
      switch (this.status) {
        case '1x2':
          return 'bg-blue'
        case '12':
          return 'bg-azure'
        case 'Over/Under':
          return 'bg-purple'
      }
      return 'bg-white-25'
    }
  }
}
</script>
