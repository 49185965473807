<template>
  <div class="card">
    <div class="card-body">
      <div class="text-center text-muted">
        <i class="gig stats-icon" :class="icon"></i>
      </div>
      <div class="text-center mt-3 stats-value text-primary">
        <animated-number :value="value" :formatValue="formatToPrice" :duration="500" />
      </div>
    </div>
    <div class="card-footer text-center font-weight-bold">
      {{ title }}
      <span class="popover-wrapper" v-if="tooltip" data-popover-position="bottom">
        <span class="popover-trigger text-info ml-2">
          <i class="uil uil-info-circle"></i>
        </span>
        <span class="popover-content">
          <ul class="user-list pl-0 mb-0">
            <li v-for="(user, index) in tooltip" :key="index">{{ user }}</li>
          </ul>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from '@/components/atomic/atoms/misc/animated-number.vue'

export default {
  components: { AnimatedNumber },
  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: Number,
    },
    tooltip: {
      type: [String, Array],
      default: null,
    },
  },
  methods: {
    formatToPrice(value) {
      return value.toFixed(0)
    },
  },
}
</script>

<style>
.stats-icon {
  font-size: 22px;
}
.stats-value {
  font-size: 40px;
  font-weight: bold;
}
ul.user-list {
  list-style-type: none;
  text-align: left;
}

.popover-wrapper .popover-content {
  left: -20px;
}
</style>
