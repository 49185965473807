import Tiptap from '@pages/testing/tip-tap.vue'
import DataTable from '@pages/testing/data-table.vue'
import OperatorData from '@pages/testing/operators.vue'
import OperatorHome from '@pages/dms/operators/edit/form.vue'

export default [
  {
    path: '/testing/tiptap',
    component: Tiptap,
    meta: {
      name: 'Tiptap'
    }
  },
  {
    path: '/testing/data-table',
    component: DataTable,
    meta: {
      name: 'Data Table'
    }
  },
  {
    path: '/testing/operators',
    component: OperatorData,
    meta: {
      name: 'Operators Data'
    }
  },
  {
    path: '/testing/operators/:id',
    component: OperatorHome,
    meta: {
      parent: 'Data Management',
      auth: true,
      permission: 'view.operators/operators',
      name: 'General'
    }
  }
]
