<template>
  <div class="row">
    <div class="col">
      <admin-maintenance />
    </div>
    <div class="col">
      <admin-notification />
    </div>
  </div>
</template>

<script>
import AdminMaintenance from '@molecules/admin/maintenance-edit.vue'
import AdminNotification from '@molecules/admin/notification-edit.vue'

export default {
  components: { AdminMaintenance, AdminNotification },
  created() {
    this.setTitle()
  }
}
</script>
