import Api from './Api'

export default {
  get() {
    return Api().get('auth/user')
  },
  logout() {
    return Api().post('backoffice/auth/logout')
  },
  socialiteLoginAzure() {
    return Api().get('backoffice/auth/socialite-login/azure')
  },
  forgotPassword(email) {
    return Api().post('backoffice/auth/forgot-password', {
      email: email,
      origin: new URL(window.location.href).origin
    })
  },
  resetPassword(token) {
    return Api().post('backoffice/auth/reset-password', {
      token: token
    })
  }
}
