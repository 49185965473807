import Api from '../Api'

export default {
  search(type, data) {
    return Api().post(`gigfarm/v1/s3/search/${type}`, data)
  },
  fetch(data) {
    return Api().post(`gigfarm/v1/s3/fetch`, data)
  }
}
  