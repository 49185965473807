import TableHeader from '@tiptap/extension-table-header'
import Component from './CustomTableHeaderComponent.vue'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import { styleAttribute } from './tiptapHelpers'
export default TableHeader.extend({
  content: '(text | image | hardBreak )*',

  addAttributes() {
    return {
      ...this.parent?.(),
      dir: {
        default: null,
        parseHTML: (element) => {
          return element.getAttribute('dir')
        },
        renderHTML: (attributes) => {
          if (attributes.dir === null || attributes.dir === 'ltr') {
            return {}
          }
          return { dir: attributes.dir }
        },
      },
      style: styleAttribute(['color', 'background-color', 'text-align', 'vertical-align']),
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(Component)
  },
  addCommands() {
    return {
      toggleHeaderRowCustom:
        () =>
        ({ editor }) => {
          editor
            .chain()
            .command(({ tr, chain }) => {
              if (editor.isActive('table')) {
                const { anchor } = tr.selection
                let activeTable
                tr.doc.descendants((node, pos) => {
                  if (node.type.name === 'table' && anchor >= pos && anchor <= pos + node.nodeSize) {
                    activeTable = pos
                  }
                })
                chain()
                  .focus(activeTable + 2)
                  .toggleHeaderRow()
                return true
              }
            })
            .run()
        },
    }
  },
})
