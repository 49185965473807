export function styleAttribute(allowedProperties = ['color', 'background-color']) {
  return {
    parseHTML: element => {
      return element.getAttribute('style')
    },
    renderHTML: attributes => {
      if (!attributes.style || !removeDuplicateStyles(attributes.style, allowedProperties).length) {
        return {}
      }
      return {
        style: removeDuplicateStyles(attributes.style, allowedProperties)
      }
    }
  }
}

export function removeDuplicateStyles(styleStr, allowedProperties) {
  if (styleStr === '' || !styleStr) {
    return ''
  }

  // Convert the allowed properties to a Set for efficient lookup
  const allowedPropertiesSet = new Set(allowedProperties)

  // Split the string into individual "property:value" pairs
  const declarations = styleStr.split(';')

  // Use a Map to preserve the order and ensure uniqueness
  const stylesMap = new Map()

  declarations.forEach(decl => {
    // Trim whitespace and ignore empty strings
    const trimmedDecl = decl.trim()
    if (trimmedDecl) {
      const [property, value] = trimmedDecl.split(':').map(s => s.trim())
      // If the property is allowed, store it in the Map, overwriting any previous value
      if (allowedPropertiesSet.has(property)) {
        stylesMap.set(property, value)
      }
    }
  })

  // Reassemble the unique declarations into a string
  const uniqueStyleString = Array.from(stylesMap)
    .map(([property, value]) => `${property}: ${value}`)
    .join('; ')

  return uniqueStyleString
}
