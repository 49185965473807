<template>
  <div class="collaborative-container">
    <p>
      <i class="uil uil-exclamation-triangle collaborative-icon"></i>
      <span class="collaborative-title">
        <b>There is a new version of this page</b>
      </span>
    </p>
    <p class="collaborative-text">
      A new version of this page was created by the user {{ username }}. To view or edit, refresh your browser
      or open the page in the new tab. Your current changes might be lost.
      <router-link :to="{ path: route_path }" @click="clickHandler"> View page in new tab </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'colaborative-edit-notification',
  methods: {
    clickHandler() {
      let route = this.$router.resolve({ path: this.$route.fullPath })
      window.open(route.href, '_blank')
    },
  },
  mounted() {},
  props: {
    route_path: {
      type: String,
      required: true,
      default: '/',
    },
    username: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>

<style scoped>
.collaborative-container {
  max-width: 80%;
  margin-left: 10%;
  background-color: #fff2e3;
  padding: 10px;
  border: 1px solid tomato;
  border-radius: 5px;
}
.collaborative-icon {
  margin-right: 10px;
  color: red;
}
.collaborative-title {
  font-size: 15px;
  vertical-align: text-bottom;
}
.collaborative-text {
  font-size: 13px;
}
</style>
