import moment from 'moment'
export default {
  capitalize: (value) => {
    if (!value) return value
    return value.replace(/^\w/, (c) => c.toUpperCase())
  },
  date: (value, format = 'DD-MM-YYYY') => {
    return moment(value).format(format)
  },
  dateTime: (value, format = 'DD-MM-YYYY HH:mm') => {
    return moment(value).format(format)
  },
  dateRelative: (value) => {
    return moment(value).fromNow()
  },
  prettyLabels: (value) => {
    if (typeof value !== 'string' || value == '') return value
    let fixed = value.replace(/_|-/g, ' ')
    return fixed[0].toUpperCase() + fixed.slice(1)
  },
  log: (value) => {
    // eslint-disable-next-line
    console.log(value)
  },
}
