<template>
  <div class="notifications-container">
    <div
      v-for="(notification, index) in $store.state.app.notifications"
      :key="index"
      :class="getClass(notification)"
    >
      <button class="close" @click="$store.dispatch('app/removeNotification', index)"></button>
      {{ notification.text }}
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getClass(notification) {
      return `alert alert-${notification.type}`
    }
  }
}
</script>
