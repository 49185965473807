<template>
  <node-view-wrapper
    as="figure"
    ref="figure"
    :class="[selected && 'border', node.attrs.style]"
    :dir="node.attrs.dir == 'ltr' || node.attrs.dir == null ? '' : 'rtl'"
    :style="
      node.attrs.dir === 'rtl'
        ? {
            width: node.attrs.resizedWidth + 'px',
            'margin-left': 'calc(100% - ' + node.attrs.resizedWidth + 'px)',
            display: 'block',
          }
        : node.attrs.dir === 'ltr' && node.attrs.style !== 'content-image--float-right'
        ? {
            width: node.attrs.resizedWidth + 'px',
            'margin-right': 'calc(100% - ' + node.attrs.resizedWidth + 'px)',
            display: 'block',
          }
        : node.attrs.dir === 'auto'
        ? {
            width: node.attrs.resizedWidth + 'px',
            'margin-right': 'auto',
            'margin-left': 'auto',
            display: 'block',
          }
        : {}
    "
  >
    <a v-bind="anchorAttributes" v-if="anchorAttributes.href">
      <img v-bind="attributes" @click.stop.prevent="clickedOnFigure" />
    </a>
    <img v-bind="attributes" @click="clickedOnFigure" v-else />
    <node-view-content as="figcaption" ref="figcaption" class="content" :contenteditable="true" />
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import interact from 'interactjs'

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  data() {
    return {
      test: false,
    }
  },
  props: nodeViewProps,
  mounted() {
    interact(this.$refs.figure.$el).resizable({
      edges: { top: true, left: true, bottom: true, right: true },
      preserveAspectRatio: true,
      modifiers: [
        interact.modifiers.aspectRatio({
          ratio: 'preserve',
        }),
      ],
      listeners: {
        move: (event) => {
          this.updateAttributes({
            resizedWidth: Math.round(event.rect.width),
            resizedHeight: Math.round(event.rect.height),
          })
        },
      },
    })
  },
  computed: {
    attributes() {
      return {
        src: this.node.attrs.src,
        width: this.node?.attrs?.resizedWidth,
        height: 'auto',
        'data-height': this.node.attrs.originalHeight,
        'data-width': this.node.attrs.originalWidth,
        title: this.node.attrs.title,
        alt: this.node.attrs.alt,
      }
    },
    anchorAttributes() {
      return {
        href: this.node.attrs.href,
        target: this.node.attrs.target,
        rel: this.node.attrs.rel,
        'data-page-id': this.node.attrs.pageId,
      }
    },
  },
  methods: {
    clickedOnFigure() {
      this.editor.commands.setNodeSelection(this.getPos())
    },
  },
}
</script>

<style lang="scss">
.removeCaption {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: #00000050;
  &:hover {
    color: #000;
  }
}
img {
  max-width: calc(100% - 10px);
}
figure {
  border: 1px solid #efefef;
  display: inline-block;
  padding: 8px;
  touch-action: none;
  /* This makes things *much* easier */
  box-sizing: border-box;
  img {
    max-width: 100%;
  }
}
figcaption {
  border-top: 1px solid #efefef;
  padding: 8px;
}
.image-buttons {
  position: absolute;
  right: 0;
  bottom: 30px;
}
</style>
