import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/sports/site-sport-providers`, {
      params: data
    })
  },
  getOne(id) {
    return Api().get(`hercules/sports/site-sport-providers/${id}`)
  },
  post(data) {
    return Api().post(`hercules/sports/site-sport-providers`, data)
  },
  update(data) {
    return Api().put(`hercules/sports/site-sport-providers/${data.id}`, data)
  },
  updateSortOrder(data) {
    return Api().put(`hercules/sports/site-sport-providers/sort-order`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sports/site-sport-providers/${row.id}`)
  }
}
