import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sites/sites/${id}`, {
      params: data
    })
  },
  clone(data) {
    return Api().post(`hercules/sites/sites/clone`, data)
  },
  post(data) {
    return Api().post(`hercules/sites/sites`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/sites/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/sites/${row.id}`)
  }
}
