import Api from '../Api'

export default {
  getOne() {
    return Api().get(`event-streams/v1/stream-providers`)
  },
  update(data) {
    return Api().put(`event-streams/v1/streams`, data)
  }
}
