<template>
  <div class="d-flex align-items-center bubble-menu-icons shadow-sm">
    <popper hover arrow placement="top" v-if="editor.isActive('link') || isLinkedImage || isLinkedFigure">
      <template #content>
        <span class="popper">Edit Link</span>
      </template>
      <button id="editLink" @click.prevent="$emit('setLink')" slot="reference" class="font-weight-semi-bold">
        Edit Link
      </button>
    </popper>
    <popper hover arrow placement="top" v-if="isActiveImage || isActiveFigure">
      <template #content>
        <span class="popper">Replace Image</span>
      </template>
      <button
        id="replaceImage"
        @click.prevent="$emit('setImage', editor.getAttributes('image'))"
        :class="{ 'is-active': isActiveImage }"
        slot="reference"
      >
        <i class="uil uil-exchange"></i>
      </button>
    </popper>
    <popper hover arrow placement="top" v-if="isActiveImage || isActiveFigure">
      <template #content>
        <span class="popper">Image Title</span>
      </template>

      <button
        id="imageTitle"
        slot="reference"
        @click.prevent="$emit('setTitle')"
        :class="{ 'is-active': isActiveImage }"
      >
        <i class="uil uil-text"></i>
      </button>
    </popper>

    <popper hover arrow placement="top" v-if="isActiveImage || isActiveFigure">
      <template #content>
        <span class="popper">Image Alt text</span>
      </template>
      <button
        id="imageAltText"
        slot="reference"
        @click.prevent="$emit('setAltText')"
        :class="{ 'is-active': isActiveImage }"
      >
        <svg width="26px" height="26px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M1 3C1 1.89543 1.89543 1 3 1H10C11.1046 1 12 1.89543 12 3V9C12 10.1046 11.1046 11 10 11H3C1.89543 11 1 10.1046 1 9V3ZM3.5 4C3.22386 4 3 4.22386 3 4.5C3 4.77614 3.22386 5 3.5 5H9.5C9.77614 5 10 4.77614 10 4.5C10 4.22386 9.77614 4 9.5 4H3.5ZM3.5 7C3.22386 7 3 7.22386 3 7.5C3 7.77614 3.22386 8 3.5 8H9.5C9.77614 8 10 7.77614 10 7.5C10 7.22386 9.77614 7 9.5 7H3.5Z"
            fill="#212121"
          />
          <path
            d="M4 12H5.5V18.75C5.5 18.9584 5.53643 19.1583 5.60326 19.3437L11.4258 13.643C12.2589 12.8273 13.5675 12.7885 14.4458 13.5266L14.5742 13.6431L20.3964 19.3447C20.4634 19.159 20.5 18.9588 20.5 18.75V7.25C20.5 6.2835 19.7165 5.5 18.75 5.5H13V4H18.75C20.5449 4 22 5.45507 22 7.25V18.75C22 20.5449 20.5449 22 18.75 22H7.25C5.45507 22 4 20.5449 4 18.75V12ZM19.3305 20.4014L13.5247 14.7148C13.2596 14.4553 12.8501 14.4316 12.5588 14.644L12.4752 14.7148L6.66845 20.4011C6.8504 20.4651 7.04613 20.5 7.25 20.5H18.75C18.9535 20.5 19.1489 20.4653 19.3305 20.4014Z"
            fill="#212121"
          />
          <path
            d="M16.2521 7.5C17.4959 7.5 18.5042 8.50831 18.5042 9.75212C18.5042 10.9959 17.4959 12.0042 16.2521 12.0042C15.0083 12.0042 14 10.9959 14 9.75212C14 8.50831 15.0083 7.5 16.2521 7.5ZM16.2521 9C15.8367 9 15.5 9.33673 15.5 9.75212C15.5 10.1675 15.8367 10.5042 16.2521 10.5042C16.6675 10.5042 17.0042 10.1675 17.0042 9.75212C17.0042 9.33673 16.6675 9 16.2521 9Z"
            fill="#212121"
          />
        </svg>
      </button>
    </popper>

    <popper hover arrow placement="top" v-if="isImage && !editor.isActive('tableCell')">
      <template #content>
        <span class="popper">Caption</span>
      </template>
      <button
        id="caption"
        @click.prevent="setCaption"
        slot="reference"
        :class="{ figureActive: isActiveFigure }"
      >
        <i :class="'uil uil-image-v figure-caption' + '-' + isActiveFigure"></i>
      </button>
    </popper>
    <popper hover arrow placement="top" v-if="editor.isActive('link') || isLinkedImage || isLinkedFigure">
      <template #content>
        <span class="popper">Open link in new tab</span>
      </template>
      <button
        id="openLink"
        @click.prevent="openAnchorLink"
        slot="reference"
        :class="{ 'is-active': editor.isActive('link') || isLinkedImage || isLinkedFigure }"
      >
        <i class="uil uil-external-link-alt"></i>
      </button>
    </popper>

    <popper hover arrow placement="top" v-if="editor.isActive('link')">
      <template #content>
        <span class="popper">Unlink</span>
      </template>

      <button id="unlink" slot="reference" @click.prevent="editor.chain().focus().unsetLink().run()">
        <i class="uil uil-link-h cross-through"></i>
      </button>
    </popper>
    <popper hover arrow placement="top" v-else-if="isLinkedFigure">
      <template #content>
        <span class="popper">Unlink Figure</span>
      </template>

      <button
        id="unlinkFigure"
        slot="reference"
        @click.prevent="editor.chain().focus().unsetFigureLink().run()"
      >
        <i class="uil uil-link-h cross-through"></i>
      </button>
    </popper>
    <popper hover arrow placement="top" v-if="isActiveImage || isActiveFigure">
      <template #content>
        <span class="popper">Image alignment</span>
      </template>

      <div slot="reference" class="dropdown">
        <button
          id="imageAlignment"
          class="dropdown-toggle bubble-align"
          tabindex="0"
          @click.prevent="() => {}"
        >
          <i class="uil uil-star star-border"></i>
        </button>
        <ul class="dropdown-menu">
          <li
            v-for="key in Object.keys(imageStyles)"
            :key="key"
            @click="updateImageStyle(key)"
            :class="{ active: activeStyle === key }"
          >
            {{ imageStyles[key] }}
          </li>
        </ul>
      </div>
    </popper>
    <popper hover arrow placement="top" v-if="isActiveImage || isActiveFigure">
      <template #content>
        <span class="popper">Delete Image</span>
      </template>

      <button
        id="deleteImage"
        slot="reference"
        @click.prevent="editor.chain().focus().deleteSelection().run()"
      >
        <i class="uil uil-image-alt-slash"></i>
      </button>
    </popper>
  </div>
</template>

<script>
import { imageStyles } from '@/lib/tiptap/constants.js'
import BaseSelect from '@/components/fields/base-select.vue'

export default {
  components: { BaseSelect },
  props: {
    editor: {},
    required: true,
    isTable: {
      type: Boolean,
      default: false,
    },
    canOpenAnchorLink: {
      type: Boolean,
      default: false,
    },
    isActiveImage: {
      type: Boolean,
      default: false,
    },
    isActiveFigure: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageStyles: imageStyles,
    }
  },
  computed: {
    activeStyle() {
      const { node } = this.editor.state.selection
      if (node) {
        return node.attrs?.style
      }
      return null
    },
    isImage() {
      return this.isActiveImage || this.isActiveFigure
    },
    isLinkedFigure() {
      return this.editor.getAttributes('figure').href && this.editor.getAttributes('figure').href.length
    },
    isLinkedImage() {
      return this.editor.getAttributes('image').href && this.editor.getAttributes('image').href?.length
    },
  },
  methods: {
    setCaption() {
      // check if imageToFigure
      if (this.isActiveFigure) {
        this.editor.chain().focus().figureToImage().run()
      } else {
        this.editor.chain().focus().imageToFigure().run()
      }
    },
    async openAnchorLink() {
      const link = this.editor.isActive('figure') ? 'figure' : 'link'
      const pageId = this.editor.getAttributes(link).pageId
      const href = this.editor.getAttributes(link).href
      if (pageId && pageId !== 'undefined') {
        if (this.$route.fullPath.includes('/site-pages/')) {
          window.open(`${pageId}`, '_blank')
        } else {
          window.open(`/site-pages/${pageId}`, '_blank')
        }
      } else {
        window.open(href, '_blank')
      }
    },
    updateImageStyle(style) {
      const element = this.isActiveImage ? 'image' : 'figure'
      const imagePos = this.editor.state.selection.from

      this.editor
        .chain()
        .focus()
        .updateAttributes(element, { style: this.activeStyle === style ? null : style })
        .run()

      this.editor.chain().focus().setNodeSelection(imagePos).run()
    },
  },
}
</script>
<style scoped lang="scss">
.bubble-button-radius {
  span {
    button {
      border-radius: 4px;
    }
    .uil {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
