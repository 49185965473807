const ids = {
    pages: {
        top: {
          id: 1708883983
        },
        general: {
            id: 2405302298
        },
        sections: {
            id: 2404909141
        },
        seo: {
            id: 2405728275,
            search_engine_optimization: {
                id: 2406023303
            },
            robot_options: {
                id: 2405662871
            },
            hreflang: {
                id: 2405728443
            },
            json_schema: {
                id: 2405695745
            }
        },
        advanced: {
            change_type_page: {
                id: 2404614492
            },
            clone_page: {
                id: 2405040414
            },
            delete_page: {
                id: 2405695752
            },
            raw_jw_inj: {
                id: 2405695759
            }
        }

    },
    sites: {
        top: {
          id: 1868300764
        },
        general: {
            id: 2405826866
        },
        breadcrumbs: {
            id: 2411429969
        },
        module_colors: {
            id: 2410184897
        },
        module_styles: {
            id: 2412118364
        },
        categories: {
            id: 2040987684
        },
        markets: {
            id: 2413363209
        },
        schema: {
            id: 2410938893
        }
    },
    menus: {
        top: {
            id: 1868661136
        },
        general: {
            id: 2408579241
        },
        items: {
            id: 2409136287
        }
    },
    banners: {
        top: {
          id: 2410643676
        },
        general: {
            id: 2411921426
        },
        items: {
            id: 2410938657
        }
    },
    authors: {
        top: {
            id: 2405171300
        },
        general: {
            id: 2413330574
        },
        pages: {
            id: 2412118461
        }
    },
    redirects: {
        id: 2411069614
    },
    page_types: {
        top: {
            id: 1708523553
        },
        general: {
            id: 2038170113
        },
        blocks: {
            id: 2405859584
        },
        page_styles: {
            id: 2405466259
        },
        extra_fields: {
            id: 2404843743
        },
        seo: {
            id: 2405236894
        },
        autogenerated_content: {
            id: 2801401938
        },
    },
    reports: {
        id: 2411724981
    },
    user_comments: {
        id: 2411266194
    }
};

export default ids;