<template>
  <div>
    <data-table
        model="koala/Campaign"
        service-file="koala/CampaignsService"
        path="elias/v1/nested_campaigns?orderBy=created&ascending=0"
        permissionPath="koala/v1/campaigns"
        modalSizeClass="modal-lg"
        :stateful-columns="true"
        :editGridLayout="true"
        :centered-modal="true"
        :editFieldFilters="['created', 'modified']"
        :searchDelay="600"
        :editSubmitDataModifier="dataModifier"
        :columns="columns()"
        :columnTemplates="columnTemplates()"
        @bulkClickByIds="bulkClickByIds"
        :enableBulkSelect="true"
        ref="table"
        :tableLimits="[10, 50, 100]"
    />
    <dynamic-bulk-edit
        v-if="showBulkEdit"
        ref="bulkEdit"
        model="koala/Campaign"
        service-file="koala/CampaignsService"
        @closed="() => (this.showBulkEdit = false)"
        @refresh="$refs.table.search()"
    />
  </div>
</template>

<script>
import DynamicBulkEdit from '@molecules/koala/dynamic-bulk-edit.vue'

export default {
  data() {
    return {
      showBulkEdit: false
    }
  },
  created() {
    this.setTitle()
  },
  components: { DynamicBulkEdit },
  methods: {
    mounted() {
      this.$refs.bulkEdit.show()
    },
    columns() {
      return [
        {
          field: 'finance_unit',
          type: 'text',
          search_key: 'finance_unit',
          filterable: true,
          advancedFilter: true,
          path: 'elias/v1/finance_units?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'name',
          type: 'text',
          search_key: 'name',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: true
        },
        {
          field: 'platform',
          type: 'text',
          filterable: true,
          order_key: 'account__operator__platform__name',
          search_key: 'account__operator__platform',
          advancedFilter: true,
          path: 'elias/v1/platforms?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'operator',
          type: 'text',
          filterable: true,
          order_key: 'account__operator__name',
          search_key: 'account__operator',
          showModalAdvanced: true,
          advancedFilter: true,
          path: 'elias/v1/operators?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'username',
          type: 'text',
          filterable: true,
          order_key: 'account__username',
          search_key: 'account__username'
        },
        {
          field: 'brand',
          type: 'text',
          search_key: 'brand',
          filterable: true,
          advancedFilter: true,
          path: 'elias/v1/brands?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'unit',
          type: 'text',
          search_key: 'unit',
          filterable: true,
          advancedFilter: true,
          path: 'elias/v1/units?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          searchBy: 'name'
        },
        {
          field: 'vertical',
          type: 'text',
          order_key: 'vertical__name',
          search_key: 'vertical',
          filterable: true,
          advancedFilter: true,
          path: 'elias/v1/verticals?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'channel',
          type: 'text',
          search_key: 'channel',
          showModalAdvanced: true,
          filterable: true,
          advancedFilter: true,
          path: 'elias/v1/channels?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'country',
          type: 'text',
          order_key: 'country',
          search_key: 'country',
          advancedFilter: true,
          filterable: true,
          path: 'elias/v1/countries?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          trackBy: 'code',
          advancedFilterTypeSingle: true,
          showAllOption: true,
          showEmptyNull: true,
          fetch_on_load: false,
        },
        {
          field: 'source',
          type: 'text',
          order_key: 'source__name',
          search_key: 'source',
          filterable: true,
          advancedFilter: true,
          path: 'elias/v1/sources?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'website',
          type: 'text',
          order_key: 'website__name',
          search_key: 'website',
          advancedFilter: true,
          filterable: true,
          path: 'elias/v1/websites?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'page_type',
          type: 'text',
          order_key: 'page_type__name',
          search_key: 'page_type',
          advancedFilter: true,
          filterable: true,
          path: 'elias/v1/page_types?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'position',
          type: 'text',
          order_key: 'position__name',
          search_key: 'position',
          advancedFilter: true,
          filterable: true,
          path: 'elias/v1/positions?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'device',
          label: 'Device',
          type: 'text',
          search_key: 'device',
          advancedFilter: true,
          filterable: true,
          path: 'elias/v1/devices?orderBy=name',
          optionLabelKey: 'name',
          basicFilterType: 'select',
          showModalAdvanced: true,
          showEmptyNull: true,
          fetch_on_load: false,
          searchBy: 'name'
        },
        {
          field: 'keywords',
          type: 'text',
          filterable: true
        }
      ]
    },
    columnTemplates() {
      return Object.assign(
          {
            finance_unit: function(row) {
              return row.finance_unit?.name
            }
          },
          {
            platform: function(row) {
              return row.account?.operator?.platform?.name
            }
          },
          {
            operator: function(row) {
              return row.account?.operator?.name
            }
          },
          {
            username: function(row) {
              return row.account?.username
            }
          },
          {
            position: function(row) {
              return row.position?.name
            }
          },
          {
            brand: function(row) {
              return row.brand?.name
            }
          },
          {
            source: function(row) {
              return row.source?.name
            }
          },
          {
            unit: function(row) {
              return row.unit?.name
            }
          },
          {
            vertical: function(row) {
              return row.vertical?.name
            }
          },
          {
            channel: function(row) {
              return row.channel?.name
            }
          },
          {
            country: function(row) {
              return row.country_name
            }
          },
          {
            website: function(row) {
              return row.website?.name
            }
          },
          {
            page_type: function(row) {
              return row.page_type?.name
            }
          },
          {
            position: function(row) {
              return row.position?.name
            }
          },
          {
            position: function(row) {
              return row.position?.name
            }
          },
          {
            device: function(row) {
              return row.device?.name
            }
          },
          {
            site: function(row) {
              return row.site.name
            }
          },
          {
            keywords: function(row) {
              return row.keywords.join(', ')
            }
          }
      )
    },
    bulkClickByIds(ids, extraData , filters, resultCount) {
      if (filters && Object.keys(filters).length > 0) {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByFilters(ids, filters, resultCount, extraData))
      } else {
        this.showBulkEdit = true
        this.$nextTick(() => this.$refs.bulkEdit.showByIds(ids))
      }
    },
    bulkClickByFilters(ids, filters, resultCount, extraData) {
      this.showBulkEdit = true
      this.$nextTick(() => this.$refs.bulkEdit.showByFilters(ids, filters, resultCount, extraData))
    },
    dataModifier(data) {
      let modified = { ...data }
      const objectKeys = [
        'account',
        'brand',
        'channel',
        'device',
        'page_type',
        'position',
        'unit',
        'vertical',
        'website'
      ]
      objectKeys.forEach(key => {
        if (data[key] && data[key].id) {
          modified[key] = data[key].id
        }
      })
      modified.keywords = data.keywords ? data.keywords : []
      return modified
    }
  }
}
</script>
