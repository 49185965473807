import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/operators/affiliate-links`, {
      params: data
    })
  },
  getOne(id, data) {
    return Api().get(`hercules/operators/affiliate-links/${id}`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/operators/affiliate-links`, data)
  },
  remove(data) {
    return Api().delete(`hercules/operators/affiliate-links/${data.id}`)
  },
  update(data) {
    return Api().put(`hercules/operators/affiliate-links/${data.id}`, data)
  },
  bulkUpdate(data) {
    return Api().put(`hercules/operators/affiliate-links/bulk`, data)
  },
  generateDefaults(data) {
    return Api().post(`hercules/operators/affiliate-links/generate-defaults`, data)
  }
}
