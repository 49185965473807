<template>
  <div @click.stop.prevent="openInNewTab" v-if="getValue()" class="url-column">
    <span class="href d-flex align-items-center">
      <i class="uil uil-external-link-alt text-muted mr-1"></i>
      {{ getValue() }}
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    getValue() {
      return this.$attrs.value
    },
    openInNewTab() {
      const url = this.getValue()
      if (url) {
        window.open(url, '_blank', 'noopener,noreferrer')
      }
    }
  }
}
</script>

<style scoped>
.href:hover {
  color: var(--blue);
}
.url-column {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
