import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sports/site-events/${id}`, {
      params: data
    })
  },
  get(data) {
    return Api().get(`hercules/sports/site-events`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/sports/site-events`, data)
  },
  update(data) {
    return Api().put(`hercules/sports/site-events/${data.id}`, data)
  },
  updateSortOrder(data) {
    return Api().put(`hercules/sports/site-events/sort-order`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sports/site-events/${row.id}`)
  }
}
