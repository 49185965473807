import Api from './Api'

export default {
  fetchItems: function(url, data) {
    return Api().get(`${url}`, {
      params: data
    })
  },
  fetchItem: function(url, id, data) {
    return Api().get(`${url}/${id}`, {
      params: data
    })
  },
}
