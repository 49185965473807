<template>
  <div>
    <no-roles-message v-if="!userHasRoles"></no-roles-message>
    <div v-else>
      <site-logs v-if="hasRole('admin') || hasRole('site logs')"></site-logs>
      <AffiliateStats />
      <SearchCard />
    </div>
  </div>
</template>

<script>
import OperatorStats from '@molecules/misc/hercules-stats.vue'
import NoRolesMessage from '@atoms/general/no-roles-message.vue'
import SiteLogs from '@atoms/misc/site-logs.vue'
import SearchCard from '@atoms/misc/search-card.vue'
import AffiliateStats from '@molecules/misc/affiliate-stats.vue'

export default {
  components: {
    OperatorStats,
    SearchCard,
    NoRolesMessage,
    SiteLogs,
    AffiliateStats
  },
  created() {
    this.setTitle()
  },
  computed: {
    userHasRoles() {
      return this.$auth.check() ? this.$auth.user().roles.length > 0 : false
    }
  },
  data() {
    return {
      showNoRolesMessage: false
    }
  }
}
</script>
