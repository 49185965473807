import Api from './Api'

export default {
  reload(config_id) {
    return Api().get(`hercules/sites/configs/reload/${config_id}`)
  },
  deploy(id) {
    return Api().get(`hercules/sites/deployments/run?id=${id}`)
  },
  updateDeployment(data) {
    return Api().put(`hercules/sites/deployments/${data.id}`, data)
  }
}
