<script>
export default {
  render: function(createElement) {
    return createElement(
      this.level, // tag name
      this.$slots.default // array of children
    )
  },

  props: {
    level: {
      required: true,
      validator: function(value) {
        if (['h1', 'h2', 'h3', 'h4', 'h5'].indexOf(value) !== -1) {
          return true
        }
        // eslint-disable-next-line no-console
        console.error(`${value} is not a valid level`)
        return false
      }
    }
  }
}
</script>
