const storageName = 'tableFilters'

export default {
  compareFilters(filterOne, filterTwo) {
    return JSON.stringify(filterOne) === JSON.stringify(filterTwo)
  },
  getFilters() {
    const rawFilters = localStorage.getItem(storageName)
    if (!rawFilters || rawFilters === '') {
      return {}
    }
    return JSON.parse(rawFilters)
  },
  isTextSelected() {
    return (
      window
        .getSelection()
        .toString()
        .trim() !== ''
    )
  },
  isNotValidClick(event) {
    const parentClass = (event.target.parentNode && event.target.parentNode.className) || []
    const targetClass = (event.target && event.target.className) || []
    const finalClass = parentClass.concat(targetClass)

    return ['checkbox', 'bulk-col', 'clickable'].some(i => finalClass.includes(i))
  },
  isCtrlKey(event) {
    return event.ctrlKey || event.metaKey
  },
  getUrlParamsFilters(params) {
    let queryFilters = {}

    Object.keys(params).forEach(key => {
      queryFilters[key] = params[key]
    })

    return queryFilters
  },
  advancedFilteringSanitization(filters, columns) {
    const queryKeys = Object.keys(filters)
    let columnsMappedByName = {}
    columns.forEach(column => {
      columnsMappedByName[column.field] = column
    })

    let activeFilters = {}
    let exactMatches = []

    if (queryKeys.length > 0) {
      queryKeys.forEach(key => {
        let filter = {}

        let keyFilter = ''

        if (['__exclude', '__isnull', '__isempty'].filter(w => key.includes(w)).length > 0) {
          keyFilter = key.substring(key.lastIndexOf('__'))
        }

        let sanitizedKey = key.replace(keyFilter, '')
        let columnKey = columnsMappedByName[sanitizedKey]

        if (columnKey?.search_key) {
          let searchKey = columnKey.search_basic_key ? columnKey.search_basic_key : columnKey.search_key
          let newKey = searchKey + keyFilter
          filter[newKey] = filters[key]

          if (columnKey?.exact_match) {
            exactMatches.push(newKey)
          }
        } else {
          filter[key] = filters[key]
        }

        Object.assign(activeFilters, filter)
      })
    }

    if (exactMatches.length > 0) {
      activeFilters['exact_match'] = exactMatches.join(',')
    }

    return activeFilters
  }
}
