import Api from './Api'

export default {
  getFiltered(filter) {
    var queryString = Object.keys(filter)
      .map(key => key + '=' + filter[key])
      .join('&')

    return Api().get(`hercules/translations/translations?${queryString}`)
  },
  getOne(id) {
    return Api().get(`hercules/translations/translations/${id}?with=language`)
  },
  post(data) {
    return Api().post(`hercules/translations/translations`, data)
  },
  update(data) {
    return Api().put(`hercules/translations/translations/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/translations/translations/${row.id}`)
  },
  csv(data) {
    return Api().post(`hercules/translations/translations/csv`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
