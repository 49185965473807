<template>
  <div v-if="object.length">
    <div class="d-flex align-items-center">
      <h4 class="mb-0 mr-3">Migrated from</h4>
      <button class="btn btn-success" @click="showAllinTable">Show all in table</button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" v-for="(header, key) in headers" :key="key">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(migratedToAccount, key) in object" :key="key" scope="row" @click="goToAccount(migratedToAccount.id)">
          <td>{{ migratedToAccount.id }}</td>
          <td v-html="scrapable(migratedToAccount.scrapable)"></td>
          <td>{{ migratedToAccount.platform }}</td>
          <td>{{ migratedToAccount.operator }}</td>
          <td>{{ migratedToAccount.username }}</td>
          <td><AccountStatus :row="migratedToAccount" :status="migratedToAccount.status" /></td>
          <td>{{ migratedToAccount.account_manager_name }}</td>
          <td>
            <div class="switch clickable">
              <input type="checkbox" :id="switchId(migratedToAccount.id)" v-model="migratedToAccount.active" disabled />
              <label :for="switchId"></label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AccountStatus from '@atoms/table/cell/account-status.vue'

export default {
  components: { AccountStatus },
  props: {
    object: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      headers: [
        'ID',
        'Scrapable',
        'Platform',
        'Operator',
        'Username',
        'Status',
        'Account Manager Name',
        'Enabled',
      ],
      migratedIds: []
    }
  },
  methods: {
    showAllinTable() {
      let routeData = this.$router.resolve({
        path: '/koala-accounts',
        query: { ids: this.object.map(o => o.id).join(',') },
      });
      window.open(routeData.href, '_blank');
    },
    goToAccount(id) {
      let routeData = this.$router.resolve({
        path: '/koala-accounts',
        query: { ids: id },
      });
      window.open(routeData.href, '_blank');
    },
    switchId(id) {
      return `switch-${id}`;
    },
    scrapable(scrapable) {
      return scrapable ? `<span class="badge badge-success"><i class="uil uil-check"></i></span>` : `<span class="badge badge-light"><i class="uil uil-times"></i></span>`;
    }
  }
}
</script>

<style scoped>
.account-status-badge {
  display: inline;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
