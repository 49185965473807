<template>
  <div class="d-flex flex-wrap bubble-menu-icons shadow-sm w-100 align-items-center">
    <popper trigger arrow placement="top">
      <template #content>
        <span class="popper">Edit Anchor</span>
      </template>

      <button
        id="editAnchor"
        slot="reference"
        @click.prevent="$emit('setAnchor')"
        class="font-weight-semi-bold"
        :class="{
          'is-active':
            editor.getAttributes('paragraph')['data-anchor-label'] ||
            editor.getAttributes('heading')['data-anchor-label'],
        }"
      >
        Edit anchor
      </button>
    </popper>
    <popper trigger arrow placement="top">
      <template #content>
        <span class="popper">Unset Anchor</span>
      </template>

      <button
        id="unsetAnchor"
        slot="reference"
        @click.prevent="
          editor
            .chain()
            .focus()
            .resetAttributes(editor.isActive('heading') ? 'heading' : 'paragraph', [
              'id',
              'data-anchor-label',
            ])
            .run()
        "
        :class="{ 'is-active': isAnchor }"
      >
        <i class="uil uil-anchor cross-through"></i>
      </button>
    </popper>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'

export default {
  components: { BaseSelect },
  props: {
    editor: {},
    required: true,
    isAnchor: {
      type: Boolean || String,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped></style>
