<template>
  <div class="col">
    <div class="card" v-if="moduleField">
    </div>
  </div>
</template>
<script>

export default {
  methods: {
    findFieldByName(name) {
      return this.moduleField.children.find(item => item.field.name === name)?.value
    }
  },
  computed: {
    listItemName() {
      return this.findFieldByName('iframe_clip_list_item_name')
    },
    listItemUrl() {
      return this.findFieldByName('iframe_clip_list_item_url')
    },
    listItemStartOffset() {
      return this.findFieldByName('iframe_clip_list_item_start_offset')
    },
    listItemEndOffset() {
      return this.findFieldByName('iframe_clip_list_item_end_offset')
    },
  },
  props: {
    fieldSpecificAttributes: {},
    moduleField: {
      type: Object,
      required: true
    }
  }
}
</script>
