import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/assets/files`, {
      params: data
    })
  },
  getOne(id, data = {}) {
    return Api().get(`hercules/assets/files/${id}`, {
      params: data
    })
  },
  create(data, params) {
    return Api().post(`backoffice/files/submit`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: params
    })
  },
  update(id, data) {
    return Api().put(`hercules/assets/files/update/${id}`, data)
  },
  clone(params) {
    return Api().post(`backoffice/files/clone`, params)
  }
}
