<template>
  <div class="form-group">
    <label v-if="label" class="_label">{{ $prettyLabels(label) }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>
    <br v-if="label" />
    <template v-if="notifiedByField && !$parent.object[notifiedByField.field]">
      <span class="text-muted ml-2">{{ notifiedByField.label }}</span>
    </template>
    <div class="mt-2" :class="[inlineLayout ? 'd-flex' : 'd-flex flex-x1']">
      <div
        v-for="(item, index) in data"
        v-bind:key="index"
        class="radio mr-3"
        :class="[!inlineLayout && index !== last && 'mb-3']"
      >
        <input
          @change="changedInput(name, item)"
          :disabled="disabled || (notifiedByField && !$parent.object[notifiedByField.field])"
          type="radio"
          :name="name + getRandomName"
          :value="modelValue"
          :id="getRandomName + index"
          :checked="modelValue === getItemValue(item)"
        />
        <label :for="getRandomName + index"
          >{{ $prettyLabels(getItemLabel(item)) }}
          <small v-if="getItemHelpText(item)" class="form-text text-muted">{{ getItemHelpText(item) }}</small>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    label: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    modelValue: {
      name: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    data: {
      type: [Array, Object],
      required: true,
    },
    useNameValue: {
      type: Boolean,
      default: () => true,
    },
    inlineLayout: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    helpText: {
      type: String,
      default: () => null,
    },
    notifiedByField: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    getRandomName() {
      return Math.random().toString(36).substring(7) + this.name
    },
    last() {
      return Object.keys(this.data).length - 1
    },
  },
  methods: {
    changedInput(name, value) {
      if (this.useNameValue) {
        this.$emit('update:modelValue', { name: name, value: this.getItemValue(value) })
      } else {
        this.$emit('update:modelValue', this.getItemValue(value))
      }
    },
    getItemValue(item) {
      if (_.isObject(item)) {
        return item.value
      } else {
        return item
      }
    },
    getItemLabel(item) {
      if (_.isObject(item)) {
        if (item.label) {
          return item.label
        } else {
          return item.value
        }
      } else {
        return item
      }
    },
    getItemHelpText(item) {
      if (_.isObject(item)) {
        return item.helpText
      } else {
        return this.helpText
      }
    },
  },
  watch: {
    value() {
      if (this.$refs.validator) {
        this.$refs.validator.validate()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.radio {
  label {
    font-weight: normal !important;
  }
}
</style>
