<template>
    <data-table
        model="SportCategory"
        service-file="SportCategoriesService"
        path="hercules/operators/sport-categories"
        :columns="columns()"
    />
  </template>
  
  <script>
  import TableColumnTemplate from '@constants/table-column-template'
  
  export default {
    created() {
      this.setTitle()
    },
    methods: {
      columns() {
        return [
          { field: 'id', type: 'text', filterable: true },
          { field: 'name', type: 'text', filterable: true },
          { field: 'short_name', type: 'text', filterable: true },
          { field: 'updated_at', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
        ]
      }
    }
  }
  </script>
  