import Api from './Api'

export default {
  getOne(id, data) {
    return Api().get(`hercules/sites/redirects/${id}`, {
      params: data
    }).then(response => {
      const result = response.data.result[0];

      result.page_selector_from = result.page_id_from !== null ? 2 : 1;
    
      result.page_selector_to = result.page_id_to !== null ? 2 : 1;
    
      return response;
    })
  },
  post(data) {
    return Api().post(`hercules/sites/redirects`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/redirects/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/redirects/${row.id}`)
  },
  csv(data) {
    return Api().post(`hercules/sites/redirects/csv`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
