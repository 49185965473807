import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/sites/breadcrumbs`, { params: data })
  },
  update(data) {
    return Api().put(`hercules/sites/breadcrumbs`, data)
  }
}
