import Api from './Api'

export default {
  getAll() {
    return Api().get('hercules/pandora/site-login')
  },
  post(data) {
    return Api().post('hercules/pandora/site-login', data)
  },
  remove(row) {
    return Api().delete(`hercules/pandora/site-login/${row.id}`)
  }
}
