<template>
  <div class="form-group list-view-container" :hidden="type == 'hidden'" :name="label && slugify(label)">
    <label>{{ $prettyLabels(label) }}</label>
    <label class="text-muted ml-1 clickable" v-if="labelAction" @click="labelAction">{{
      labelActionTitle
    }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>

    <ul class="list-group list-style ml-2 mr-2 pl-2 pr-2 mb-2 mt-2 list-container">
      <li class="no-hover list-group-item d-flex pl-0 pr-0">
        <div class="d-flex flex-row w-85">
          <span class="w-100 pl-3 pr-3" v-for="(field, index) in fieldList" v-bind:key="index">
            {{ $prettyLabels(field.display_name) }}
          </span>
        </div>
      </li>
      <li
        v-for="(item, index) in value"
        v-bind:key="index"
        class="no-hover list-group-item d-flex flex-row justify-content-between pl-0 pr-0"
      >
        <dynamic-fields
          :formFields="fieldList"
          :object="item"
          formType="edit"
          fieldClass="d-flex flex-row form-styling w-85"
          @valueChanged="updateVal"
        />
        <button
          type="button"
          v-on:click.stop="removeItem(index)"
          class="btn btn-danger btn-sm align-self-center mr-2"
        >
          <i class="uil uil-trash"></i> Delete
        </button>
      </li>
      <li class="no-hover list-group-item d-flex flex-row justify-content-center">
        <button
          :disabled="isAddItemDisabled"
          type="button"
          v-on:click.stop="addItem"
          class="btn btn-success btn-sm"
        >
          <i class="uil uil-plus"></i> Add
        </button>
      </li>
    </ul>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
export default {
  computed: {
    isAddItemDisabled() {
      return this.value
        ? this.value.filter((item) => {
            if (item.contact_type === '' || item.email === '') {
              return item
            }
          }).length > 0
        : false
    },
  },
  props: {
    field: {
      type: Object,
    },
    fieldList: {
      type: Array,
    },
    label: {
      type: String,
    },
    labelActionTitle: {
      type: String,
    },
    labelAction: {
      type: Function,
    },
    helpText: {
      type: String,
    },
    value: {
      type: Array,
    },
    type: {
      default: () => 'text',
    },
    readOnly: {
      default: () => false,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
  },
  methods: {
    updateVal() {
      this.$emit('sync', this.field.name, JSON.stringify(this.value))
    },
    addItem() {
      let newObject = {}
      this.fieldList.forEach((field) => {
        newObject[field.name] = ''
      })
      this.value.push(newObject)
      this.$emit('sync', this.field.name, JSON.stringify(this.value))
    },
    removeItem(index) {
      this.value.splice(index, 1)
      this.$emit('sync', this.field.name, JSON.stringify(this.value))
    },
  },
}
</script>
