export default {
  getForm() {
    return [
      {
        name: 'source_site_id',
        type: 'hidden'
      },
      {
        name: 'source_site',
        type: 'text',
        disabled: true,
        display_name: 'Site to copy'
      },
      {
        name: 'name',
        required: true,
        auto_focus: true,
        type: 'text',
        display_name: 'New site name',
        help_text: 'Only full URLs are accepted, e.g. http://mysite.com'
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets',
        required: true,
        display_name: 'New site market',
        selectLabel: 'label',
        type: 'list-one',
        value_key: 'market.label'
      }
    ]
  },
  getConfig() {
    return {
      name: 'Site',
      url: 'sites/sites'
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
