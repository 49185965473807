<template>
  <div class="page-size align-items-center">
    <label class="alt mb-0" v-if="tableLimits.length">Limit:</label>
    <div class="btn-group">
      <span
        v-for="limit in tableLimitsState"
        v-bind:key="limit"
        class="btn btn-link clickable pl-1 pr-1"
        v-bind:class="{ active: limit === activeLimit }"
        @click="() => (activeLimit = limit)"
        >{{ limit }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stateName: {
      type: String,
      required: true,
    },
    tableLimits: {
      required: false,
      default: () => [10, 100, 1000],
    },
  },
  data() {
    return {
      tableLimitsState: this.tableLimits,
    }
  },
  computed: {
    activeLimit: {
      set(value) {
        if (value === this.activeLimit) {
          this.emitter.emit(`${this.stateName}.search`) // refresh search
        } else {
          this.$store.dispatch(`${this.stateName}/setLimit`, value)
          this.$store.dispatch(`${this.stateName}/setActivePage`, 1)
        }
      },
      get() {
        return this.$store.getters[`${this.stateName}/limit`]
      },
    },
  },
}
</script>
