<template>
  <div v-if="value.market">
    <span class="popover-wrapper" v-if="value.linked_group">
      {{ value.market.label }}
      <i class="popover-trigger uil uil-link font-size-lg"></i>
      <span class="popover-content">
        Belongs to market group:
        <ul class="mb-1">
          <li>
            <a
              class="m-1 mx-2 link-primary"
              @click.stop="
                $router.push({
                  path: `/affiliates/${value.affiliate_id}/linked-markets?child=${value.linked_group.id}`
                })
              "
              >{{ value.linked_group.group_name }}</a
            >
          </li>
        </ul>
        Default market:
        <div>
          <ul>
          <li>
            <strong>{{ value.linked_group.default_market.label }} </strong>
          </li>
        </ul>
        
        </div>
      </span>
    </span>
    <span v-else> {{ value.market.label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {}
  }
}
</script>
