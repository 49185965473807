<template>
  <div class="d-flex mx-2 mb-3">
    <div class="w-33">
      <h4>Pros</h4>
      <ul class="list-group">
        <li class="list-group-item bg-green-10" v-for="(pro, index) in pros" :key="index">{{ pro.value }}</li>
      </ul>
    </div>
    <div class="w-33 ml-5">
      <h4>Cons</h4>
      <ul class="list-group">
        <li class="list-group-item bg-red-10" v-for="(con, index) in cons" :key="index">{{ con.value }}</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find(item => item.field.name === name)
    }
  },
  computed: {
    pros() {
      return this.findFieldByName('perks_and_cons_perks').children
    },
    cons() {
      return this.findFieldByName('perks_and_cons_cons').children
    }
  },
  props: {
    fieldSpecificAttributes: {},
    pageModule: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.spotlight-img {
  max-height: 100px;
}
.spotlight-img-icon {
  max-height: 60px;
}
</style>
