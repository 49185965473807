<template>
  <div @click="copyValue($attrs.value)" data-tooltip="Copy to clipboard" class="clipboard-column">
    <span class="clickable">
      <i class="fas fa-copy text-muted mr-1" />
      {{ $attrs.value }}
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    copyValue(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          this.showSuccessMessage('Copied to your clipboard.')
        })
        .catch((err) => {
          this.showErrorMessage('Failed to copy text: ', err)
        })
    },
  },
}
</script>

<style scoped>
.clickable:hover {
  color: var(--blue);
}
.clipboard-column {
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
