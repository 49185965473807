export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'name',
        type: 'text',
        slug_field: 'short_name'
      },
      {
        name: 'short_name',
        required: true,
        display_name: 'Short name',
        type: 'slug',
        disabled: true
      },
      {
        name: 'active',
        required: false,
        display_name: 'Active',
        type: 'checkbox',
        default: 0
      },
      {
        name: 'type',
        field: 'type',
        filterable: true,
        type: 'list-one',
        options: ['page', 'market'],
        prettyLabels: true,
        display_name: 'Type',
        required: true,
        clickable: true,
        trackBy: null,
        selectLabel: null
      },
      {
        name: 'site_id',
        search_url: 'hercules/sites/sites',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'name',
        only_create: true,
        notify_fields: [{ field: 'enabled_modules', param: 'site_id' }]
      },
      {
        name: 'enabled_modules',
        search_url: 'hercules/sites/modules',
        display_name: 'Enabled Modules',
        type: 'list-many',
        required: false,
        selectLabel: 'label',
        fetch_on_load: false,
        validate_changes: true,
        notified_by: [{ field: 'site_id', label: 'Site' }]
      }
    ]
  },
  getConfig() {
    return {
      url: 'sites/sections',
      name: 'Section',
      editWithData: 'enabled_modules'
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
