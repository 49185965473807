<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg3">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Consumer details</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body overflow-scroll mh-600">
          <div class="d-flex align-items-center mb-3 bg-secondary p-3">
            <i class="uil uil-envelope"></i>
            <span class="selectable ml-2">channel: {{ object.channel }}</span>
            <hr class="vertical" />
            from: {{ object.from }}
            <hr class="vertical" />
            <span id="userid">size: {{ size() }}</span>
            <hr class="vertical" />
            <span>{{ $dateRelative(object.sent_from_hercules) }}</span>
          </div>
          <table class="table w-100">
            <thead>
              <tr>
                <th>#</th>
                <th>site</th>
                <th>IP</th>
                <th>Version</th>
                <th>GIT hash</th>
                <th>received</th>
                <th>processed</th>
                <th>total</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(consumer, key) in object.consumer_performance_logs" :key="key">
                <td>{{ key + 1 }}</td>
                <td class="text-truncate">{{ consumer.site_url }}</td>

                <td>{{ consumer.server_ip }}</td>
                <td>{{ consumer.json_updater_version }}</td>
                <td>{{ consumer.commit_hash }}</td>

                <td>{{ difference(consumer.json_updater_message_received, object.sent_from_hercules) }}s</td>
                <td>
                  {{
                    difference(
                      consumer.json_updater_message_processed,
                      consumer.json_updater_message_received
                    )
                  }}s
                </td>
                <td>{{ difference(consumer.json_updater_message_processed, object.sent_from_hercules) }}s</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import byteSize from 'byte-size'

export default {
  data() {
    return {
      object: {},
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    difference(one, two) {
      return moment(one).diff(moment(two), 'seconds', true)
    },
    size() {
      const size = byteSize(this.object.message_size)
      return `${size.value} ${size.unit}`
    },
    show() {
      this.$nextTick(() => {
        this.$refs.modal.classList.add('show')
      })
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
  },
}
</script>
