export default {
  getForm() {
    return [
      {
        name: 'operator',
        type: 'text',
        display_name: 'Operator',
        disabled: true,
        read_only: true
      },
      {
        name: 'username',
        type: 'text',
        display_name: 'Username',
        disabled: true,
        read_only: true
      },
      {
        name: 'password',
        type: 'text',
        display_name: 'Password'
      },
      {
        name: 'api_username',
        type: 'text',
        display_name: 'API User'
      },
      {
        name: 'api_key',
        type: 'text',
        display_name: 'API Key'
      }
    ]
  },
  getConfig() {
    return {
      name: 'Account Credentials',
      url: 'koala/v1/accounts/credentials'
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
