<template>
  <div v-if="object">
    <h4>Migrated to</h4>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" v-for="(header, key) in headers" :key="key">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" @click="goToAccount(object.id)">
          <td>{{ object.id }}</td>
          <td v-html="scrapable"></td>
          <td>{{ object.platform }}</td>
          <td>{{ object.operator }}</td>
          <td>{{ object.username }}</td>
          <td><AccountStatus :row="object" :status="object.status" /></td>
          <td>{{ object.account_manager_name }}</td>
          <td>
            <div class="switch clickable">
              <input type="checkbox" :id="switchId" v-model="object.active" disabled />
              <label :for="switchId"></label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AccountStatus from '@atoms/table/cell/account-status.vue'

export default {
  components: { AccountStatus },
  props: {
    object: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      headers: [
        'ID',
        'Scrapable',
        'Platform',
        'Operator',
        'Username',
        'Status',
        'Account Manager Name',
        'Enabled',
      ],
    }
  },
  computed: {
    scrapable() {
      if (this.object.scrapable) {
        return `<span class="badge badge-success"><i class="uil uil-check"></i></span>`
      } else {
        return `<span class="badge badge-light"><i class="uil uil-times"></i></span>`
      }
    },
    switchId() {
      return `switch-${this.object.id}`
    },
  },
  methods: {
    goToAccount(id) {
      let routeData = this.$router.resolve({
        path: '/koala-accounts',
        query: { ids: id },
      });
      window.open(routeData.href, '_blank');
    }
  }
}
</script>

<style scoped>
.account-status-badge {
  display: inline;
}
</style>
