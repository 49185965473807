<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>
    <textarea
      v-bind="$attrs"
      :name="label && slugify(label)"
      :value="modelValue"
      class="form-control"
      v-on="listeners"
      ref="textarea"
      :readonly="readOnly && formType && formType === 'edit'"
    ></textarea>
    <small class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    helpText: {
      type: String,
    },
    readOnly: {
      default: () => false,
    },
    formType: {
      default: () => null,
      type: String,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
  },
  methods: {
    getInput() {
      return this.$refs.textarea
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$attrs,
        input: (event) => this.$emit('update:modelValue', event.target.value),
      }
    },
  },
}
</script>
