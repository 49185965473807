import Api from '../Api'

export default {
  getCronjob(data) {
    return Api().get(`gigfarm/v1/cloudflare/cronjob`, {
      params: data
    })
  },
  getDomains() {
    return Api().get(`gigfarm/v1/cloudflare/list/all`)
  },
  exists(data) {
    return Api().post(`gigfarm/v1/cloudflare/exists`, data)
  },
  purge(data) {
    return Api().post(`gigfarm/v1/cloudflare/purge`, data)
  }
}
