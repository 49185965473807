export default {
  getForm() {
    return [
      {
        name: 'site_id',
        type: 'hidden'
      },
      {
        name: 'page_id',
        type: 'hidden'
      },
      {
        name: 'path',
        required: true,
        auto_focus: true,
        type: 'text'
      }
    ]
  },
  getConfig() {
    return {
      url: 'sites/pages',
      name: 'Page',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
