<template>
  <div v-bind:class="containerClass" style="width: 170px">
    <div class="search-input">
      <base-input
        v-debounce:400ms="valueChanged"
        v-model="title"
        :showLabel="false"
        placeholder="Search"
        type="text"
      />
    </div>
  </div>
</template>
<script>
import BaseInput from '@atoms/fields/base-input.vue'
export default {
  watch: {},
  data() {
    return {
      filter: {},
      title: ''
    }
  },
  methods: {
    valueChanged() {
      this.filter['search'] = this.title
      this.$emit('searchFilter', this.filter)
    }
  },
  components: { BaseInput },
  props: {
    containerClass: {
      type: String
    }
  }
}
</script>

<style scoped>
div > div {
  margin-bottom: 0;
}
</style>
