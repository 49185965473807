<template>
  <span :class="statusClass">
    {{ capitalize(statusName) }}
  </span>
</template>

<script>
export default {
  props: {
    status: {},
    color: {},
  },
  computed: {
    statusName() {
      if (this.status === null) {
        return null
      }
      return this.status.replace('_', ' ')
    },
    statusClass() {
      if (this.status === null) {
        return null
      }
      return `operator-status-badge operator-status-${this.status.replace('_', '-')} text-truncate`
    },
  },
}
</script>

<style></style>
