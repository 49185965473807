<template>
  <div class="form-group" :name="label && slugify(label)">
    <label v-if="label">{{ $prettyLabels(label) }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>
    <multi-select
      v-bind="$attrs"
      v-model="data"
      tag-placeholder="Add this as a new value"
      :closeOnSelect="false"
      :label="tagLabel"
      :options="options"
      :multiple="true"
      placeholder="Type to add"
      :taggable="true"
      :disabled="disabled || (readOnly && formType && formType === 'edit')"
      @tag="addTag"
    />
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'
import GeneralService from '@services/GeneralService.js'

export default {
  components: { MultiSelect },
  computed: {
    data: {
      get: function () {
        return this.modelValue
      },
      set: function (value) {
        this.$emit('update:modelValue', value.length === 0 ? null : value)
      },
    },
  },
  data() {
    return {
      options: [],
    }
  },
  mounted() {
    if (this.optionsUrl) {
      GeneralService.fetchItems(this.optionsUrl)
        .then((response) => {
          this.options = response.data.result
        })
        .catch(() => this.showErrorMessage(`Failed to fetch options for ${this.label}`))
    } else {
      this.options = this.manualOptions
    }
  },
  props: {
    tagLabel: {
      type: String,
      default: () => '',
    },
    optionsUrl: {
      type: String,
    },
    manualOptions: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
    helpText: {
      type: String,
    },
    modelValue: {},
    disabled: {
      default: () => false,
    },
    readOnly: {
      default: () => false,
    },
    formType: {
      default: () => null,
      type: String,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
  },
  methods: {
    addTag(newTag) {
      if (!this.modelValue) {
        this.data = []
      }
      newTag.split(',').map((item) => {
        if (item.trim().length > 0) {
          if (!this.modelValue) {
            this.data = [item.trim()]
          } else {
            !this.data.includes(item.trim()) && this.data?.push(item.trim())
          }
        }
      })
    },
  },
}
</script>

<style>
.multiselect__tag {
  color: #47596d !important;
}

.multiselect__tag-icon:hover {
  background: #fc7e7e !important;
}
</style>
