import $ from 'jquery'

const countVisibleModals = () => {
  return $('.modal.show').length
}

const shouldClose = (element) => {
  if (element.find("div[close-modal-inputs='true']").attr('close-modal-inputs')) {
    element.find("div[close-modal-inputs='true']").attr('close-modal-inputs', 'false')
    return false
  }

  if (element.find(':focus').length == 1) {
    const focusElement = element.find(':focus').first()
    if (focusElement.attr('type') !== 'checkbox' && focusElement.attr('type') !== 'number') {
      focusElement.blur()
      return false
    }
  }

  return true
}

export default {
  mounted: (el, binding) => {
    $(el).attr('index', countVisibleModals() + 1)
    // Define Handler and cache it on the element
    const handler = (e) => {
      const keyCode = e.keyCode
      const visibleModals = countVisibleModals()
      const $element = $(el)
      if (keyCode === 27 && shouldClose($element) && visibleModals == $element.attr('index')) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler
    document.addEventListener('keyup', handler)
  },
  unmounted: (el) => {
    document.removeEventListener('keyup', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  },
}
