export default {
  methods: {
    getLabelClasses(status, right = false) {
      let floatRight = ''
      if (right) {
        floatRight = 'float-right'
      }
      switch (status) {
        case 'main':
          return `badge bg-blue ${floatRight}`
        case 'scheduled':
          return `badge bg-orange ${floatRight}`
        case 'active':
          return `badge bg-green ${floatRight}`
        case 'expired':
          return `badge bg-red ${floatRight}`
      }
    }
  }
}
