<template>
  <div>
    <h1>Tracking details</h1>
    <div>
      <base-input
        v-model="uuid"
        :keydownEnter="getDetails"
        :loading="isLoading"
        :autoFocus="true"
        autocomplete="off"
      />
    </div>
    <div class="row" v-if="details">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">Basics</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <dl>
                  <dt>UUID</dt>
                  <dd class="code">
                    {{ details.request.uuid }}
                  </dd>
                  <dt>Date</dt>
                  <dd class="code">
                    {{ $dateTime(details.request.created_at) }}
                    <span class="text-muted ml-2">({{ $dateRelative(details.request.created_at) }})</span>
                  </dd>
                  <dt>Site</dt>
                  <dd class="code">
                    {{ details.request.site_name }}
                  </dd>
                  <dt>Operator</dt>
                  <dd class="code">
                    {{ $prettyLabels(details.request.operator_short_name) }}
                  </dd>
                  <dt>IP</dt>
                  <dd class="code">
                    {{ details.request.ip_address }}
                  </dd>
                  <dt>HTTP Referer</dt>
                  <dd class="code">
                    {{ details.request.http_referer }}
                  </dd>
                </dl>
              </div>
              <div class="col-xl-6">
                <dl>
                  <dt>User UUID</dt>
                  <dd class="code">
                    {{ details.request.user_uuid }}
                  </dd>
                  <dt>Page type</dt>
                  <dd class="code">
                    {{ details.request.page_type }}
                  </dd>
                  <dt>Module</dt>
                  <dd class="code">
                    {{ details.request.module }}
                  </dd>
                  <dt>Market</dt>
                  <dd class="code">
                    {{ details.request.market_short_code }}
                  </dd>
                  <dt>User agent</dt>
                  <dd class="code">
                    {{ details.request.user_agent }}
                  </dd>
                  <dt>URL</dt>
                  <dd class="code text-truncate mr-3" v-if="details.request.request_url">
                    <a :href="details.request.request_url" target="_blank">
                      {{ details.request.request_url }}</a
                    >
                  </dd>
                  <dd v-else>-</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              Conversion uploads <span class="text-muted">({{ details.conversion_uploads.length }})</span>
            </h3>
          </div>
          <div class="card-body">
            <p v-if="details.conversion_uploads.length === 0">No conversion uploads sent</p>
            <table class="table" v-else>
              <tbody>
                <tr class="cursor-pointer" v-for="(row, index) in details.conversion_uploads" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td class="">
                    {{ row.target }}
                  </td>
                  <td class="w-1">
                    <div class="avatar avatar-sm text-white" :class="getActionClass(row.event)">
                      <i :class="getActionIcon(row.event)"></i>
                    </div>
                  </td>
                  <td class="text-muted">{{ row.event }}</td>
                  <td>
                    <div>
                      {{ getConversionAmount(row) }}
                    </div>
                  </td>
                  <td>
                    <div class="font-weight-medium text-nowrap code">
                      <span class="bone">
                        {{ $dateTime(row.event_time) }}
                      </span>
                    </div>
                    <div class="caption">
                      <span class="bone">
                        {{ $dateRelative(row.event_time) }}
                      </span>
                    </div>
                  </td>
                  <td class="w-1">
                    <div v-if="row.fb_events_received === 1">
                      <i class="uil uil-check-circle text-success"></i>
                    </div>
                    <div v-if="row.fb_events_received === 0">
                      <i class="uil uil-crosshair text-danger"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              Postbacks <span class="text-muted">({{ details.postbacks.length }})</span>
            </h3>
          </div>
          <div class="card-body">
            <p v-if="details.postbacks.length === 0">No postbacks found</p>
            <table class="table" v-else>
              <tbody>
                <tr class="cursor-pointer" v-for="(row, index) in details.postbacks" :key="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td class="w-1">
                    <div class="avatar avatar-sm text-white" :class="getActionClass(row.action)">
                      <i :class="getActionIcon(row.action)"></i>
                    </div>
                  </td>
                  <td class="text-muted">{{ row.action }}</td>
                  <td>
                    {{ getPostbackAmount(row) }}
                  </td>
                  <td>
                    <div class="font-weight-medium text-nowrap code">
                      <span class="bone">
                        {{ $dateTime(row.created_at) }}
                      </span>
                    </div>
                    <div class="caption">
                      <span class="bone">
                        {{ $dateRelative(row.created_at) }}
                      </span>
                    </div>
                  </td>
                  <td class="w-1">
                    <div v-if="row.fb_events_received === 1">
                      <i class="uil uil-check-circle text-success"></i>
                    </div>
                    <div v-if="row.fb_events_received === 0">
                      <i class="uil uil-crosshair text-danger"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  data() {
    return {
      uuid: null,
      details: null,
      isLoading: false,
    }
  },
  mounted() {
    if (this.$route.query.uuid) {
      this.uuid = this.$route.query.uuid
      this.getDetails()
    }
    // this.getDetails()
  },
  methods: {
    getPostbackAmount(row) {
      let symbols = {
        EUR: '€',
        USD: '$',
      }
      if (row.platform === 'softswiss') {
        const symbol = symbols[row.data.currency] || ''
        return `${symbol}${row.data.amount}`
      }
    },
    getConversionAmount(row) {
      let symbols = {
        EUR: '€',
        USD: '$',
      }
      if (['nrc', 'click'].includes(row.event)) {
        return ''
      }
      if (row.platform === 'softswiss') {
        const symbol = symbols[row.data.currency] || ''
        return `${symbol}${row.data.amount}`
      }
    },
    getActionClass(action) {
      switch (action) {
        case 'click':
          return 'bg-secondary'
        case 'nrc':
          return 'bg-info'
        case 'ftd':
          return 'bg-success'
        case 'deposit':
          return 'bg-primary'
        default:
          return 'bg-primary'
      }
    },
    getActionIcon(action) {
      switch (action) {
        case 'click':
          return 'uil uil-mouse-alt'
        case 'nrc':
          return 'uil uil-pen'
        case 'ftd':
          return 'uil uil-money-bill'
        case 'deposit':
          return 'uil uil-exchange'
        default:
          return 'uil uil-money-bill'
      }
    },
    getTargetIcon(target) {
      switch (target) {
        case 'facebook':
          return 'uil uil-facebook'
        case 'google':
          return 'uil uil-google'

        default:
          return 'uil uil-question'
      }
    },
    async getDetails() {
      this.isLoading = true
      await this.$http
        .get(`hercules/tracking/debug/${this.uuid}`, {})
        .then((response) => {
          if (response.data.result.request) {
            this.details = response.data.result
            this.$router.replace({ query: { uuid: this.uuid } })
          } else {
            this.showErrorMessage('No tracking details found')
            this.details = null
          }
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
  components: {
    BaseInput,
  },
}
</script>
