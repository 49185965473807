<template>
  <div class="toplist-item-bonus text-truncate">
    <div
      v-if="bonus === null || bonus.format == null || bonus.format === ''"
      class="badge bg-red clickable"
      :class="[row.removed && 'pointer-events-none']"
      @click="bonusEdit"
    >
      <div v-if="isLoading"><i class="uil uil-spin" /> <span class="badge-text">Loading..</span></div>
      <div v-else><i class="uil uil-times" /> <span class="badge-text badge-text"> Not valid</span></div>
    </div>
    <div v-else-if="showFullBonus" @click="bonusEdit" class="badge badge badge-mint clickable text-truncate">
      {{ bonus.format }}
    </div>
    <div v-else class="badge badge-mint clickable" @click="bonusEdit">
      <i class="uil uil-check" /><span class="badge-text"> Valid</span>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isLoading: false
    }
  },
  props: {
    allowBonusTrackerEdit: {
      required: false,
      default: true
    },
    affiliateOperator: {
      required: false
    },
    bonus: {
      required: true
    },
    showFullBonus: {
      type: Boolean,
      default: false
    },
    toplist: {
      required: false
    },
    row: {}
  },
  methods: {
    bonusEdit() {
      if (!this.allowBonusTrackerEdit || this.row.removed || this.isLoading) return
      const affiliateOperatorId = this.affiliateOperator.id
      this.isLoading = true
      this.$http
        .get(`hercules/operators/affiliate-operators/${affiliateOperatorId}/types`, {
          params: { affiliate_operator_id: affiliateOperatorId, name: this.toplist.one_liner }
        })
        .then(response => {
          if (response.data.result.length === 1) {
            return response
          } else {
            return this.$http.post(`hercules/operators/affiliate-operators/${affiliateOperatorId}/types`, {
              affiliate_operator_id: affiliateOperatorId,
              name: this.toplist.one_liner
            })
          }
        })
        .then(response => {
          if (response.data.success) {
            if (Array.isArray(response.data.result)) {
              this.$emit('updateBonus', response.data.result[0])
            } else {
              this.$emit('updateBonus', response.data.result)
            }
          } else {
            this.showErrorMessage('Failed to edit bonus')
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false
          }, 200)
        })
    }
  }
}
</script>
