import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'

const validDirections = ['ltr', 'rtl', 'auto']

function TextDirectionPlugin({ types }) {
  return new Plugin({
    key: new PluginKey('textDirection'),
    appendTransaction: (transactions, oldState, newState) => {
      const docChanges = transactions.some(transaction => transaction.docChanged)
      if (!docChanges) {
        return
      }

      let modified = false
      const tr = newState.tr

      newState.doc.descendants((node, pos) => {
        if (types.includes(node.type.name)) {
          if (node.attrs.dir !== null) {
            return
          }
          tr.setNodeAttribute(pos, 'dir')
          modified = true
        }
      })

      return modified ? tr : null
    }
  })
}

const TextDirection = Extension.create({
  name: 'textDirection',

  addOptions() {
    return {
      types: [],
      defaultDirection: 'ltr'
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          class: {
            parseHTML: element => {
              return element.getAttribute('class')
            },
            renderHTML: attributes => {
              return {
                class: attributes.class
              }
            }
          },
          dir: {
            default: null,
            parseHTML: element => element.dir || this.options.defaultDirection,
            renderHTML: attributes => {
              if (attributes.dir === this.options.defaultDirection) {
                return {}
              }
              return { dir: attributes.dir }
            }
          }
        }
      }
    ]
  },

  addCommands() {
    return {
      setTextDirection: direction => ({ commands }) => {
        if (!validDirections.includes(direction)) {
          return false
        }

        return this.options.types.forEach(type => commands.updateAttributes(type, { dir: direction }))
      },

      unsetTextDirection: () => ({ commands }) => {
        return this.options.types.forEach(type => commands.resetAttributes(type, 'dir'))
      },
      shortcutSetTextDirection: direction => ({ chain }) => {
        return chain()
          .focus()
          .selectAll()
          .setTextDirection(direction)
          .run()
      }
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-l': () => {
        return this.editor.commands.shortcutSetTextDirection('ltr')
      },
      'Mod-Alt-r': () => {
        return this.editor.commands.shortcutSetTextDirection('rtl')
      }
    }
  },

  addProseMirrorPlugins() {
    return [
      TextDirectionPlugin({
        types: this.options.types
      })
    ]
  }
})

export default TextDirection
