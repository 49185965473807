<template>
  <div>{{ formattedDisplayValue }}</div>
</template>

<script>
export default {
  props: {
    value: Number,
    formatValue: Function,
    duration: Number,
  },
  data() {
    return {
      displayValue: 0,
      interval: null,
    }
  },
  computed: {
    formattedDisplayValue() {
      return this.formatValue
        ? this.formatValue(Math.round(this.displayValue))
        : Math.round(this.displayValue)
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.initializeCounter()
      },
    },
  },
  methods: {
    initializeCounter() {
      clearInterval(this.interval)
      this.displayValue = 0
      this.runCounter()
    },
    runCounter() {
      const step = (this.value - this.displayValue) / (this.duration / 10)
      this.interval = setInterval(() => {
        this.displayValue += step
        if ((step > 0 && this.displayValue >= this.value) || (step < 0 && this.displayValue <= this.value)) {
          this.displayValue = this.value
          clearInterval(this.interval)
        }
      }, 10)
    },
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
}
</script>
