<template>
  <div class="d-flex justify-content-between clickable">
    <button
      class="btn  btn-secondary icon-button clickable"
      @click="click(true)"
      :disabled="row[field] === 0"
    >
      <i class="uil uil-sort-amount-up clickable" />
    </button>
    <button disabled class="btn btn-info" style="background-color: #fffbf7">
      {{ row[field] }}
    </button>
    <button class="btn  btn-secondary icon-button clickable" @click="click(false)">
      <i class="uil uil-sort-amount-down clickable" />
    </button>
    <span></span>
  </div>
</template>

<script>
export default {
  props: {
    row: {},
    field: {}
  },
  methods: {
    click(up = true) {
      this.$emit(
        'columnClick',
        this.row.id,
        this.field,
        up ? this.row[this.field] - 1 : this.row[this.field] + 1
      )
    }
  }
}
</script>
