import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/sites/pages`, {
      params: data
    })
  },
  getOne(id, data) {
    return Api().get(`hercules/sites/pages/${id}`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/sites/pages`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/pages/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/pages/${row.id}`)
  },
  fetchStructure() {
    return Api().get(`hercules/sites/pages/structure`)
  },
  unlinkPage(pageId, sourcePageId) {
    return Api().delete(`hercules/sites/page-hreflang-groups/unlink-page/${pageId}?source_page_id=${sourcePageId}`)
  },
  linkPages(source, target) {
    return Api().post(`hercules/sites/page-hreflang-groups/link-pages`, {
      source_page_id: source,
      target_page_id: target
    })
  },
  predictPageGroup(data) {
    return Api().get(`hercules/sites/page-hreflang-groups/predict-page`, {
      params: data
    })
  },
  createPageGroup(data = {}) {
    return Api().post(`hercules/sites/page-hreflang-groups`, data)
  }
}
