export default {
  computed: {
    advancedFilters() {
      return this.$store.state['data-table'] && this.$store.state['data-table'].advancedFilters
    },
    platforms() {
      return this.advancedFilters && this.advancedFilters.platforms
    },
    operators() {
      return this.advancedFilters && this.advancedFilters.operators
    },
    computedPath() {
      const advancedFilters = this.advancedFilters || {}
      const queryParams = [
        advancedFilters.account_manager_name
          ? `account_manager_name=${advancedFilters.account_manager_name}`
          : '',
        advancedFilters.business_units ? `business_units=${advancedFilters.business_units}` : '',
        advancedFilters.currencies ? `currencies=${advancedFilters.currencies}` : '',
        advancedFilters.finance_id ? `finance_id=${advancedFilters.finance_id}` : '',
        advancedFilters.operator_base_url ? `operator_base_url=${advancedFilters.operator_base_url}` : '',
        advancedFilters.operators ? `operators=${advancedFilters.operators}` : '',
        advancedFilters.platforms ? `platforms=${advancedFilters.platforms}` : '',
        advancedFilters.proxies ? `proxies=${advancedFilters.proxies}` : '',
        advancedFilters.username ? `username=${advancedFilters.username}` : ''
      ]
        .filter(Boolean)
        .join('&')

      const platformId = this.platforms ? `platforms=${this.platforms}` : ''

      return {
        operatorsPath: `koala/v1/operators?${platformId}&orderBy=name&ascending=1&limit=100`,
        reportsPath: `koala/v1/reports?${platformId}&orderBy=platform.name&ascending=1&limit=100`,
        accountsPath: `koala/v1/accounts?${queryParams}&orderBy=operator.name&ascending=1&limit=100`
      }
    }
  }
}
