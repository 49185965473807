<template>

  <data-table
    service-file="TemplatesService"
    path="hercules/sports/tournaments?with=country,sport"
    :columns="columns()"
    :enableRowClick="false"
    :show-remove="false"
    :show-create="false"
  />

</template>

<script>
export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'name', type: 'text', filterable: true },
        { field: 'gender', type: 'text', filterable: true },
        {
          field: 'country.name',
          search_key: 'country__name',
          order_key: 'country__name',
          label: 'Country',
          filterable: true
        },
        {
          field: 'sport.name',
          search_key: 'sport__name',
          order_key: 'sport__name',
          label: 'Sport',
          filterable: true
        }
      ]
    }
  }
}
</script>

