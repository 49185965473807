<template>
  <div>
    <data-table
      model="koala/Proxies"
      service-file="koala/ProxiesService"
      :path="path()"
      permissionPath="koala/v1/proxies"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      ref="table"
      :tableLimits="[10, 50, 100]"
    />
  </div>
</template>
    
<script>
export default {
  created() {
    this.setTitle()
  },
  components: {},
  methods: {
    columns() {
      return [
        {
          field: 'name',
          type: 'text',
          search_key: 'name',
          filterable: true,
          sortable: false,
          advancedFilter: true,
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: false
        },
        { field: 'username', type: 'text', filterable: false, sortable: false, order_key: 'name' },
        {
          field: 'ip_address',
          displayName: 'IP Address',
          type: 'text',
          filterable: false,
          order_key: 'ip'
        },
        {
          field: 'protocol',
          type: 'text'
        },
        {
          field: 'port',
          type: 'number'
        }
      ]
    },
    columnTemplates() {
      // const self = this
      return Object.assign({
        username: function (row) {
          return row.credentials.username
        },
        ip_address: function (row) {
          return row.ip
        },
      })
    },

    path() {
      return `koala/v1/proxies`
    },
  },
}
</script>
    