<template>
  <div class="w-100 d-flex flex-wrap justify-content-between pb-0">
    <table-search
      :stateName="stateName"
      :enableAdvancedSearch="enableAdvancedSearch"
      :enableQuickSearch="enableQuickSearch"
    />
    <div class="d-flex" :class="[isKoalaCampaign ? 'justify-content-end w-100' : '']">
      <table-limit :stateName="stateName" v-if="!basicHeader" :tableLimits="tableLimits" />
      <table-actions
        :basicHeader="basicHeader"
        :stateName="stateName"
        :columns="columns"
        :totalRows="totalRows"
        :customActionButton="customActionButton"
        :customSecondaryButton="customSecondaryButton"
        @bulkEditClick="$emit('bulkEditClick')"
        @createClick="$emit('createClick')"
      />
    </div>
  </div>
</template>

<script>
import TableSearch from '@atoms/table/table-search.vue'
import TableLimit from '@atoms/table/table-limits.vue'
import TableActions from '@atoms/table/table-actions.vue'

export default {
  components: {
    TableSearch,
    TableLimit,
    TableActions,
  },
  computed: {
    isKoalaCampaign() {
      return this.$route && this.$route.path === '/koala-campaigns'
    },
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    basicHeader: {
      type: Boolean,
      required: true,
    },
    stateName: {
      type: String,
      required: true,
    },
    customActionButton: {
      type: Object,
      default: null,
    },
    customSecondaryButton: {
      type: Object,
      default: null,
    },
    totalRows: {
      required: true,
    },
    enableAdvancedSearch: {
      type: Boolean,
      default: () => true,
    },
    enableQuickSearch: {
      type: Boolean,
      default: () => true,
    },
    tableLimits: {
      required: false,
      default: () => [10, 100, 1000],
    },
  },
}
</script>
