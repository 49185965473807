export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        slug_field: 'short_name'
      },
      {
        name: 'short_name',
        required: true,
        display_name: 'Short Name',
        type: 'slug',
        read_only: false
      },
      {
        name: 'logo_asset_id',
        required: false,
        display_name: 'Logo',
        help_text: 'Standardised logo',
        type: 'base_media',
        substitute: 'image',
        permission: 'misc.cryptos/manage-standardised-logos',
        standardised: true
      },
      {
        name: 'url',
        required: false,
        display_name: 'URL',
        type: 'text'
      },
      {
        name: 'founded',
        required: false,
        display_name: 'Founded Date',
        type: 'date',
        read_only: false
      },
      {
        name: 'licences',
        required: false,
        display_name: 'Crypto Licences',
        type: 'list-many',
        search_url: 'hercules/cryptos/licences',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'headquarters',
        required: false,
        display_name: 'Headquarters',
        type: 'text'
      },
      {
        name: 'rating',
        required: false,
        display_name: 'Ratings',
        type: 'average_number',
        inputs: ['rating_security', 'rating_fees', 'rating_usability', 'rating_features', 'rating_support'],
        average_input: 'rating',
        min: 0,
        max: 5,
        step: 0.1
      },
      {
        name: 'selling_points',
        required: false,
        display_name: 'Selling Points',
        type: 'list-many',
        search_url: 'hercules/globals/selling-points',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'exchange_type_id',
        search_url: 'hercules/cryptos/exchange-types',
        required: false,
        display_name: 'Type of exchange',
        type: 'list-one',
        value_key: 'exchangeType.type',
        trackBy: 'id',
        selectLabel: 'type'
      },
      {
        name: 'restricted_countries',
        required: false,
        display_name: 'Restricted Countries',
        type: 'list-many-nested-checkbox',
        search_url: 'hercules/globals/countries?with=children&hide_children=0&with_no_assets=1',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'crypto_currencies',
        required: false,
        display_name: 'Crypto Currencies',
        type: 'list-many',
        search_url: 'hercules/globals/currencies?crypto=1&with_no_assets=1',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'currencies_amount',
        required: false,
        display_name: 'Crypto Currencies Amount',
        type: 'text'
      },
      {
        name: 'fiat_currencies',
        required: false,
        display_name: 'Fiat Currencies',
        type: 'list-many',
        search_url: 'hercules/globals/currencies?crypto=0&with_no_assets=1',
        selectLabel: 'name',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'support_types',
        search_url: 'hercules/globals/support-types',
        display_name: 'Support Types',
        display_field: 'type',
        selectLabel: 'type',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'services',
        search_url: 'hercules/cryptos/services',
        display_name: 'Products and services',
        display_field: 'name',
        selectLabel: 'name',
        type: 'list-many',
        fetch_on_load: false,
        validate_changes: true
      },
      {
        name: 'markets_amount',
        required: false,
        display_name: 'Markets amount',
        type: 'text'
      },
      {
        name: 'best_for',
        required: false,
        display_name: 'Best for',
        type: 'text'
      }
    ]
  },
  getConfig() {
    return {
      url: 'cryptos/exchanges',
      name: 'Crypto Exchange',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
