<template>
  <div class="col">
    <div class="card">
      <div class="card-header font-weight-bold" v-html="findFieldByName('faq_schema_item_question').value" />
      <div class="card-body" v-html="findFieldByName('faq_schema_item_answer').value"></div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    findFieldByName(name) {
      return this.moduleField.children.find(item => item.field.name === name)
    }
  },
  props: {
    fieldSpecificAttributes: {},
    moduleField: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.spotlight-img {
  max-height: 100px;
}
.spotlight-img-icon {
  max-height: 60px;
}
</style>
