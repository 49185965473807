<template>
  <span class="badge badge-success" v-if="value">
    <i class="uil uil-check"></i>
  </span>
  <span v-else class="badge badge-light">
    <i class="uil uil-times"></i>
  </span>
</template>

<script>
export default {
  props: {
    value: {}
  }
}
</script>
