export default {
  getForm() {
    return [
      {
        name: 'rating',
        type: 'number',
        min: 0,
        max: 5,
        step: 0.5
      },
      {
        name: 'price',
        type: 'text'
      },
      {
        name: 'selling_point_1',
        type: 'text',
        help_text: 'Visible on review page'
      },
      {
        name: 'selling_point_2',
        type: 'text',
        help_text: 'Visible on review page'
      },
      {
        name: 'selling_point_3',
        type: 'text',
        help_text: 'Visible on review page'
      },
      {
        name: 'bonus',
        type: 'text',
        help_text: 'Visible on review page'
      },
      {
        name: 'main',
        type: 'checkbox'
      },
      {
        name: 'enabled',
        type: 'checkbox'
      }
    ]
  },
  getConfig() {
    return {
      url: 'sports/site-providers',
      name: 'Provider',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
