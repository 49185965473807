<template>
  <div class="switch clickable">
    <input type="checkbox" :id="switchId" v-model="checked" @click="click" />
    <label :for="switchId"></label>
  </div>
</template>

<script>
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
const confirmDelete = async (title, content, actionButtonTitle, actionButtonIconClass, actionButtonClass) => {
  return await openDialog(ConfirmDialog, {
    title,
    content,
    actionButtonTitle,
    actionButtonIconClass,
    actionButtonClass,
  })
}

export default {
  data() {
    return { checked: !!this.row[this.field] }
  },
  props: {
    row: {},
    field: {},
    confirmMessage: {},
    confirmTitle: {},
    confirmButtonTitle: {},
    confirmButtonIconClass: {},
    confirmButtonClass: {},
  },
  updated() {
    this.checked = !!this.row[this.field]
  },
  computed: {
    switchId() {
      return `${this.field}__${this.row.id}`
    },
  },
  methods: {
    click(event) {
      if (this.confirmMessage) {
        event.preventDefault()
        this.confirmClick()
      } else {
        this.$emit('columnClick', this.row.id, this.field, !this.row[this.field])
      }
    },
    async confirmClick() {
      if (
        await confirmDelete(
          this.confirmTitle,
          this.confirmMessage,
          this.confirmButtonTitle,
          this.confirmButtonIconClass,
          this.confirmButtonClass
        )
      ) {
        this.$emit('columnClick', this.row.id, this.field, !this.row[this.field])
      }
    },
  },
}
</script>
