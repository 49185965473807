<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Open different market</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body" v-if="items">
          <div class="callout callout-info">
            You are currently editing <strong>{{ list.market.label }}.</strong> This top list is available in
            <strong>{{ items.length }}</strong> other market(s)
          </div>
          <ul class="list-group">
            <li
              class="list-group-item d-flex align-items-center justify-content-between border rounded"
              v-for="item in items"
              :key="item.id"
              :class="[item.id === list.id && 'active']"
              @click="item;"
            >
              <div class="d-flex-column">
                <div>
                  {{ item.market.label }} <span class="text-muted ml-1">({{ item.items_count }})</span>
                </div>
                <div v-if="item.scheduled_at" class="text-warning font-size-sm">
                  Scheduled {{ $dateRelative(item.scheduled_at) }} ({{ $dateTime(item.scheduled_at) }})
                </div>
              </div>

              <button class="btn btn-primary btn-sm on-hover" v-if="item.id !== list.id" @click="click(item)">
                <i class="uil uil-external-link-alt"></i> Open in new tab
              </button>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary mr-2" @click="close">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
export default {
  components: { BaseCheckbox },
  props: {
    list: {
      required: true,
    },
  },
  data() {
    return {
      items: null,
    }
  },
  mounted() {},
  methods: {
    show() {
      this.$refs.modal.classList.add('show')
      this.getMarkets()
    },
    close() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
    getMarkets() {
      this.$http
        .get('hercules/toplists/lists', {
          params: {
            affiliate_id: this.list.affiliate_id,
            short_code: this.list.short_code,
            type: this.list.type,
            tracker: this.list.tracker,
            one_liner: this.list.one_liner,
            with_count: 'items',
            with: 'market',
            orderBy: 'market_name',
            ascending: 1,
            exact_match: 'short_code',
          },
        })
        .then((response) => {
          this.items = response.data.result.filter((item) => item.id !== this.list.id)
        })
    },
    click(list) {
      window.open(`/toplists/${list.id}/items`, '_blank')
    },
  },
}
</script>
