import { store } from '@store/index'

export default {
  data() {
    return {
      userId: null,
      showColaborativeNotification: false,
      username: '',
    }
  },
  mounted() {
    let pusherChannel = window.pusher.subscribe('updateModelChannel')

    pusherChannel.bind('pusher:subscription_succeeded', function () {})

    pusherChannel.bind('updateModel', (message) => {
      if (
        message.modelName === this.modelName &&
        Number(message.modelId) === Number(this.$route.params.id) &&
        message.pusherToken !== store.state.app.uniqueKey
      ) {
        this.username = message.username
        this.showColaborativeNotification = true
      }
    })
  },
}
