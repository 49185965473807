<template>
  <div class="d-flex bubble-menu-icons shadow-sm">
    <popper hover arrow placement="top" v-if="editor.isActive('link')">
      <template #content>
        <span class="popper">Edit Link</span>
      </template>
      <button id="editLink" @click.prevent="$emit('setLink')" slot="reference" class="font-weight-semi-bold">
        Edit Link
      </button>
    </popper>

    <popper hover arrow placement="top" v-if="editor.getAttributes('paragraph')['data-anchor-label']">
      <template #content>
        <span class="popper">Edit Anchor</span>
      </template>
      <button
        id="editAnchor"
        @click.prevent="$emit('setAnchor')"
        class="font-weight-semi-bold"
        :class="{
          'is-active':
            editor.getAttributes('paragraph')['data-anchor-label'] ||
            editor.getAttributes('heading')['data-anchor-label'],
        }"
        slot="reference"
      >
        Edit Anchor
      </button>
    </popper>
    <popper hover arrow placement="top" v-if="editor.isActive('link')">
      <template #content>
        <span class="popper">Open link in new tab</span>
      </template>
      <button
        id="openLink"
        @click.prevent="openAnchorLink"
        slot="reference"
        :class="{ 'is-active': editor.isActive('link') }"
      >
        <i class="uil uil-external-link-alt"></i>
      </button>
    </popper>

    <popper hover arrow placement="top" v-if="editor.isActive('link')">
      <template #content>
        <span class="popper">Unlink</span>
      </template>

      <button id="unlink" slot="reference" @click.prevent="editor.chain().focus().unsetLink().run()">
        <i class="uil uil-link-h cross-through"></i>
      </button>
    </popper>

    <popper hover arrow placement="top" v-if="editor.getAttributes('paragraph')['data-anchor-label']">
      <template #content>
        <span class="popper">Unset Anchor</span>
      </template>

      <button
        id="unsetAnchor"
        slot="reference"
        @click.prevent="
          editor
            .chain()
            .focus()
            .resetAttributes(editor.isActive('heading') ? 'heading' : 'paragraph', [
              'id',
              'data-anchor-label',
            ])
            .run()
        "
        :class="{ 'is-active': isAnchor }"
      >
        <i class="uil uil-anchor cross-through"></i>
      </button>
    </popper>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select.vue'

export default {
  components: { BaseSelect },
  props: {
    editor: {},
    required: true,
    canOpenAnchorLink: {
      type: Boolean,
      default: false,
    },
    isAnchor: {
      type: Boolean,
      default: false,
    },
    selectedText: {
      type: String,
      default: '',
    },
  },
  methods: {
    async openAnchorLink() {
      const link = this.editor.isActive('figure') ? 'figure' : 'link'
      const pageId = this.editor.getAttributes(link).pageId
      const href = this.editor.getAttributes(link).href
      if (pageId && pageId !== 'undefined') {
        if (this.$route.fullPath.includes('/site-pages/')) {
          window.open(`${pageId}`, '_blank')
        } else {
          window.open(`/site-pages/${pageId}`, '_blank')
        }
      } else {
        window.open(href, '_blank')
      }
    },
  },
}
</script>
