export default {
  getForm() {
    return [
      {
        name: 'site_id',
        search_url: 'hercules/sites/sites',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'site.name'
      },
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        help_text: 'Maximum 100 characters',
        maxlength: 100
      },
    ]
  },
  getConfig() {
    return {
      url: 'sites/authors',
      name: 'Author',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
