<template>
  <data-table
    model="Ribbons"
    service-file="RibbonsService"
    :path="path()"
    :columns="columns()"
    :create-defaults="createDefaults()"
    ref="list"
  />
</template>

<script>
export default {
  created() {
    this.setTitle()
  },
  methods: {
    path() {
       return `hercules/globals/ribbons?with=language&site_id=${this.$route.params['id']}`
    },
    columns() {
      return [
        { field: 'label', type: 'text', filterable: true },
        { field: 'language.name', type: 'text', filterable: true },
      ]
    },
    createDefaults() {
      return {
       site_id: parseInt(this.$route.params['id'])
      }
    }
  }
}
</script>
