<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <base-input label="Character count" v-model="charCountTitle" :disabled="true" class="w-49" />
      <base-input label="Pixel length" v-model="charCountTitleWidth" :disabled="true" class="w-49" />
    </div>
    <div class="progress mt-2">
      <div class="progress-bar" :class="getProggresColor()" :data-progress="percentage"></div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
export default {
  components: {
    BaseInput
  },
  data() {
    return {
      value: 0,
      percentage: 0,
      charCountMetaTitle: 0,
      charCountMetaDescription: 0,
      titleLength: 0,
      seoFriendly: true,
      widthsMap: {
        arial: {
          '0': [55.63, 55.63, 55.63, 55.63],
          '1': [55.63, 55.63, 55.63, 55.63],
          '2': [55.63, 55.63, 55.63, 55.63],
          '3': [55.63, 55.63, 55.63, 55.63],
          '4': [55.63, 55.63, 55.63, 55.63],
          '5': [55.63, 55.63, 55.63, 55.63],
          '6': [55.63, 55.63, 55.63, 55.63],
          '7': [55.63, 55.63, 55.63, 55.63],
          '8': [55.63, 55.63, 55.63, 55.63],
          '9': [55.63, 55.63, 55.63, 55.63],
          ' ': [27.8, 24.16, 24.16, 24.16],
          '!': [27.8, 33.31, 27.8, 33],
          '"': [35, 47, 35, 47],
          '#': [55.63, 55.63, 55.63, 55.63],
          $: [55.63, 55.63, 55.63, 55.63],
          '%': [88.92, 88.92, 88.92, 88.92],
          '&': [66.7, 72.22, 66.7, 72],
          "'": [19, 24, 19, 24],
          '(': [33.31, 33.31, 33.31, 33],
          ')': [33.31, 33.31, 33.31, 33],
          '*': [38.92, 38.92, 38.92, 39],
          '+': [58.41, 58.41, 58.41, 58.41],
          ',': [27.8, 27.8, 27.8, 27.8],
          '-': [33.31, 33.31, 33.31, 33],
          '.': [27.8, 27.8, 27.8, 27.8],
          '/': [27.8, 27.8, 27.8, 27.8],
          ':': [27.8, 33.31, 27.8, 33],
          ';': [27.8, 33.31, 27.8, 33],
          '<': [58.41, 58.41, 58.41, 58.41],
          '=': [58.41, 58.41, 58.41, 58.41],
          '>': [58.41, 58.41, 58.41, 58.41],
          '?': [55.63, 61, 55.63, 61],
          '@': [102, 98, 102, 98],
          A: [66.7, 72.22, 66.7, 72],
          B: [66.7, 72.22, 66.7, 72],
          C: [72.22, 72.22, 72.22, 72],
          D: [72.22, 72.22, 72.22, 72],
          E: [66.7, 66.7, 66.7, 66.7],
          F: [61, 61, 61, 61],
          G: [78, 78, 78, 78],
          H: [72.22, 72.22, 72.22, 72],
          I: [27.8, 27.8, 27.8, 27.8],
          J: [50, 55.63, 50, 55.63],
          K: [66.7, 72.22, 66.7, 72],
          L: [55.63, 61, 55.63, 61],
          M: [83.31, 83.31, 83.31, 83.31],
          N: [72.22, 72.22, 72.22, 72],
          O: [77.8, 77.8, 77.8, 77.8],
          P: [66.7, 66.7, 66.7, 66.7],
          Q: [77.8, 77.8, 77.8, 77.8],
          R: [72.22, 72.22, 72.22, 72],
          S: [66.7, 66.7, 66.7, 66.7],
          T: [61.09, 61, 61, 61],
          U: [72.22, 72.22, 72.22, 72],
          V: [66.7, 66.7, 66.7, 66.7],
          W: [94.39, 94, 94, 94],
          X: [66.7, 66.7, 66.7, 66.7],
          Y: [66.7, 66.7, 66.7, 66.7],
          Z: [61.09, 61, 61, 61],
          '[': [27.8, 33.31, 27.8, 33],
          '\\': [27.8, 27.8, 27.8, 27.8],
          ']': [27.8, 33.31, 27.8, 33],
          '^': [46.94, 58.41, 47, 58.41],
          _: [55.63, 55.63, 55.63, 55.63],
          '`': [33.31, 33.31, 33.31, 33],
          a: [55.63, 55.63, 55.63, 55.63],
          b: [55.63, 61, 55.63, 61],
          c: [50, 55.63, 50, 55.63],
          d: [55.63, 61, 55.63, 61],
          e: [55.63, 55.63, 55.63, 55.63],
          f: [27.8, 33.31, 27.8, 33],
          g: [55.63, 61, 55.63, 61],
          h: [55.63, 61, 55.63, 61],
          i: [22.22, 27.8, 22, 27.8],
          j: [22.22, 27.8, 22, 27.8],
          k: [50, 55.63, 50, 55.63],
          l: [22.22, 27.8, 22, 27.8],
          m: [83.31, 88.92, 83, 88.92],
          n: [55.63, 61, 55.63, 61],
          o: [55.63, 61, 55.63, 61],
          p: [55.63, 61, 55.63, 61],
          q: [55.63, 61, 55.63, 61],
          r: [33.31, 38.92, 33.31, 39],
          s: [50, 55.63, 50, 55.63],
          t: [27.8, 33.31, 27.8, 33],
          u: [55.63, 61, 55.63, 61],
          v: [50, 55.63, 50, 55.63],
          w: [72.22, 78, 72.22, 78],
          x: [50, 55.63, 50, 55.63],
          y: [50, 55.63, 50, 55.63],
          z: [50, 50, 50, 50],
          '{': [33.31, 38.92, 33.31, 39],
          '|': [25.98, 27.8, 26, 27.8],
          '}': [33.31, 38.92, 33.31, 39],
          '~': [58.41, 58.41, 58.41, 58.41]
        }
      }
    }
  },
  props: {
    relationalData: {
      type: Object,
      required: false
    },
    maxWidth: {
      type: Number,
      required: true
    },
    maxCharacters: {
      type: Number,
      required: true
    },
    meta: {
      type: String,
      required: false
    },
    lang: {
      type: String,
      required: true
    },
    fontSize: {
      type: Number,
      required: true
    }
  },
  computed: {
    charCountTitle() {
      return `${this.titleLength} / ${this.maxCharacters}`
    },
    charCountTitleWidth() {
      return `${this.value}px / ${this.maxWidth}px`
    }
  },
  methods: {
    getWidth(string, settings) {
      var font = settings.font.toLowerCase()
      var size = settings.size
      var variant = 0
      var map = settings.map
      var available = Object.keys(map)
      if (available.indexOf(font) === -1) {
        throw new Error('This font is not supported. Supported fonts are: ' + available.join(', '))
      }
      var totalWidth = 0
      string.split('').forEach(function(char) {
        let width = 0
        if (/[\x00-\x1F]/.test(char)) {
          // non-printable character
          return true
        } else if (
          /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/.test(char)
        ) {
          width = 100
        } else {
          let widths = map[font][char] || map[font].x
          width = widths[variant]
        }
        totalWidth += width
        return true
      })
      return totalWidth * (size / 100)
    },
    checkWidth() {
      let title = this.meta || ''

      if (this.meta && this.relationalData) {
        title = this.replaceRelationData(title)
      }
      this.value = parseInt(this.getWidth(title, { size: this.fontSize, font: 'arial', map: this.widthsMap }))
      this.titleLength = title.length
      let charactersPercentage = (this.titleLength / this.maxCharacters) * 100
      let widthPercentage = (this.value / this.maxWidth) * 100
      if (charactersPercentage > 100 || widthPercentage > 100) {
        this.percentage = 100
        this.seoFriendly = false
      } else {
        this.percentage =
          charactersPercentage > widthPercentage ? parseInt(charactersPercentage) : parseInt(widthPercentage)
        this.seoFriendly = true
      }
      this.updateValue(this.value)
      this.$emit('formatMeta', title)
    },
    replaceRelationData(title) {
      let home_team = ''
      let away_team = ''

      this.relationalData.event.home_team.locales.forEach(
        function(e) {
          if (e.code === this.lang) {
            home_team = e.label
          }
        },
        this,
        home_team
      )

      this.relationalData.event.away_team.locales.forEach(
        function(e) {
          if (e.code === this.lang) {
            away_team = e.label
          }
        },
        this,
        away_team
      )

      title = title.replaceAll('{home_team}', home_team)
      title = title.replaceAll('{away_team}', away_team)
      title = title.replaceAll('{sport}', this.relationalData.event.sport.name)
      title = title.replaceAll('{time}', this.relationalData.event.time)
      title = title.replaceAll('{date}', this.relationalData.event.date)

      return title
    },
    getProggresColor() {
      if (!this.seoFriendly) {
        return 'bg-danger'
      } else if (this.percentage > 70) {
        return 'bg-success'
      } else if (this.percentage > 30) {
        return 'bg-warning'
      } else {
        return 'bg-danger'
      }
    },
    updateValue: function(value) {
      this.$emit('input', value)
    }
  },
  mounted() {
    this.checkWidth()
  },
  watch: {
    meta() {
      this.checkWidth()
    },
    relationalData() {
      this.checkWidth()
    }
  }
}
</script>
