<template>

  <div :class="alignmentClass">

    <async-image
      :id="this.findFieldByName('image_value').value"
      class="image-fluid image-module-img"
      :title="this.findFieldByName('image_title').value"
      :alt="this.findFieldByName('image_alt').value"
    />

  </div>

</template>

<script>
import AsyncImage from '@atoms/cms/modules/preview/async-image.vue'

export default {
  components: { AsyncImage },
  data() {
    return {
      cards: []
    }
  },
  methods: {
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find(item => item.field.name === name)
    }
  },
  computed: {
    alignmentClass() {
      const alignment = this.findFieldByName('image_alignment').value
      if (alignment === 'align_left') return 'text-left'
      if (alignment === 'align_center') return 'text-center'
      if (alignment === 'align_right') return 'text-right'
      return ''
    }
  },
  props: {
    fieldSpecificAttributes: {},
    pageModule: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.image-module-img {
  max-height: 400px;
}
</style>

