<template>
  <node-view-wrapper
    v-bind="attributes"
    as="img"
    ref="image"
    :class="[selected && 'border', node.attrs.style]"
  />
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import interact from 'interactjs'

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },
  data() {
    return {
      test: false,
      width: this.node.attrs.resizedWidth,
      height: this.node.attrs.resizedHeight,
      alt: this.node.attrs.alt,
      title: this.node.attrs.title,
    }
  },
  props: nodeViewProps,
  mounted() {
    if (this.selected === false) {
      return
    }
  },
  watch: {
    selected() {
      if (this.selected) {
        this.$nextTick(() => {
          interact(this.$refs.image && this.$refs.image.$el).resizable({
            edges: { top: true, left: true, bottom: true, right: true },
            preserveAspectRatio: true,
            modifiers: [
              interact.modifiers.aspectRatio({
                ratio: 'preserve',
              }),
            ],
            listeners: {
              move: (event) => {
                this.updateAttributes({
                  resizedWidth: Math.round(event.rect.width),
                  resizedHeight: Math.round(event.rect.height),
                })
              },
            },
          })
        })
      }
    },
  },
  computed: {
    attributes() {
      return {
        dir: this.node?.attrs?.dir,
        src: this.node.attrs.src,
        height: 'auto',
        width: this.node?.attrs?.resizedWidth,
        'data-height': this.node.attrs.originalHeight,
        'data-width': this.node.attrs.originalWidth,
        alt: this.node?.attrs?.alt,
        title: this.node?.attrs?.title,
      }
    },
  },
}
</script>

<style lang="scss">
img {
  max-width: calc(100% - 10px);
}
</style>
