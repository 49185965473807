<template>
  <div>
    <span v-for="(item, key) in list" v-bind:key="key" :class="`badge ${bgColor} m-1`">{{
      item[columnKey]
    }}</span>
    <span v-if="items.length > limit" :class="`badge ${bgColor} m-1`">...</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: ''
    }
  },
  props: {
    items: {},
    columnKey: {
      type: String,
      default: 'id'
    },
    limit: {
      type: Number,
      default: 10
    },
    bgColor: {
      type: String,
      default: 'bg-azure'
    }
  },
  computed: {
    list() {
      return this.limit ? this.items.slice(0, this.limit) : this.items
    }
  }
}
</script>
