<template>
  <div>
    <ul>
      <li
        class="clickable pb-3"
        v-for="(anchor, index) in this.pageModule.module_value_fields"
        :key="index"
        v-scroll-to="{ element: `#${anchor.slug}`, duration: 500, offset: -100 }"
      >
        <span>{{ anchor.anchor_label }}</span>
        <span
          class="badge ml-3"
          v-bind:class="{
            'bg-purple-25': anchor.type === 'content',
            'bg-blue-25': anchor.type === 'module'
          }"
          style="height: fit-content"
          >{{ anchor.type }}</span
        >
      </li>
    </ul>
    <div class="text-muted">
      This module is generated automatically, please save the changes to see the updated anchors
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find(item => item.field.name === name)
    }
  },
  props: {
    fieldSpecificAttributes: {},
    pageModule: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
li:hover {
  color: #1e1c62;
}
</style>
