import Api from './Api'

export default {
  getFilters(data) {
    return Api().get(`hercules/sites/pages/filters`, {
      params: data
    })
  },
  get(data) {
    return Api().get(`hercules/sites/pages`, {
      params: data
    })
  },
  getOne(id, data) {
    return Api().get(`hercules/sites/pages/${id}`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/sites/pages`, data)
  },
  bulkUpdate(data) {
    return Api().put('hercules/sites/pages/bulk', data)
  },
  bulkRemove(data) {
    return Api().delete('hercules/sites/pages/bulk', {
      params: data
    })
  },
  clone(data) {
    return Api().post(`hercules/sites/pages/clone`, data)
  },
  changePageType(data) {
    return Api().post(`hercules/sites/pages/change-page-type`, data)
  },
  changePagePath(data) {
    return Api().put(`hercules/sites/pages/change-page-path`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/pages/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/pages/${row.id}`)
  },
  fetchStructure() {
    return Api().get(`hercules/sites/pages/structure`)
  },
  rebuildSportsPage(data) {
    return Api().get(`hercules/sites/commands/create-event-pages`, {
      params: data
    })
  }
}
