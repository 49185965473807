<template>
  <span :class="`badge ${badgeColor}`">{{ $prettyLabels(status) }}</span>
</template>

<script>
export default {
  props: {
    status: {},
  },
  computed: {
    badgeColor() {
      switch (this.status) {
        case 'event_details':
          return 'bg-blue'
        case 'tournament_standings':
          return 'bg-azure'
        case 'round_schedule':
          return 'bg-purple'
      }
      return 'bg-white-25'
    },
  },
}
</script>
