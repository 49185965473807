export default {
  getForm() {
    return [
      {
        name: 'affiliate_id',
        search_url: 'hercules/operators/affiliates',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'website',
        value_key: 'site_name',
        notify_fields: [
          { field: 'toplist_shortcode', param: 'affiliate_id' },
          { field: 'toplist_id', param: 'affiliate_id' }
        ]
      },
      {
        name: 'geo_located',
        type: 'checkbox',
        display_name: 'Enabled',
        display_name_two: 'Geo targeted',
        switch: true
      },
      {
        name: 'toplist_shortcode',
        search_url: `hercules/toplists/lists/distinct-shortcode?type=sports`,
        required: true,
        display_name: 'Toplist Shortcode',
        type: 'list-one',
        notified_by: { field: 'affiliate_id', label: 'Please select a site first.' },
        show_if: { field: 'geo_located', value: true },
        trackBy: 'short_code',
        selectLabel: 'short_code',
        optionLabelPrettier: false
      },
      {
        name: 'toplist_id',
        display_name: 'Toplist Name',
        type: 'model_select',
        multiple: false,
        required: true,
        model: 'top_list',
        value_label: 'toplist_name',
        notified_by: { field: 'affiliate_id', label: 'Please select a site first.' },
        show_if_not: { field: 'geo_located', value: true }
      },
      {
        name: 'title',
        type: 'text',
        help_text: 'Description of the widget'
      },
      {
        name: 'sport_id',
        display_name: 'Sport',
        search_url: `hercules/sports/sports?active=1`,
        required: true,
        type: 'list-one',
        trackBy: 'id',
        selectLabel: 'name',
        notify_fields: [
          { field: 'event_ids', param: 'sport_id' },
          { field: 'tournament_ids', param: 'sport_id' }
        ]
      },
      {
        name: 'type_id',
        display_name: 'Type',
        search_url: `hercules/widgets/odds-types`,
        required: true,
        type: 'list-one',
        trackBy: 'id',
        selectLabel: 'name',
        notify_fields: [
          { field: 'sub_type_id', param: 'type_id' },
          { field: 'event_ids', param: 'type_id' },
          { field: 'tournament_ids', param: 'type_id' }
        ]
      },
      {
        name: 'sub_type_id',
        display_name: 'Sub type',
        search_url: `hercules/widgets/odds-types/sub`,
        notified_by: { field: 'type_id', label: 'Please select a type first.' },
        fetch_on_load: false,
        required: true,
        type: 'list-one',
        trackBy: 'id',
        selectLabel: 'name'
      },
      {
        name: 'event_ids',
        show_if: { field: 'type_id', value: 1 },
        display_name: 'Event',
        type: 'model_select',
        multiple: true,
        model: 'events',
        value_label: 'details_label',
        notified_by: { field: 'sport_id', label: 'Please select a sport first.' },
        basicTable: false,
        required: true
      },
      {
        name: 'tournament_ids',
        show_if: { field: 'type_id', value: 2 },
        display_name: 'Tournament',
        type: 'model_select',
        multiple: true,
        model: 'tournament',
        value_label: 'details_label',
        notified_by: { field: 'sport_id', label: 'Please select a sport first.' },
        basicTable: false,
        required: true
      }
    ]
  },
  getConfig() {
    return {
      url: 'widgets/odds',
      name: 'Odds Widget',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
