<template>
    <div class="modal" ref="modalMonitoring" tabindex="-1" role="dialog" v-click-outside="close">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">Migrated or Merged Accounts</h3>
                    <button class="close" v-on:click="close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="migrationObject.migratedFrom && migrationObject.migratedFrom.length > 0">
                        <MigrationFromIdsTable :object="migrationObject.migratedFrom"/>
                    </div>
                    <div v-if="migrationObject.migratedTo">
                        <MigrationToTable :object="migrationObject.migratedTo"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import MigrationToTable from './migration-to-table.vue'
import MigrationFromIdsTable from './migration-from-ids-table.vue'
  
export default {
    components: { MigrationToTable, MigrationFromIdsTable },
    props: {
        object: {
            type: Object,
            default: () => ({ migratedTo: null, migratedFrom: [] })
        }
    },
    data() {
        return {
            migrationObject: {
                migratedTo: null,
                migratedFrom: []
            }
        };
    },
    watch: {
        object: {
            handler(newVal) {
                this.migrationObject = { ...newVal };
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        show() {
            if (this.$refs.modalMonitoring) {
                this.$refs.modalMonitoring.classList.add('show');
            } else {
                console.error('modalMonitoring ref is not defined');
            }
        },
        close() {
            if (this.$refs.modalMonitoring) {
                this.$refs.modalMonitoring.classList.remove('show');
            }
            this.migrationObject = {
                migratedTo: null,
                migratedFrom: []
            };
        }
    }
}
</script>
  
<style scoped>
.modal-dialog {
max-width: fit-content !important;
}
</style>
  