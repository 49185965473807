<template>
  <img
    v-bind:src="getResizerUrl(asset)"
    @load="() => (loading = false)"
    class="loading-opacity"
    v-bind:class="{ 'is-loading': loading }"
    :style="imgStyle"
  />
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
      default: () => '100px',
    },
    width: {
      type: String,
      default: () => '100%',
    },
    minWidth: {
      type: String,
      default: () => 'auto',
    },
  },
  methods: {
    getResizerUrl(asset) {
      if (asset.extension === '.svg' || asset.extension === '.gif') {
        return asset.url
      }
      return asset.url.replace(
        'https://assets-srv.s3.eu-west-1.amazonaws.com',
        'https://d3853ib161syl2.cloudfront.net/fit-in/106x100',
        asset.url
      )
    },
  },
  computed: {
    imgStyle() {
      return `height: ${this.height}; width: ${this.width}; min-width: ${this.minWidth}`
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    asset(newValue, oldValue) {
      this.loading = newValue.url !== oldValue.url
    },
  },
}
</script>
<style scoped>
.loading {
  opacity: 0.5;
}

img {
  object-fit: contain;
  border: 1px solid #b5b4b4;
  padding: 5px;
  max-width: 100%;
}
</style>
