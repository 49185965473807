export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        display_name: 'Team',
        type: 'text',
        read_only: true,
        disabled: true
      },
      {
        name: 'gender',
        required: true,
        type: 'text',
        display_name: 'Gender',
        read_only: true,
        disabled: true
      },
      {
        name: 'sport_id',
        required: true,
        display_name: 'Sport',
        search_url: 'hercules/sports/sports',
        type: 'list-one',
        selectLabel: 'name',
        read_only: true,
        disabled: true
      },
      {
        name: 'country_id',
        required: true,
        display_name: 'Country',
        search_url: 'hercules/sports/countries',
        type: 'list-one',
        selectLabel: 'name',
        read_only: true,
        disabled: true
      },
      {
        name: 'abbreviation',
        required: false,
        type: 'text',
        display_name: 'Abbreviation'
      },
      {
        name: 'additional_logo',
        required: false,
        display_name: 'Additional Logo',
        type: 'base_media'
      }
    ]
  },
  getConfig() {
    return {
      url: '/sports/teams',
      name: 'Sport Teams',
      pagination: true
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig()
    }
  }
}
