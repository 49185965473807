import Link from '@tiptap/extension-link'
import { Plugin, PluginKey } from '@tiptap/pm/state'
import { findChildrenInRange } from '@tiptap/core'
import { store } from '@/store/index.js'
export default Link.extend({
  addProseMirrorPlugins() {
    const self = this
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleClick(view, pos, event) {
            const href = self.editor.getAttributes('link').href
            if (event.target.parentNode?.getAttribute('href') === href) {
              store.commit('tiptap/setSelectedText', event.target.parentNode?.textContent)
            } else if (event.target.getAttribute('href') === href) {
              store.commit('tiptap/setSelectedText', event.target?.textContent)
            } else {
              store.commit('tiptap/setSelectedText', '')
            }
          }
        }
      })
    ]
  },
  addAttributes() {
    return {
      ...this.parent?.(),

      pageId: {
        parseHTML: element => element.getAttribute('data-page-id'),
        renderHTML: attributes => {
          return {
            'data-page-id': attributes.pageId
          }
        }
      },
      label: {
        parseHTML: element => element.getAttribute('label'),
        renderHTML: attributes => {
          return {
            label: attributes.label
          }
        }
      },
      rel: {},
      target: {},
      trackingLinkId: {}
    }
  },
  addOptions() {
    return {
      ...this.parent?.(),
      link: null
    }
  },
  addCommands() {
    return {
      ...this.parent?.(),
      updateLinkContent: (attributes, newText, internal) => ({ state, dispatch, tr, commands }) => {
        const { selection } = state
        const { from, to } = selection

        if (!this.options.link) {
          let linkToInsert = ''
          const { href, label, pageId, rel, target } = attributes
          if (internal) {
            linkToInsert = `<a href="${href}" data-page-id="${pageId}" label="${label}">${newText}</a>`
          } else {
            linkToInsert = `<a href="${href}" rel="${rel}" target="${target}">${newText}</a>`
          }

          linkToInsert = `<a href="${href}" data-page-id="${pageId}" label="${label}">${newText}</a>`
          commands.insertContentAt({ from, to }, linkToInsert)
          return
        }

        const { node, pos } = this.options.link

        const oldLinkMark = node.marks.find(mark => mark.type.name === 'link')

        if (!oldLinkMark) return false

        // Create a new link mark with updated attributes
        let updatedLinkMark = null
        const linkType = state.schema.marks.link

        updatedLinkMark = linkType.create({ ...attributes })
        // Create a new text node with the new content and the updated link mark
        const newTextnode = state.schema.text(newText, [updatedLinkMark])

        // Replace the old link content with the new content
        tr.replaceWith(pos, pos + node.nodeSize, newTextnode)
        dispatch(tr)

        return true
      },
      // highlighting finds the whole text link not just the highlighted text
      findTextInLink: () => ({ state }) => {
        const { doc, selection } = state
        const { from, to } = selection

        const link = findChildrenInRange(
          doc,
          { from, to },
          node => node.type.name === 'text'
        ).find(({ node }) => node.marks.some(mark => mark.type.name === 'link'))
        this.options.link = link
        if (!link) return ''

        return link.node.text
      }
    }
  }
})
