import Api from '../Api'

const buildQueryString = filters => {
  return Object.keys(filters)
    .map(key => key + '=' + filters[key])
    .join('&')
}

export default {
  getOne(id, data) {
    return Api().get(`koala/v1/account-reports/${id}`, {
      params: data
    })
  },
  get() {
    return Api().get(`koala/v1/account-reports`)
  },
  post(data) {
    const { account, report, active } = data
    return Api().post(`koala/v1/account-reports`, { account, report, active })
  },
  remove(data) {
    return Api().delete(`koala/v1/account-reports/${data.id}`, data)
  },
  update(data) {
    const { active } = data
    return Api().put(`koala/v1/account-reports/${data.id}`, { active })
  },
  bulkByIds(ids, changes, extraData) {
    this.prepareTheDataForBulkEdit(changes, extraData)
    if (changes.keywords === null) {
      changes.keywords = []
    }
    return Api().patch(`koala/v1/account-reports?${buildQueryString({ ids: ids })}`, changes)
  },
  bulkByFilters(filters, changes, extraData) {
    this.prepareTheDataForBulkEdit(changes, extraData)
    if (changes.keywords === null) {
      changes.keywords = []
    }
    return Api().patch(`koala/v1/account-reports?${buildQueryString(filters)}`, changes)
  },
  prepareTheDataForBulkEdit(changes) {
    if (changes.active === null) {
      changes.active = false
    }
  }
}
