<template>
  <div>
    <span>{{ label }}</span>
    <a
      class="p-2 ml-2 font-size-sm text-muted clickable"
      :href="`/toplists/${id}/items`"
      target="_blank"
      v-if="id"
    >
      Edit list
      <i class="uil uil-external-link-alt ml-2 icon-xs"></i>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    label: {},
    id: {}
  }
}
</script>
