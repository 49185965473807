<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" id="toplist-add-modal" v-click-outside="close">
    <div class="modal-dialog modal-lg2">
      <div class="modal-content">
        <div class="modal-header pb-3 d-flex flex-column position-relative">
          <div class="d-flex align-items-center w-100">
            <h5 class="modal-title">Select operators</h5>

            <base-checkbox
              v-model="showAllOperators"
              v-if="!isPrefilledTemplate"
              :switch="true"
              wrapperClass="ml-3"
              label="View all operators"
            />

            <button class="close" v-on:click="close"></button>
          </div>

          <div class="has-helper-text w-100 mt-4">
            <input
              type="text"
              ref="search"
              class="form-control"
              placeholder="Search by name"
              v-model="search"
              v-on:keyup.enter="searchEnter"
              @keydown.up.exact.prevent="focusUp"
              @keydown.down.exact.prevent="focusDown"
              @focus="searchFieldFocussed = true"
              @blur="searchFieldFocussed = false"
            />

            <span class="helper-text bg-white-50 mr-3 text-muted" v-if="search && searchFieldFocussed">
              {{ searchHelpText }}
            </span>
          </div>
        </div>

        <div class="modal-body">
          <div v-if="isLoading" class="loading full-width"></div>

          <div class="flex-table" v-else>
            <div class="flex-table-row text-muted text-uppercase font-size-sm align-items-center">
              <div class="flex-table-cell toplist-item-operator-name text-uppercase">Operator</div>

              <div class="flex-table-cell toplist-item-bonus-tracker" v-if="!isPrefilledTemplate">
                <div class="flex-table-cell toplist-item-bonus text-uppercase">Bonus</div>

                <div class="flex-table-cell toplist-item-tracker text-uppercase">Tracker</div>
              </div>

              <div class="toplist-item-status">Status</div>

              <div class="toplist-item-sort-position">Position</div>

              <div class="toplist-item-actions">Selected</div>
            </div>
          </div>

          <div class="flex-table">
            <item-row
              v-for="(item, index) in limitedResults"
              v-bind:key="index"
              :row="item"
              :index="index"
              :showFullBonus="showFullBonus"
              :toplist="toplist"
              @add="addOperator"
              @remove="removeOperator"
              :wrapperClass="search && focusIndex === index ? 'border-info' : 'border-light'"
            />
          </div>
        </div>

        <div class="modal-footer d-flex justify-content-between">
          <div>{{ filteredLabel }}</div>

          <div class="d-flex align-items-center">
            <base-checkbox
              v-if="addedOperatorsCount > 0"
              v-model="reviewAddedOperators"
              :switch="true"
              wrapperClass=""
              label="Filter selected"
            />

            <a class="btn btn-primary ml-3" @click="done">{{ doneLabel }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-extra-semi */
import ItemRow from './item-row-modal.vue'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import { uniqueId } from 'lodash'

export default {
  components: { ItemRow, BaseInput, BaseCheckbox },
  data() {
    return {
      search: null,
      operators: [],
      showFullBonus: false,
      showAllOperators: false,
      reviewAddedOperators: false,
      searchFieldFocussed: false,
      matchCount: 0,
      focusIndex: 0,
      isLoading: true,
    }
  },
  computed: {
    filteredResults() {
      if (this.reviewAddedOperators) {
        return this.operators.filter((operator) => operator.added)
      }
      let filtered = this.operators
      if (this.showAllOperators === false) {
        filtered = filtered.filter((item) => {
          return !item.operator.affiliate_operators || item.operator.affiliate_operators.length > 0
        })
      }
      if (this.search !== null) {
        filtered = filtered.filter((item) =>
          item.operator.name.toLowerCase().includes(this.search.toLowerCase())
        )
      }
      return filtered
    },
    limitedResults() {
      return this.filteredResults.slice(0, 10)
    },
    addedOperatorsCount() {
      return this.operators.filter((operator) => operator.added).length
    },
    doneLabel() {
      const addedOperatorsCount = this.addedOperatorsCount
      if (addedOperatorsCount === 0) {
        return 'Done'
      } else if (addedOperatorsCount === 1) {
        return 'Add 1 operator'
      }
      return `Add ${addedOperatorsCount} operators`
    },
    searchHelpText() {
      if (this.search === null || this.search === '' || this.filteredResults.length === 0) return
      let match = this.filteredResults[this.focusIndex]
      if (match) {
        const keyword = this.filteredResults[this.focusIndex].added ? 'remove' : 'add'
        return `Press enter to ${keyword} ${this.filteredResults[this.focusIndex].operator.name}`
      }
      return ''
    },
    filteredLabel() {
      if (this.reviewAddedOperators) {
        return `Showing operators that will be added`
      } else {
        const filteredResultsLength = this.filteredResults.length
        const count = filteredResultsLength < 10 ? filteredResultsLength : 10
        return `Showing ${count} of ${this.filteredResults.length} operators`
      }
    },
    isPrefilledTemplate() {
      return this.toplist.list_type === 'prefilled_template'
    },
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
    this.getOperators()
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    toplist: {
      type: Object,
    },
    lowestPosition: {
      required: true,
      type: Number,
    },
  },
  watch: {
    search() {
      this.focusIndex = 0
    },
    showAllOperators() {
      this.focusIndex = 0
      this.$nextTick(this.$refs.search.focus())
    },
  },
  methods: {
    searchEnter() {
      if (this.filteredResults.length === 0) {
        return
      }
      const match = this.filteredResults[this.focusIndex]
      match.added ? this.removeOperator(match) : this.addOperator(match)
    },
    addOperator(row) {
      row.added = true
      let newPosition = this.lowestPosition
      if (this.addedOperatorsCount > 1) {
        newPosition = Math.max(...this.operators.map((operator) => operator.sort_nr))
      }
      if (row.sort_nr === null) {
        row.sort_nr = newPosition + 1
      }
    },
    removeOperator(row) {
      row.added = false
      row.sort_nr = null
      this.$nextTick(() => {
        if (this.filteredResults.length === 0) {
          this.reviewAddedOperators = false
          this.$refs.search.focus()
        }
      })
    },
    done() {
      let operators = this.operators.filter((operator) => operator.added)
      operators.sort((o1, o2) => o1.sort_nr - o2.sort_nr)
      this.$emit('done', operators)
      this.close()
    },
    focusDown() {
      if (this.limitedResults[this.focusIndex + 1]) {
        this.focusIndex++
      }
    },
    focusUp() {
      if (this.focusIndex > 0) {
        this.focusIndex--
      }
    },
    async getOperators() {
      this.isLoading = true
      const obj = this
      const url = this.isPrefilledTemplate
        ? 'hercules/operators/operators'
        : 'hercules/operators/operator-top-lists'
      const params = this.isPrefilledTemplate
        ? {
            ids_not: this.items.map((item) => item.operator_id),
          }
        : {
            ids_not: this.items.map((item) => item.operator_id),
            affiliate_id: this.toplist.affiliate_id,
            market_id: this.toplist.market_id,
            type: this.toplist.operator_type,
            bonus_name: this.toplist.one_liner,
            tracker_name: this.toplist.tracker,
          }
      await this.$http
        .get(url, { params: params })
        .then((response) => {
          let map = []
          const selected = this.items.map(item => item.operator.id)
          response.data.result && response.data.result.forEach(operator => {
            let modifiedOperator = {
              id: uniqueId('id'),
              operator_id: operator.id,
              added: selected.includes(operator.id),
              removed: false,
              soft_remove: false,
              sort_nr: null,
              operator: operator,
              ribbons: [],
              selling_points: [],
            }
            if (!obj.isPrefilledTemplate) {
              ;(modifiedOperator.ribbons = []), (modifiedOperator.selling_points = [])
            }
            map.push(modifiedOperator)
          })
          this.operators = map.sort((a, b) => (b.sort_nr < a.sort_nr ? 1 : -1))
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    show() {
      this.$refs.modal.classList.add('show')
      setTimeout(() => {
        if (this.$refs && Object.hasOwn(this.$refs, 'search')) {
          this.$nextTick(this.$refs.search.focus())
        }
      }, 300)
    },
    close() {
      this.$emit('done', [])
    },
  },
}
</script>

<style lang="scss">
#toplist-add-modal {
  .modal-body {
    height: 550px;
    overflow-y: auto;
  }
  .form-text {
    position: absolute;
    bottom: 12px;
  }
}
</style>
