import Heading from '@tiptap/extension-heading'
import { styleAttribute } from './tiptapHelpers'

export default Heading.extend({
  addAttributes() {
    return {
      level: {
        default: 1,
        rendered: false
      },
      style: styleAttribute(),
      'data-anchor-label': {
        default: '',
        parseHTML: element => element.getAttribute('data-anchor-label'),
        renderHTML: attributes => {
          const attr = {}
          if (attributes['data-anchor-label']) {
            attr['data-anchor-label'] = `${attributes['data-anchor-label']}`
          }
          return attr
        }
      },
      id: {
        default: '',
        parseHTML: element => element.getAttribute('id'),
        renderHTML: attributes => {
          const attr = {}
          if (attributes.id) {
            attr.id = `${attributes.id}`
          }
          return attr
        }
      }
    }
  }
})
