import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/sites/page-custom-hreflangs`, {
      params: data
    })
  },
  getOne(id, data) {
    return Api().get(`hercules/sites/page-custom-hreflangs/${id}`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/sites/page-custom-hreflangs`, data)
  },
  update(data) {
    return Api().put(`hercules/sites/page-custom-hreflangs/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/sites/page-custom-hreflangs/${row.id}`)
  },
  fetchStructure() {
    return Api().get(`hercules/sites/page-custom-hreflangs/structure`)
  }
}
