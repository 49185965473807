export default {
  getForm() {
    return [
      {
        name: 'language_id',
        search_url: 'hercules/globals/languages?orderBy=name&ascending=1',
        required: false,
        display_name: 'Language',
        type: 'list-one',
        selectLabel: 'name'
      }
    ]
  },
  getConfig() {
    return {
      url: 'operators/affiliate-link-logics',
      name: 'Link Logic',
      pagination: true
    }
  },
  getStructure(menuId) {
    return {
      list: this.getForm(menuId),
      config: this.getConfig()
    }
  }
}
