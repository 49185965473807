export default {
  methods: {
    average(values, decimalPlaces = 0) {
      let sum = 0
      values.forEach(value => {
        if (!isNaN(value)) {
          sum += parseFloat(value)
        }
      })
      const average = parseFloat(sum / values.length).toFixed(decimalPlaces)
      return average
    }
  }
}
