<template>
  <a
    :href="link"
    class="d-flex mb-1 flex-fill search-result-item align-items-center px-2 rounded"
    :class="[activeResultIndex === result.index && 'bg-light']"
    @click.prevent="click"
    :id="`search-result-${index}`"
  >
    <div v-if="config.image" class="mr-2">
      <lazy-image :imageSrc="optimizedImage" imageClass="search-thumbnail rounded-lg" />
    </div>
    <div class="d-flex flex-column text-decoration-none text-reset justify-content-center">
      <div class="font-weight-normal text-black">{{ result._source.name }}</div>
      <div v-if="config.timestamps && result._source.updated_at">
        {{ $dateRelative(result._source.updated_at) }}
      </div>
      <div class="d-flex text-muted">
        <div v-if="result._source.site_name">{{ result._source.site_name }}</div>
        <div v-if="result._source.site_name && result._source.market_label" class="mx-1">/</div>
        <div v-if="result._source.market_label">{{ result._source.market_label }}</div>
      </div>
    </div>
  </a>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import lazyImage from '@atoms/lazy-image.vue'

export default {
  components: { BaseInput, lazyImage },
  props: {
    result: {},
    config: {},
    index: {},
    activeResultIndex: {},
  },
  computed: {
    resultIndex() {
      return [this.result._index, this.index].join(':')
    },
    optimizedImage() {
      if (!this.result._source.image) return
      if (this.result._source.image.endsWith('.svg')) return this.result._source.image
      return this.result._source.image.replace(
        'https://assets-srv.s3.eu-west-1.amazonaws.com',
        'https://d3853ib161syl2.cloudfront.net/fit-in/60x60'
      )
    },
    link() {
      return this.config.link.replace('{id}', this.result._id)
    },
  },
  methods: {
    click() {
      this.$emit('close')
      this.$router.push(this.link)
    },
  },
}
</script>

<style lang="scss">
.search-thumbnail {
  width: 30px;
  height: 30px;
  max-width: 100%;
}
.search-result-item {
  height: 52px;
  &:hover {
    background: #efefef;
  }
}
</style>
