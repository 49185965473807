<template>
  <div
    class="table-actions d-flex justify-content-center flex-wrap p-3"
    v-click-outside-table-popover="hideTableActions"
  >
    {{ currentRow + 1 }} x {{ currentCol + 1 }}
    <div
      class="d-flex"
      v-for="(row, rKey) in rows"
      :key="rKey"
      @mouseenter="mouseEnterRows(rKey)"
      @click="insertTable"
    >
      <span
        class="sm-box"
        :class="{ 'highlight-cell': col.hovered && rKey <= currentRow }"
        v-for="(col, cKey) in cols"
        :key="cKey"
        @mouseenter="mouseEnterCols(cKey)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  directives: {
    'click-outside-table-popover': {
      mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
          // Here we'll check that the click was outside the element and call method
          if (!(el === event.target || el.contains(event.target))) {
            // And if it did, call method provided in attribute value
            binding.value(event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },
  data() {
    return {
      tables: 0,
      cols: [
        { hovered: false },
        { hovered: false },
        { hovered: false },
        { hovered: false },
        { hovered: false },
        { hovered: false },
        { hovered: false },
        { hovered: false },
        { hovered: false },
        { hovered: false },
      ],
      rows: 2,
      currentRow: 0,
      currentCol: 0,
    }
  },
  methods: {
    hideTableActions() {
      this.$emit('hideTableActions')
    },
    mouseEnterRows(rKey) {
      this.currentRow = rKey
      if (rKey === this.rows - 1 && rKey < 9) {
        this.rows += 1
      } else {
        if (rKey === 9) return
        let leftOver = rKey - (this.rows - 2)
        this.rows += leftOver
      }
    },
    mouseEnterCols(cKey) {
      this.currentCol = cKey
      this.cols.forEach((col, index) => {
        if (index <= cKey) {
          col.hovered = true
        } else {
          col.hovered = false
        }
      })
    },
    insertTable() {
      this.editor
        .chain()
        .focus()
        .insertTable({ rows: this.currentRow + 1, cols: this.currentCol + 1, withHeaderRow: true })
        .run()

      this.hideTableActions()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-actions {
  color: #222;
  background: white;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 200px;

  &:hover {
    cursor: pointer;
  }
  .sm-box {
    width: 0.8rem;
    height: 0.8rem;
    background: #f5f5f5;
    margin: 0.1rem;
    border: 1px solid #d3d3d8;
  }
  button {
    margin: 0.2rem;
    border-radius: 4px;
    background: #fff;
    border: none;
    padding: 4px 6px 2px 6px;
    &:disabled {
      color: #d3d3d8;
    }
    &:hover {
      background: #f5f5f5;
    }
  }

  .highlight-cell {
    background: rgba(0, 152, 247, 0.3);
    border: 1px solid #0098f7;
    cursor: pointer;
  }
}
</style>
